import React, { Component } from "react";
import { DateTime } from "luxon";
import { Localized } from "@fluent/react";

export class Expire extends Component {
  static defaultProps = {
    expireDate: 0,
  };

  render() {
    const { expireDate } = this.props;
    if (!expireDate) return null;

    return (
      <div className="expire-notification">
        <i
          className="fa fa-calendar fa-3x expire-notification-icon"
          aria-hidden="true"
        />
        <div className="expire-notification-text">
          <div className="expire-notification-text-title">
            <Localized id="expiration_date">expiration_date</Localized>
          </div>
          <div className="expire-notification-text-date">
            {DateTime.fromMillis(expireDate).toLocaleString({
              locale: "en-gb",
            })}
          </div>
        </div>
      </div>
    );
  }
}
