import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReportsList from '../../components/ReportsList';
import { getReportsList } from '../../actions/reports';
import { addToFavorite, deleteFromFavorite } from '../../actions/favorites';

class ReportsListContainer extends Component {
  static defaultProps = {
    perPage: '10',
  };

  state = {
    page: '1',
  };

  goNext = () => this.setState({ page: this.state.page + 1 });

  goBack = () => this.setState({ page: this.state.page - 1 });

  goToPage = page => this.setState({ page });

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const {
      dateFilter,
      getReportsList,
      globalSpecial,
      isSearch = false,
      perPage,
      reportsType,
      searchQuery = '',
      urlPagination,
    } = this.props;

    let { mispActor } = this.props;

    const { location } = window;
    let urlParams = location ? new URLSearchParams(location.search.substring(1)) : false;
    let page = this.state.page;

    const isSpecialReport = reportsType === 'SPECIAL_REPORTS' || urlParams.has('is_special');
    const isBusiness = reportsType === 'focused' || urlParams.has('is_custom');
    //const urlParams = new URLSearchParams(location.search.substring(1));

    if (urlPagination) {
      const regex = /^[0-9]*$/g;
      //let urlParams = location ? new URLSearchParams(location.search.substring(1)) : false;

      if (urlParams && urlParams.has('page') && urlParams.get('page').match(regex)) {
        page = urlParams.get('page');
      } else {
        page = '1';
      }

      if (urlParams && !mispActor && urlParams.has('misp_actor')) {
        mispActor = urlParams.get('misp_actor');
      }

      if (isSearch && searchQuery) {
        const params = {
          global: true,
          globalspecial: true,
          search: searchQuery,
          page,
          per_page: perPage,
          tag_filter: urlParams.has('tag_filter') ? urlParams.get('tag_filter') : '[]',
        };

        getReportsList({ type: reportsType, params });
        return;
      }
    }

    // HACK: Find a better way to manage per_page
    const params = {
      global: isBusiness ? false : true,
      special: isSpecialReport,
      globalspecial: !!globalSpecial,
      page,
      per_page: perPage,
      tag_filter: urlParams.has('tag_filter') ? urlParams.get('tag_filter') : '[]',
    };

    if (mispActor) {
      params['misp-actor'] = mispActor;
    }

    getReportsList({
      type: reportsType,
      hashFilter: !!dateFilter,
      params,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { reportsType, location, dateFilter, perPage } = this.props;
    if (
      reportsType !== prevProps.reportsType ||
      (location && location.search !== prevProps.location.search) ||
      this.state.page !== prevState.page ||
      dateFilter !== prevProps.dateFilter ||
      perPage !== prevProps.perPage
    ) {
      this.getData();
    }
  }

  render() {
    const {
      addToFavorite,
      deleteFromFavorite,
      hasFetchedData,
      isSearch = false,
      link,
      location,
      pagination,
      page,
      perPage,
      reports,
      reportsType,
      searchQuery = '',
      showPagination,
      showFeedRSS,
      title,
      urlPagination,
      mispActor,
    } = this.props;

    const pathname = location ? location.pathname : '';
    const reports_ = reports[reportsType]
      ? perPage
        ? reports[reportsType].slice(0, perPage)
        : reports[reportsType]
      : [];

    return (
      <ReportsList
        addToFavorite={addToFavorite}
        deleteFromFavorite={deleteFromFavorite}
        goBack={this.goBack}
        goNext={this.goNext}
        goToPage={this.goToPage}
        hasFetchedData={hasFetchedData}
        isSearch={isSearch}
        link={link}
        page={page}
        pagination={pagination}
        reports={reports_}
        reportsType={reportsType}
        searchQuery={searchQuery}
        showFeedRSS={showFeedRSS}
        showPagination={showPagination}
        title={title}
        url={pathname}
        urlPagination={urlPagination}
        mispActor={mispActor}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    reports: state.reports.reports,
    page: state.reports.page,
    pagination: state.reports.pagination[ownProps.reportsType],
    isLoading: state.reports.isLoading,
    hasFetchedData: state.reports.hasFetchedData,
    // requestErrorMessage: state.reports.requestErrorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReportsList: (...args) => dispatch(getReportsList(...args)),
    addToFavorite: (...args) => dispatch(addToFavorite(...args)),
    deleteFromFavorite: (...args) => dispatch(deleteFromFavorite(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsListContainer);
