const initialState = {
  attributes: {},
  isLoading: true,
  requestErrorMessage: '',
  hasFetchedData: false,
  page: "1",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_EVENT_ATTRIBUTES_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
      };

    case 'GET_EVENT_ATTRIBUTES_SUCCESS':
      return {
        ...state,
        attributes: {
          [action.id]: action.data
        },
        isLoading: false,
        hasFetchedData: true,
        page: action.page,
      };

    case 'GET_EVENT_ATTRIBUTES_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
      };

    default:
      return state;
  }
}
