import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { prettyDate } from '../../tools/date';
import './TicketRow.scss';

export default class TicketRow extends Component {
  statusIcon(status) {
    switch (status.name) {
      case 'New':
        return <i className="fa fa-clock-o ticket-clock fa-2x" />;
      case 'In progress': // in corso
        return <i className="fa fa-refresh ticket-refresh fa-spin fa-2x" />;
      case 'Closed': // chiuso
        return <i className="fa fa-check-circle-o ticket-check fa-2x" />;
      default:
        return null;
    }
  }

  ticketType(type){
    switch(type){
      case 4:
        return 'Insight';
      case 5:
        return 'General request';
      case 6:
        return 'On demand';
      default:
        return '';
    }
  }

  render() {
    const { id, created_date, subject, status_extra_info, type } = this.props;

    return (
      <li className="list-group-item">
        <div className="float-left">
          <h2>
              <Link to={`/ticket/${id}`}>{subject}</Link>
          </h2>
          <span className="ticket-type">{this.ticketType(type)}</span><span className="date-label small"> - {prettyDate(new Date(created_date))}</span>
        </div>
        <span className="btn btn-ligth btn-btnless float-right">
          {this.statusIcon(status_extra_info)}
        </span>
      </li>
    );
  }
}
