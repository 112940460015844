import React, { Component } from 'react';
import { connect } from 'react-redux';
import Ticket from '../../components/Ticket';
import { getTicket, addNewTicketReply, addNewTicketReplyAttachment } from '../../actions/ticket';

class TicketContainer extends Component {
  componentDidMount() {
    const { match, tickets, getTicket } = this.props;
    const ID = match.params.id;

    if (!tickets[ID]) {
      getTicket({params: { id: ID }});
    }
  }

  render() {
    const { match, tickets } = this.props;
    const ID = match.params.id;
    return (
      <Ticket ticket={tickets[ID]} {...this.props} />
    )
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTicket: (...args) => dispatch(getTicket(...args)),
    addNewTicketReply: (...args) => dispatch(addNewTicketReply(...args)),
    addNewTicketReplyAttachment: (...args) => dispatch(addNewTicketReplyAttachment(...args)),
  }
}
export default connect(
  (state) => ({
    tickets: state.ticket.ticket,
  }),
  mapDispatchToProps
)(TicketContainer);