import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dynamic from '../../components/Dynamic';
import { getDynamic } from '../../actions/dynamic';

class DynamicContainer extends Component {
  static defaultProps = {
    contents: {},
  };

  componentDidMount() {
    this.getReportIfNotDownloaded();
  }

  getReportIfNotDownloaded = id => {
    const { match, contents } = this.props;
    const ID = id || match.params.id;
    const { category } = match.params;

    if (!contents[ID] || !contents[ID].id) {
      this.props.getDynamic({ params: { category, ID } });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getReportIfNotDownloaded(this.props.match.params.id);
    }
  }

  render() {
    const { addToFavorite, deleteFromFavorite, isLoading, match, contents, user } = this.props;
    const ID = match.params.id;

    return (
      <Dynamic
        addToFavorite={addToFavorite}
        deleteFromFavorite={deleteFromFavorite}
        isLoading={isLoading}
        match={match}
        dynamic={contents[ID]}
        user={user}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    contents: state.dynamic.contents,
    isLoading: state.dynamic.isLoading,
    hasFetchedData: state.dynamic.hasFetchedData,
    requestErrorMessage: state.dynamic.requestErrorMessage,
    error: state.dynamic.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDynamic: (...args) => dispatch(getDynamic(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicContainer);
