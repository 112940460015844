/**
 * Fetch Adversaries Ranking List
 * Con delta.
 *
 * @-param {object} data
 * @-param {object} router
 */
export function getAdversaryRankingList(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_ADVERSARY_RANKING_LIST_REQUEST'
    });

    const d = await api.get('threats/actors/delta', data);

    if (d.error) {
      dispatch({
        type: 'GET_ADVERSARY_RANKING_LIST_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_ADVERSARY_RANKING_LIST_SUCCESS',
        data: d.data
      });
    }
  };
}

/**
 * Funzione interna, che trasforma la lista degli adversaries, in una lista di filtri.
 * Usati internamente dal componente AdversariesPage, per renderizzare e per il funzionamento dei filti.
 * @-param {object} areas
 * @-param {object} continents
 * @-param {object} countries
 * @-param {object} count
 * @-returns {object} { area, continent, country, counts }
 */
function _getFilter(areas, continents, countries, count) {
  const area = areas.map(area => ({
    name: area.name,
    taxonomy: area.taxonomy,
    id: area.id,
    //count: area.count
  }));

  const counts = {
    byContinent: {},
    byCountry: {}
  };

  for (const item of count) {
    if (!counts.byContinent[item.continent] && (item.continent !== undefined)) {
      counts.byContinent[item.continent] = 0;
    }
    if (!counts.byCountry[item.country] && (item.country !== undefined)) {
      counts.byCountry[item.country] = parseInt(item.count, 10);
    }

    counts.byContinent[item.continent] =
      counts.byContinent[item.continent] + parseInt(item.count, 10);
  }

  const continent = continents.map(c => ({
    name: c.name,
    taxonomy: c.taxonomy,
    id: c.id,
    count: counts.byContinent[c.name]
  }));

  const country = countries.map(c => ({
    name: c.name,
    taxonomy: c.taxonomy,
    id: c.id,
    count: counts.byCountry[c.name]
  }));

  return { area, continent, country, counts };
}

/**
 * Fetch della lista degli adversaries, dall'endpoint '/threats/actors'.
 * Supporta la paginazione, il size della richiesta, ed i filtri.
 * Deriva le proprieta' di cui sopra, dallo stato di Redux.
 * @-export
 * @-param {any} [data={}]
 * @-param {any} router
 */
export function getAdversariesList(data = {}) {
  return async (dispatch, gestState, api) => {
    dispatch({
      type: 'GET_ADVERSARIES_REQUEST'
    });

    
    let requestParams = {
      page: data.page,
      per_page: data.per_page
    };
    
    if (data.filters) {
      requestParams = {
        ...requestParams,
        ...data.filters,
      };
    }
    
    if (data.search) {
      requestParams = { ...data };
    }

    const d = await api.get('threats/actors', requestParams);

    if (d.error) {
      dispatch({
        type: 'GET_ADVERSARIES_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_ADVERSARIES_SUCCESS',
        data: d.data,
        filters: _getFilter(
          d.data.area,
          d.data.continent,
          d.data.country,
          d.data.count
        ),
        currentFilters: data.filters,
        page: requestParams.page,
        extra: d._extra,
      });
    }
  };
}

/**
 * Action utilizzata soltanto in caso di search degli adversaries, dal componente AdversariesList.
 * @-param {object} data
 * @-param {object} data.params Parametri che verrano URL-encoded.
 * @-param {string} data.params.search Query della ricerca.
 * @-param {object} router
 */
// export function getAdversariesListFromSearch(data, router) {
//   return async (dispatch, getState, api) => {
//     const common = {
//       searchType: 'adversaries',
//       query: data.params.search
//     };
//     const d = await api.get('threats/actors', data.params);
//     if (d.error) {
//       dispatch({
//         type: 'SEARCH_EXTERNAL_FAILED',
//         message: d.error,
//         ...common
//       });
//     } else {
//       dispatch({
//         type: 'SEARCH_EXTERNAL_SUCCESS',
//         data: d.data,
//         ...common,
//         extra: d._extra
//       });
//     }
//   };
// }

/**
 * Effettua la richiesta di download, di un singolo adversary.
 * @-param {object} data
 * @-param {object} data.params Oggetto che verra URL-encodato, alla richiesta GET.
 * @-param {object} data.params.ID ID dell'adversary da prendere.
 */
export function getAdversary(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_ADVERSARY_REQUEST'
    });

    const d = await api.get(`threats/actors/${data.params.ID}`);

    if (d.error) {
      dispatch({
        type: 'GET_ADVERSARY_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_ADVERSARY_SUCCESS',
        data: d.data
      });
    }
  };
}

/**
 * Modifica il fitro correntemente selezionato nello stato di redux.
 * @-param {object} filter - Il nuovo filtro.
 * @-param {string} filter.type - Il tipo di filtro, da cui verra' effettuata la richiesta al backend.
 * @-param {number} filter.value - Il valore del filtro, ad esempio l'id del valore da filtrare.params
 * @-param {string} filter.name - Il nome del filtro. label.
 */
// export function editAdversariesFilter(filter, router) {
//   return async (dispatch, getState, api) => {
//     dispatch({
//       type: 'EDIT_ADVERSARIES_FILTER',
//       filter: filter
//     });

//     dispatch(getAdversariesList({page: 1}));
//   };
// }

/**
 * Azzera il filtro corrente della lista degli adversaries.
 *
 * @-param {object} filter
 * @-param {object} router
 */
// export function clearAdversariesFilter(filter, router) {
//   return async (dispatch, getState, api) => {
//     dispatch({
//       type: 'CLEAR_ADVERSARIES_FILTER',
//       filter: filter
//     });
//     dispatch(getAdversariesList());
//   };
// }

/**
 * Cambia lo stato degli adversary, permettendo di cambiare la pagina. Causando un refetch.
 * @-param {number} page
 *
export function editAdversariesPage(page) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'EDIT_ADVERSARY_PAGE',
      page
    });

    dispatch(getAdversariesList());
  };
}*/
