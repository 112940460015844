import React, { Component } from 'react';
import { Expire } from './Expire';

export class LicenseTypeCol extends Component {
  static defaultProps = {
    label: '',
    type: '',
    currentLicense: '',
  };

  render() {
    const { label, type, currentLicense, expireDate } = this.props;
    const isActive = currentLicense.toLowerCase() === type.toLowerCase();
    //const iconClassNames = isActive ? 'fa fa-check-circle fa-4x p-setting' : 'fa fa-minus-circle fa-4x p-nosetting';

    return (
      <div className={`col-12`}>
        <div className={`plan ${isActive ? 'activeplan' : ''}`}>
          <span className="mt-3 license-type">{label}</span>
          {this.props.children}
          {isActive && <Expire expireDate={expireDate} />}
        </div>
      </div>
    );
  }
}
