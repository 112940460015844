import React, { Component } from "react";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import Paginations from "../Pagination";
import PropTypes from "prop-types";

import EmptyState from "../EmptyState";
import ReportsRow from "./ReportsRow";
import "./ReportList.scss";
import { Localized } from "@fluent/react";

export default class ReportsList extends Component {
  static defaultProps = {
    reports: [],
    title: "Reports",
    link: "#",
    showPagination: false,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  addToFavorite = (id) => this.props.addToFavorite(id);

  deleteFromFavorite = (id) => this.props.deleteFromFavorite(id);

  searchURL = (tag) => {
    const { reportsType } = this.props;
    const { location } = window;
    const urlParams = new URLSearchParams(location.search.substring(1));
    const tags = [tag];

    urlParams.set("tag_filter", JSON.stringify(tags));
    urlParams.set("type", "report");
    urlParams.set("page", "1");

    switch (reportsType) {
      case "SPECIAL_REPORTS":
        urlParams.set("is_special", true);
        break;

      case "focused":
        urlParams.set("is_custom", true);
        break;

      default:
        console.warn("type not recognized");
    }

    return `/search?${urlParams.toString()}`;
  };

  removeFilterTag = (idx) => {
    const { location } = window;
    let urlParams = new URLSearchParams(location.search.substring(1));
    let tags = urlParams.has("tag_filter")
      ? JSON.parse(urlParams.get("tag_filter"))
      : false;
    if (!tags) {
      return;
    }

    tags.splice(idx, 1);

    let url = "";
    let basePath = "/reports";

    if (tags.length > 0) {
      urlParams.set("page", "1");
      urlParams.set("tag_filter", JSON.stringify(tags));
      basePath = "/search";
    } else {
      if (urlParams.has("query")) {
        basePath = "/search";
      }

      urlParams.delete("tag_filter");
    }

    url = `${basePath}?${urlParams.toString()}`;

    this.context.router.history.push(url);
  };

  render() {
    const {
      goBack,
      goNext,
      goToPage,
      hasFetchedData,
      // isSearch,
      link,
      noHeader,
      page,
      pagination,
      contents,
      pageType,
      // searchQuery,
      showPagination,
      title,
      url,
      urlPagination,
    } = this.props;

    // const { location } = window;

    if (!hasFetchedData && !contents.length) {
      return <Spinner />;
    }

    if (hasFetchedData && !contents.length) {
      return (
        <EmptyState
          img="/img/reports.svg"
          label={<Localized id="no_reports_found">no_reports_found</Localized>}
        />
      );
    }

    // const api_url = process.env.REACT_APP_API_URL_PROD || 'https://api.echo2.dev.tigersecurity.private/api/';
    //const feedUrl = `${api_url}feed/${method}?token=${localStorage.getItem('feed_token')}`;

    // let urlParams = new URLSearchParams(location.search.substring(1));
    // let tags = urlParams.has('tag_filter') ? JSON.parse(urlParams.get('tag_filter')) : false;
    return (
      <div className="card">
        {!!!noHeader && (
          <div className="card-header">
            <h2>
              <i className="fa fa-bar-chart ts-blue2" aria-hidden="true" />
              &nbsp;
              <Link to={link}>
                <span className="ts-blue2">
                  <Localized id="pages">pages</Localized> /{" "}
                </span>
                {title}
              </Link>
            </h2>
          </div>
        )}

        <div className="card-body">
          <ul className="list-unstyled">
            {contents.map((d, i) => (
              <ReportsRow
                addToFavorite={this.addToFavorite}
                deleteFromFavorite={this.deleteFromFavorite}
                key={i}
                odd={i % 2 === 1}
                report={d}
                pageType={pageType}
              />
            ))}
          </ul>

          {showPagination && (
            <Paginations
              goBack={goBack}
              goNext={goNext}
              goToPage={goToPage}
              // isSearch={isSearch}
              page={page}
              pagination={pagination}
              // searchQuery={isSearch ? searchQuery : ''}
              // searchType={isSearch ? 'report' : ''}
              url={urlPagination ? `${url}?` : ""}
            />
          )}
        </div>
      </div>
    );
  }
}
