const initialState = {
  adversaries: [],
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_ADVERSARY_RANKING_LIST_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true
      };

    case 'GET_ADVERSARY_RANKING_LIST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        adversaries: action.data
      };

    case 'GET_ADVERSARY_RANKING_LIST_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false
      };

    default:
      return state;
  }
}
