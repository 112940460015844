import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IOCEventsList from '../../components/IOCEventsList';
import { getThreatsList, getThreatsListSearch } from '../../actions/threats';
import { downloadEvent } from '../../actions/threats';

class IOCEventsListContainer extends Component {
  static defaultProps = {
    eventsType: 'DEFAULT',
    isSearch: false,
    orgID: -1,
    page: 1,
    searchQuery: '',
    size: 10,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  state = {
    page: 1,
    org_id: -1,
  };

  goNext = () => this.setState({ page: this.state.page + 1 });
  goBack = () => this.setState({ page: this.state.page - 1 });
  goToPage = page => this.setState({ page });

  changeOrgFilter = org_id => {
    const { location } = this.props;
    let urlParams = new URLSearchParams(location.search.substring(1));
    urlParams.set('orgID', org_id);
    this.context.router.history.push(
      `${location.pathname}?${urlParams.toString()}`
    );

    // if (this.props.match.path === '/IOCEvents') {
    //   this.props.history.push(`/IOCEvents?page=1&orgID=${org_id}`);
    // }

    this.setState({ org_id, page: 1 });
  };

  getThreats = () => {
    const {
      actorName,
      eventsType,
      getThreats,
      getThreatsSearch,
      idList,
      isSearch,
      searchQuery,
      size,
      urlPagination,
    } = this.props;

    const { location } = window;

    let { org_id } = this.state;

    let requestData = {
      params: {
        from: this.state.page * size - size,
        to: size,
      },
      page: this.state.page,
    };

    const urlParams = new URLSearchParams(location.search.substring(1));

    if (urlPagination) {
      let urlPage = '1';
      const regex = /^[0-9]*$/g;

      if (urlParams.get('page') && urlParams.get('page').match(regex)) {
        urlPage = urlParams.get('page');
      } else {
        urlPage = '1';
      }

      if (urlParams.get('orgID') && urlParams.get('orgID').match(regex)) {
        org_id = urlParams.get('orgID');
      }

      requestData = {
        params: {
          from: urlPage * size - size,
          to: size,
          tag_filter: urlParams.has('tag_filter')
            ? urlParams.get('tag_filter')
            : '[]',
        },
        page: urlPage,
      };

      if (org_id !== -1) {
        requestData.params.org_id = org_id;
      }

      if (isSearch && searchQuery) {
        getThreatsSearch(searchQuery, requestData);
        return;
      }
    }

    if (idList) {
      requestData.params.ID = idList;
    }

    if (actorName) {
      requestData.params.actor_name = actorName;
    }

    if (org_id !== -1) {
      requestData.params.org_id = org_id;
    }

    getThreats(eventsType, requestData);
  };

  componentDidMount() {
    this.getThreats();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.page !== prevState.page ||
      this.state.org_id !== prevState.org_id ||
      (this.props.location &&
        this.props.location.search !== prevProps.location.search)
    ) {
      this.getThreats();
    }
  }

  render() {
    const {
      actorName,
      downloadEvent,
      events,
      hasFetchedData,
      isSearch,
      location,
      orgID,
      page,
      searchQuery,
      showORGFilter,
      showPagination,
      showiocDownload,
      size,
      urlPagination,
    } = this.props;

    const pathname = location ? location.pathname : '';
    return (
      <IOCEventsList
        actorName={actorName}
        activeFilters={orgID > 0 ? { org_id: orgID } : false}
        changeOrgFilter={this.changeOrgFilter}
        downloadEvent={downloadEvent}
        events={events}
        goBack={this.goBack}
        goNext={this.goNext}
        goToPage={this.goToPage}
        hasFetchedData={hasFetchedData}
        isSearch={isSearch}
        org_id={orgID}
        page={page}
        searchQuery={searchQuery}
        showiocDownload={showiocDownload}
        showORGFilter={showORGFilter}
        showPagination={showPagination}
        size={size}
        url={pathname}
        urlPagination={urlPagination}
        location={location}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    downloadEvent: (...args) => dispatch(downloadEvent(...args)),
    getThreats: (...args) => dispatch(getThreatsList(...args)),
    getThreatsSearch: (...args) => dispatch(getThreatsListSearch(...args)),
  };
};

const mapStateToProps = (state, props) => {
  return {
    events: state.threats.events[props.eventsType || 'DEFAULT'],
    hasFetchedData: state.threats.hasFetchedData,
    orgID: state.threats.orgID,
    page: state.threats.page,
    // requestErrorMessage: state.threats.requestErrorMessage,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IOCEventsListContainer);
