import React, { Component } from 'react';
import Spinner from '../Spinner';
import TooltipItem from './TooltipItem';
import Paginations from '../Pagination';
import { prettyDate } from '../../tools/date';
import { getContrast50 } from '../../tools/colors';
import './Attributes.scss';

export default class Attributes extends Component {
  static defaultProps = {
    attributes: [],
    showPagination: true,
  };

  render() {
    const { isLoading, size, showPagination, page, url } = this.props;
    const pagination = { pages: 0 };
    let { attributes } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (attributes && attributes.length > 0) {
      let _oid = 0;

      pagination.pages = Math.ceil(attributes[0].contatore / size);

      attributes = attributes.reduce((o, a) => {
        if (a.object_id !== 0 && a.object_id !== _oid) {
          if (_oid !== 0) {
            o[o.length - 1].isLastGrouped = true;
            o.push({
              isBlank: true,
            });
          }
          o.push({
            isGrouping: true,
            ...a,
          });
          _oid = a.object_id;
        }
        o.push({
          isGrouping: false,
          isGrouped: _oid !== 0 && _oid === a.object_id,
          isLastGrouped: false,
          ...a,
        });
        return o;
      }, []);

      if (_oid !== 0) {
        attributes[attributes.length - 1].isLastGrouped = true;
      }

      return (
        <div className="iocs">
          <table className="table">
            <thead>
              <tr>
                <th>Data</th>
                <th>Categoria</th>
                <th>Tipo</th>
                <th>Valore</th>
                <th>Tags</th>
                <th>Commento</th>
                <th>IDS</th>
              </tr>
            </thead>
            <tbody>
              {attributes.map((e, index) => {
                if (e.isBlank) {
                  return (
                    <tr key={index} className="is_blank">
                      <td />
                    </tr>
                  );
                }

                if (e.isGrouping) {
                  return (
                    <tr key={index} className="is_grouping is_grouped">
                      <td>
                        {prettyDate(
                          new Date(parseInt(e.object_timestamp * 1000, 10))
                        )}
                      </td>
                      <td>
                        <b>Nome:</b> {e.object_name}
                        <br />
                        <b>Referenza:</b>{' '}
                        {e.attribute_referenced_value1
                          ? `${e.attribute_referenced_type} ${e.attribute_referenced_value1}`
                          : 0}
                        <br />
                      </td>
                      <td />
                      <td />
                      <td />
                      <td>{e.object_description}</td>
                      <td />
                    </tr>
                  );
                }

                const tags = e.tag;
                let value1 = e.value1,
                  value2 = e.value2;

                switch (e.type) {
                  case 'malware-sample':
                    value1 = (
                      <a
                        href={`${
                          process.env.REACT_APP_API_URL_PROD ||
                          'https://dashboard-telsy.echo2.dev.tigersecurity.private/api/'
                        }/threats/event/${
                          e.id
                        }/download/malware-sample?token=${localStorage.getItem(
                          'api_token'
                        )}`}
                        download={`${value1}.zip`}
                      >
                        {value1}
                      </a>
                    );
                    break;
                  case 'attachment':
                    value1 = (
                      <a
                        href={`${
                          process.env.REACT_APP_API_URL_PROD ||
                          'https://dashboard-telsy.echo2.dev.tigersecurity.private/api/'
                        }/threats/event/${
                          e.id
                        }/download/attachment?token=${localStorage.getItem(
                          'api_token'
                        )}`}
                        download={value1}
                      >
                        {value1}
                      </a>
                    );
                    break;
                  default:
                    break;
                }

                return (
                  <tr
                    key={index}
                    className={`${e.isGrouped ? 'is_grouped' : ''} ${
                      e.isLastGrouped ? 'is_last_grouped' : ''
                    }`}
                  >
                    <td>
                      {prettyDate(new Date(parseInt(e.timestamp * 1000, 10)))}
                    </td>
                    <td>
                      <pre>{e.category}</pre>
                    </td>
                    <td>
                      <pre>{e.type}</pre>
                    </td>
                    <td>
                      <pre>{value1}</pre>
                      {!!value2 ? <pre>{value2}</pre> : null}
                    </td>
                    <td>
                      {tags.map((tag, i) => (
                        <span
                          className="badge report-tag"
                          style={{
                            backgroundColor: tag.colour,
                            color: getContrast50(tag.colour),
                          }}
                        >
                          {tag.name}
                        </span>
                      ))}
                    </td>
                    <td>
                      <pre>{e.comment}</pre>
                    </td>
                    <td>
                      <pre className="d-inline">
                        {e.ids === 1 ? 'Sì ' : 'No '}
                      </pre>
                      {e.false_positive && <TooltipItem id={e.id} />}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {showPagination && (
            <Paginations page={page} pagination={pagination} url={`${url}?`} />
          )}
        </div>
      );
    }

    return null;
  }
}
