import React from 'react';
import { Col } from 'reactstrap';
import tableMapper from '../util/tableMapper';

const domain = ({ data }) => {
  if (!data) return null;

  return (
    <Col sm={{ size: 10, offset: 1 }}>
      {data.map(el => {
        const Component = tableMapper(el.table_name);
        return <Component payload={el} />;
      })}
    </Col>
  );
};

export default domain;
