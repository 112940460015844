const initialState = {
  user: {},
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_USER_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
      };

    case 'GET_USER_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        user: {
          ...state.user,
          [action.id]: action.data
        }
      };

    case 'GET_USER_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
      };

    case 'SETTINGS_SAVE_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          [action.id]: {
            ...state.user[action.id],
            notifications: {
            ...state.user[action.id].notifications,
            ...action.data
            }
          }

        }
      }

    case 'EDIT_USER_REQUEST':
      return {
        ...state,
        isLoading: true,
      };

    case 'EDIT_USER_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
      
      case 'EDIT_USER_FAILED':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
