import { getUser } from "./user";

/* Call the login endpoint on the back-end and,
 * if the request succeed, logs in the platform the user */
export function login(data) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    if (data.username.length < 2 || data.password.length < 2) {
      dispatch({
        type: "AUTHENTICATION_FAILED",
        message: "Wrong username or password",
      });

      return;
    }

    const d = await api.post("auth/login", data);

    if (d.error) {
      dispatch({
        type: "AUTHENTICATION_FAILED",
        message: d.error,
      });
    } else {
      api.setToken(d.data.token);
      api.setUserID(d.data.id);
      localStorage.setItem("api_token", d.data.token);
      localStorage.setItem("user_id", d.data.id);

      const feedToken = await api.get("auth/feedToken");
      localStorage.setItem("feed_token", feedToken.data.token);

      const cdnToken = await api.get("auth/cdnToken");
      api.CDN.setToken(cdnToken.data.token);
      localStorage.setItem("cdn_token", cdnToken.data.token);

      dispatch({
        type: "AUTHENTICATION_SUCCESS",
      });

      dispatch(getUser());
    }
  };
}
/* Sets isAuthenticated to false and removes the api_token */
export function logout(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: "LOGOUT_REQUEST",
    });

    api.delToken();
    localStorage.removeItem("api_token");

    dispatch({
      type: "LOGOUT_SUCCESS",
    });
  };
}

export function emailResetPassword(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: "RESETPASSWORDEMAIL_REQUEST",
    });

    const d = await api.get("auth/passwordReset", data);

    if (d.error) {
      dispatch({
        type: "RESETPASSWORDEMAIL_FAILED",
        message: d.error,
      });
    } else {
      dispatch({
        type: "RESETPASSWORDEMAIL_SUCCESS",
      });
    }
  };
}

export function resetPassword(token, firstChange, data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: "RESETPASSWORD_REQUEST",
    });

    api.setToken(token);

    let d;
    if (firstChange) {
      d = await api.post(`users/${api.getUserID()}`, {
        password: data.password,
        current_password: data.currentPassword,
      });
    } else {
      d = await api.post("users/resetPassword", {
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
      api.delToken();
    }

    if (d.error) {
      dispatch({
        type: "RESETPASSWORD_FAILED",
        message: d.error,
      });
    } else {
      if (firstChange) {
        await api.get("auth/logout");
        api.delToken();
      }

      dispatch({
        type: "RESETPASSWORD_SUCCESS",
      });
    }
  };
}
