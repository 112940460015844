export function getAttributesFromEventId(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_EVENT_ATTRIBUTES_REQUEST'
    });

    const d = await api.get(`threats/event/${data.params.ID}/attributes`, data.query);

    if (d.error) {
      dispatch({
        type: 'GET_EVENT_ATTRIBUTES_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_EVENT_ATTRIBUTES_SUCCESS',
        data: d.data,
        id: data.params.ID,
        page: data.page,
      });
    }
  };
}
