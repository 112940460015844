import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './Paginations.scss';

const PageNumberItem = ({ pageNum, currentPage, disabled = false, onClick, to_url }) =>
  to_url ? (
    <PaginationItem active={currentPage === pageNum} disabled={disabled}>
      <Link className="page-link" to={to_url}>
        {pageNum}
      </Link>
    </PaginationItem>
  ) : (
    <PaginationItem active={currentPage === pageNum} disabled={disabled} onClick={() => onClick(pageNum)}>
      <PaginationLink>{pageNum}</PaginationLink>
    </PaginationItem>
  );

export default class Paginations extends Component {
  static defaultProps = {
    pagination: { pages: 0, count: 0 },
    page: 1,
  };

  makeUrl = page => {
    // const { activeFilters, isSearch, searchQuery, searchType, url } = this.props;
    // let finalUrl = `${url}page=${page}`;

    // if (activeFilters) {
    //   if (activeFilters.adversary_area) {
    //     finalUrl = `${finalUrl}&adversary_area=${activeFilters.adversary_area}`;
    //   }
    //   if (activeFilters.adversary_continent) {
    //     finalUrl = `${finalUrl}&adversary_continent=${activeFilters.adversary_continent}`;
    //   }
    //   if (activeFilters.adversary_country) {
    //     finalUrl = `${finalUrl}&adversary_country=${activeFilters.adversary_country}`;
    //   }
    //   if (activeFilters.org_id) {
    //     finalUrl = `${finalUrl}&orgID=${activeFilters.org_id}`;
    //   }
    // }

    // if (isSearch) {
    //   finalUrl = `${finalUrl}&query=${searchQuery}&type=${searchType}`
    // }

    const { location } = window;
    let urlParams = new URLSearchParams(location.search.substring(1));
    urlParams.set('page', page);

    return `${location.pathname}?${urlParams.toString()}`;
  };

  render() {
    const { pagination, page, goBack, goNext, goToPage, url } = this.props;
    let showLastPage = false;

    const totPag = parseInt(pagination.pages, 10);
    const currentPag = parseInt(page, 10);

    const calcRange = (start, end) => [...Array(1 + end - start).keys()].map(v => start + v);

    const range =
      !totPag || totPag === 0
        ? []
        : calcRange(currentPag - 2, currentPag + 2)
            .filter(e => e > 0)
            .filter(e => {
              if (e > totPag) {
                return false;
              } else if (e === totPag) {
                showLastPage = false;
              } else {
                showLastPage = true;
              }

              return true;
            });

    return (
      range.length > 1 && (
        <div className="pagination">
          {totPag !== 0 && (
            <Pagination className="no-small-screens">
              <PaginationItem disabled={currentPag === 1}>
                {url ? (
                  <Link className="page-link" to={this.makeUrl(currentPag - 1)} aria-label="previous">
                    <span aria-hidden="true">«</span>
                    <span className="sr-only">Previous</span>
                  </Link>
                ) : (
                  <PaginationLink previous onClick={goBack} />
                )}
              </PaginationItem>

              {currentPag > 1 &&
                Array.isArray(range) &&
                !range.includes(1) &&
                (url ? (
                  <PageNumberItem currentPage={currentPag} pageNum={1} to_url={this.makeUrl(1)} />
                ) : (
                  <PageNumberItem currentPage={currentPag} pageNum={1} onClick={goToPage} />
                ))}

              {currentPag > 1 && Array.isArray(range) && !range.includes(1) && !range.includes(2) && (
                <PaginationItem className="dots-lastpage">...</PaginationItem>
              )}

              {range.map(e =>
                url ? (
                  <PageNumberItem key={e} currentPage={currentPag} pageNum={e} to_url={this.makeUrl(e)} />
                ) : (
                  <PageNumberItem key={e} currentPage={currentPag} pageNum={e} onClick={goToPage} />
                ),
              )}

              {showLastPage && Array.isArray(range) && !range.includes(parseInt(totPag, 10) - 1) && (
                <PaginationItem className="dots-lastpage">...</PaginationItem>
              )}

              {showLastPage &&
                Array.isArray(range) &&
                !range.includes(parseInt(totPag, 10)) &&
                (url ? (
                  <PageNumberItem currentPage={currentPag} pageNum={totPag} to_url={this.makeUrl(totPag)} />
                ) : (
                  <PageNumberItem currentPage={currentPag} pageNum={totPag} onClick={goToPage} />
                ))}

              <PaginationItem disabled={totPag <= currentPag}>
                {url ? (
                  <Link className="page-link" to={this.makeUrl(currentPag + 1)} aria-label="next">
                    <span aria-hidden="true">»</span>
                    <span className="sr-only">Next</span>
                  </Link>
                ) : (
                  <PaginationLink next onClick={goNext} />
                )}
              </PaginationItem>
            </Pagination>
          )}

          {totPag !== 0 && (
            <Pagination className="small-screens">
              <PaginationItem disabled={currentPag === 1}>
                {url ? (
                  <Link className="page-link" to={this.makeUrl(currentPag - 1)} aria-label="previous">
                    <span aria-hidden="true">«</span>
                    <span className="sr-only">Previous</span>
                  </Link>
                ) : (
                  <PaginationLink previous onClick={goBack} />
                )}
              </PaginationItem>

              {totPag <= 5 &&
                [...Array(totPag).keys()].map(e =>
                  url ? (
                    <PageNumberItem key={e} currentPage={currentPag} pageNum={e + 1} to_url={this.makeUrl(e + 1)} />
                  ) : (
                    <PageNumberItem key={e} currentPage={currentPag} pageNum={e + 1} onClick={goToPage} />
                  ),
                )}

              {totPag > 5 &&
                (url ? (
                  <PageNumberItem currentPage={currentPag} pageNum={1} to_url={this.makeUrl(1)} />
                ) : (
                  <PageNumberItem currentPage={currentPag} pageNum={1} onClick={goToPage} />
                ))}

              {totPag > 5 && <PaginationItem className="dots-lastpage">...</PaginationItem>}

              {totPag > 5 &&
                currentPag !== 1 &&
                currentPag !== totPag &&
                (url ? (
                  <PageNumberItem currentPage={currentPag} pageNum={currentPag} to_url={this.makeUrl(currentPag)} />
                ) : (
                  <PageNumberItem currentPage={currentPag} pageNum={currentPag} onClick={goToPage} />
                ))}

              {totPag > 5 && currentPag !== 1 && currentPag !== totPag && (
                <PaginationItem className="dots-lastpage">...</PaginationItem>
              )}

              {totPag > 5 &&
                (url ? (
                  <PageNumberItem currentPage={currentPag} pageNum={totPag} to_url={this.makeUrl(totPag)} />
                ) : (
                  <PageNumberItem currentPage={currentPag} pageNum={totPag} onClick={goToPage} />
                ))}

              <PaginationItem disabled={totPag <= currentPag}>
                {url ? (
                  <Link className="page-link" to={this.makeUrl(currentPag + 1)} aria-label="next">
                    <span aria-hidden="true">»</span>
                    <span className="sr-only">Next</span>
                  </Link>
                ) : (
                  <PaginationLink next onClick={goNext} />
                )}
              </PaginationItem>
            </Pagination>
          )}
        </div>
      )
    );
  }
}
