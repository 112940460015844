import { DateTime } from 'luxon';

export function prettyDate(date) {
  const diff = (new Date().getTime() - date.getTime()) / 1000,
    day_diff = Math.floor(diff / 86400);
  /*const year = date.getFullYear(),
        month = date.getMonth()+1,
        day = date.getDate();
  */

  let lang = localStorage.getItem('language') || 'it-IT';
  const langString = {
    'it-IT': {
      moment_ago: ' poco fa',
      minute_ago: ' 1 minuto fa',
      minutes_ago: ' minuti fa',
      hour_ago: ' 1 ora fa',
      hours_ago: ' ore fa',
    },
    'en-US': {
      moment_ago: ' less seconds',
      minute_ago: ' 1 minute ago',
      minutes_ago: ' minutes ago',
      hour_ago: ' 1 hour ago',
      hours_ago: ' shours ago',
    },
  };

  if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31)
    return DateTime.fromISO(date.toISOString()).toFormat('dd/LL/yyyy HH:mm');

  let r =
    (day_diff === 0 &&
      ((diff < 60 && langString[lang].moment_ago) ||
        (diff < 120 && langString[lang].minute_ago) ||
        (diff < 3600 && Math.floor(diff / 60) + langString[lang].minutes_ago) ||
        (diff < 7200 && langString[lang].hour_ago) ||
        (diff < 86400 &&
          Math.floor(diff / 3600) + langString[lang].hours_ago))) ||
    DateTime.fromISO(date.toISOString()).toFormat('dd/LL/yyyy HH:mm');

  return r;
}
