import React, { Component } from 'react';
import Spinner from '../Spinner';
import SingleImpact from './SingleImpact';
import './Impact.scss';

export default class Impact extends Component {
  state = {
    isDropdownOpen: false
  };

  impact = [
    { impact: 'Disponibilità Servizio', icon: 'fa fa-cogs' },
    { impact: 'Brand', icon: 'fa fa-tag' },
    { impact: 'Perdita di Dati', icon: 'fa fa-shield' },
    { impact: 'Perdite Economiche', icon: 'fa fa-dollar' }
  ];

  overrideImpact = name => {
    const { impact } = this.props;

    for (const im of impact) {
      if (name === im.impact) {
        return im;
      }
    }
    return {};
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className="card mb-4 impact">
        <div className="card-header">
          <h2>Impatti</h2>
        </div>
        <div className="card-body">
          <div className="row">
            {isLoading ?
              (<Spinner />) :
              (this.impact.map((item, index) => (
                <SingleImpact key={index} {...item} {...this.overrideImpact(item.impact)} />
              )))
            }
          </div>
        </div>
      </div>
    );
  }
}