import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import Root from './containers/Root';
import { unregister } from './registerServiceWorker';
import './styles/style.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './styles/custom.scss';
import './styles/ts-way-style.css';
import './styles/print-styles.scss';

import { negotiateLanguages } from '@fluent/langneg';
import { FluentBundle, FluentResource } from '@fluent/bundle';
import { LocalizationProvider, ReactLocalization } from '@fluent/react';

import * as ftl from './l10n/index';

//let ftl = require('../');

// import 'bootstrap/dist/css/bootstrap.min.css';

// Store all translations as a simple object which is available
// synchronously and bundled with the rest of the code.
const RESOURCES = {
  'en-US': new FluentResource(ftl.en),
  'it-IT': new FluentResource(ftl.it),
};

// A generator function responsible for building the sequence
// of FluentBundle instances in the order of user's language
// preferences.
function* generateBundles(userLocales) {
  // Choose locales that are best for the user.
  const currentLocales = negotiateLanguages(userLocales, ['en-US', 'it-IT'], {
    defaultLocale: 'it-IT',
  });

  for (const locale of currentLocales) {
    const bundle = new FluentBundle(locale);
    bundle.addResource(RESOURCES[locale]);
    yield bundle;
  }
}

unregister();
window.Popper = require('popper.js');
let l10n = new ReactLocalization(
  generateBundles([localStorage.getItem('language')])
);

ReactDOM.render(
  <Provider store={store}>
    <LocalizationProvider l10n={l10n}>
      <Root />
    </LocalizationProvider>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
