import React from 'react';
import IOCEventsListContainer from '../../../containers/IOCEventsList';

const string = ({ query, location }) => {
  return (
    <div className="col">
      <IOCEventsListContainer
        isSearch
        location={location}
        searchQuery={query}
        eventsType={`SEARCH_${query.slice(0, 5)}`}
        showPagination
        showORGFilter
        urlPagination
      />
    </div>
  );
};

export default string;
