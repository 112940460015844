import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import "./FilterBar.scss";
import { Localized } from "@fluent/react";

export default class FilterBar extends Component {
  state = {
    isDropdownOpen: false,
  };

  toggleDropdown = () =>
    this.setState((state) => ({ isDropdownOpen: !state.isDropdownOpen }));

  setDateFilter = (filter) => {
    this.props.editImpactDateFilter(filter);
  };

  render() {
    const { isHomepage, user, noDateFilter } = this.props;
    const { dateFilter } = this.props;
    const { isDropdownOpen } = this.state;

    const dateRanges = {
      h24: {
        filter: {
          date_start: DateTime.local()
            .minus({ hours: 24 })
            .toFormat("yyyy-LL-dd HH:mm:ss"),
        },
        label: <Localized id="dateRanges_h24">dateRanges_h24</Localized>,
      },
      d7: {
        filter: {
          date_start: DateTime.local()
            .minus({ days: 7 })
            .toFormat("yyyy-LL-dd HH:mm:ss"),
        },
        label: <Localized id="dateRanges_7d">dateRanges_7d</Localized>,
      },
      m3: {
        filter: {
          date_start: DateTime.local()
            .minus({ months: 3 })
            .toFormat("yyyy-LL-dd HH:mm:ss"),
        },
        label: <Localized id="dateRanges_m3">dateRanges_m3</Localized>,
      },
      all: {
        filter: {
          date_start: "1970-01-01 00:00:00",
        },
        label: <Localized id="dateRanges_all">dateRanges_all</Localized>,
      },
    };

    let overrideName = "";
    if (user) {
      overrideName = user.organization.name;
    }

    return (
      <div className="filter-bar">
        {!!!noDateFilter ? (
          <Dropdown isOpen={isDropdownOpen} toggle={this.toggleDropdown}>
            <DropdownToggle
              className="btn impact-toggle-filter"
              caret
              tag="button"
              data-toggle="dropdown"
              aria-haspopup={true}
              aria-expanded={false}
            >
              {dateFilter.label}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.setDateFilter(dateRanges.h24)}>
                {dateRanges.h24.label}
              </DropdownItem>
              <DropdownItem onClick={() => this.setDateFilter(dateRanges.d7)}>
                {dateRanges.d7.label}
              </DropdownItem>
              <DropdownItem onClick={() => this.setDateFilter(dateRanges.m3)}>
                {dateRanges.m3.label}
              </DropdownItem>
              <DropdownItem onClick={() => this.setDateFilter(dateRanges.all)}>
                {dateRanges.all.label}
              </DropdownItem>
            </DropdownMenu>

            {/* </Dropdown>
        )}
        <div className="ml-auto">
          <Link to={'/'} alt="Dashboard Globale">
            <button className={`btn filter-bar-btn ${isHomepage ? 'active' : ''}`} type="button" >
              <i className="fa fa-globe" aria-hidden="true" /> &nbsp;Global
            </button>
          </Link>
          <Link to={'/custom'} alt="Dashboard Azienda">
            <button className={`btn filter-bar-btn ${!isHomepage ? 'active' : ''}`} type="button" >
              <i className="fa fa-briefcase" aria-hidden="true" /> &nbsp;{overrideName}
            </button>
          </Link> */}
          </Dropdown>
        ) : (
          <div></div>
        )}

        <div className="filter-bar-buttons" data-toggle="buttons">
          {/* <div className={`btn btn-secondary btn-dashboard ${isHomepage ? 'active' : ''}`} onClick={goToDashboard} >
            <i className="fa fa-globe" aria-hidden="true" />
            &nbsp;Global
          </div>
          <div className={`btn btn-secondary btn-dashboard ${!isHomepage ? 'active' : ''}`} onClick={goToBusiness}>
            <i className="fa fa-briefcase" aria-hidden="true" />
            &nbsp;
            <a>{overrideName}</a>
          </div> */}
          <Link to={"/"} alt="Dashboard Globale">
            <button
              className={`btn filter-bar-btn ${isHomepage ? "active" : ""}`}
              type="button"
            >
              <i className="fa fa-globe" aria-hidden="true" /> &nbsp;Global
            </button>
          </Link>
          <Link to={"/custom"} alt="Dashboard Azienda">
            <button
              className={`btn filter-bar-btn ${!isHomepage ? "active" : ""}`}
              type="button"
            >
              <i className="fa fa-briefcase" aria-hidden="true" /> &nbsp;
              {overrideName}
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
