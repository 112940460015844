import React, { Component } from 'react';
import { connect } from 'react-redux';
import Report from '../../components/Report';
import {getReport, getReportRelated} from '../../actions/report';
import { addToFavorite, deleteFromFavorite } from '../../actions/favorites';

class ReportContainer extends Component {
  static defaultProps = {
    reports: {}
  }

  componentDidMount() {
    this.getReportIfNotDownloaded();
  }

  getReportIfNotDownloaded = (id) => {
    const { match, reports } = this.props;
    const ID = id || match.params.id;

    if (!reports[ID] || !reports[ID].id) {
      this.props.getReport({ params: { ID }});
      this.props.getReportRelated({ params: { ID }});
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getReportIfNotDownloaded(this.props.match.params.id)
    }
  }

  render() {
    const {
      addToFavorite,
      deleteFromFavorite,
      isLoading,
      match,
      reports,
      user,
    } = this.props;
    const ID = match.params.id;

    return (
      <Report
        addToFavorite={addToFavorite}
        deleteFromFavorite={deleteFromFavorite}
        isLoading={isLoading}
        match={match}
        report={reports[ID]}
        user={user}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    reports: state.report.reports,
    isLoading: state.report.isLoading,
    // hasFetchedData: state.report.hasFetchedData,
    // requestErrorMessage: state.report.requestErrorMessage,
    // error: state.report.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToFavorite: (...args) => dispatch(addToFavorite(...args)),
    deleteFromFavorite: (...args) => dispatch(deleteFromFavorite(...args)),
    getReport: (...args) => dispatch(getReport(...args)),
    getReportRelated: (...args) => dispatch(getReportRelated(...args)),
    // downloadEvent: (...args) => dispatch(downloadEvent(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
