import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IOCDownloadButtons from './IOCDownloadButtons';
import OrgFilterDropdown from './OrgFilterDropdown';
import IOCList from './IOCList';
import Spinner from '../Spinner';
import './IOCEventsList.scss';
import { Localized } from '@fluent/react';

export default class IOCEventsList extends Component {
  static defaultProps = {
    size: 10,
    events: [],
    showORGFilter: false,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  removeFilterTag = idx => {
    const { location } = this.props;
    let urlParams = new URLSearchParams(location.search.substring(1));
    let tags = urlParams.has('tag_filter')
      ? JSON.parse(urlParams.get('tag_filter'))
      : false;
    if (!tags) {
      return;
    }

    tags.splice(idx, 1);

    let url = '';
    let basePath = '/IOCEvents';

    if (tags.length > 0) {
      urlParams.set('page', '1');
      urlParams.set('tag_filter', JSON.stringify(tags));
      basePath = '/search';
    } else {
      if (urlParams.has('query')) {
        basePath = '/search';
      }

      urlParams.delete('tag_filter');
    }

    url = `${basePath}?${urlParams.toString()}`;

    this.context.router.history.push(url);
  };

  printableTag = tagName => {
    const rTag = /misp-galaxy:([\S]+)="([^"]+)"/;
    const res = rTag.exec(tagName);

    if (!res || (res && res.length < 3)) {
      return tagName;
    }

    return `${res[1]
      .replace(/(^\w|[\W]\w)/g, c => c.toUpperCase())
      .replace('-', ' ')}:${res[2]}`;
  };

  render() {
    const {
      actorName,
      activeFilters,
      changeOrgFilter,
      downloadEvent,
      hasFetchedData,
      goBack,
      goNext,
      goToPage,
      isSearch,
      org_id,
      page,
      searchQuery,
      showiocDownload,
      showORGFilter,
      showPagination,
      size,
      url,
      urlPagination,
    } = this.props;

    const { location } = window;

    let { events } = this.props;

    const desc = {
      '-1': <Localized id="ioc_all_events">ioc_all_events</Localized>,
      1: <Localized id="ioc_events_ts_manual">ioc_events_ts_manual</Localized>,
      292: <Localized id="ioc_events_ts_auto">ioc_events_ts_auto</Localized>,
      294: <Localized id="ioc_events_malware">ioc_events_malware</Localized>,
      295: (
        <Localized id="ioc_events_ts_analysts">
          ioc_events_ts_analysts
        </Localized>
      ),
      289: <Localized id="ioc_events_trusted">ioc_events_trusted</Localized>,
    };

    const orgImages = {
      '-1': 'globe',
      1: 'TS',
      292: 'TS_gathering_engine',
      294: 'ext_sandbox',
      295: 'TS_threat_forecast',
      289: 'threat_hunting_engine',
      377: 'telsy',
    };

    let urlParams = new URLSearchParams(location.search.substring(1));
    let tags = urlParams.has('tag_filter')
      ? JSON.parse(urlParams.get('tag_filter'))
      : false;
    return (
      <div id="ioc-list" className="card">
        <div className="card-header">
          {isSearch ? (
            <h2>
              <i className="fa fa-search ts-blue2" aria-hidden="true" />
              &nbsp;
              <span className="ts-blue2">
                <Localized id="research">research</Localized>&nbsp;/{' '}
              </span>
              {searchQuery ? (
                <span>
                  <Localized id="results_for">results_for</Localized>{' '}
                  <span className="font-italic">"{searchQuery}"</span>&nbsp;
                  <Localized id="in_ioc_events">in_ioc_events</Localized>&nbsp;
                </span>
              ) : (
                <Localized id="in_ioc_events">in_ioc_events</Localized>
              )}
              {showORGFilter && (
                <OrgFilterDropdown
                  orgId={org_id}
                  changeOrgFilter={changeOrgFilter}
                />
              )}
              {tags && (
                <div>
                  {tags.map((t, idx) => (
                    <span key={idx} className="badge report-tag" title={t}>
                      <i
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.removeFilterTag(idx)}
                        className="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>{' '}
                      {this.printableTag(t)}
                    </span>
                  ))}
                </div>
              )}
            </h2>
          ) : (
            <h2>
              <i className="fa fa-bolt ts-blue2 d-print-none" />
              <span className="ts-blue2 d-print-none">
                {' '}
                <Localized id="threats">threats</Localized> /{' '}
              </span>
              <Localized id="ioc_events">ioc_events</Localized>
              {showiocDownload && (
                <IOCDownloadButtons actorName={actorName} org_id={org_id} />
              )}
              {showORGFilter && (
                <OrgFilterDropdown
                  orgId={org_id}
                  changeOrgFilter={changeOrgFilter}
                />
              )}
            </h2>
          )}

          {showORGFilter && <p className="font-italic desc">{desc[org_id]}</p>}
        </div>

        {!hasFetchedData && !events.length ? (
          <Spinner />
        ) : (
          <IOCList
            activeFilters={activeFilters}
            actorName={actorName}
            downloadEvent={downloadEvent}
            events={events}
            goBack={goBack}
            goNext={goNext}
            goToPage={goToPage}
            isSearch={isSearch}
            location={location}
            orgImages={orgImages}
            page={page}
            searchQuery={searchQuery}
            showPagination={showPagination}
            size={size}
            url={url}
            urlPagination={urlPagination}
          />
        )}
      </div>
    );
  }
}
