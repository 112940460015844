import React, { Component } from 'react';
import { connect } from 'react-redux';
import Favorites from '../../components/Favorites';
import { getFavoritesList, addToFavorite, deleteFromFavorite } from '../../actions/favorites';

class FavoritesContainer extends Component {
  componentDidMount() {
    this.props.getFavoritesList()
  }

  render() {
    return (
      <Favorites {...this.props} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    favorites: state.favorites.favorites,
    hasFetchedData: state.favorites.hasFetchedData,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFavoritesList: (...args) => dispatch(getFavoritesList(...args)),
    addToFavorite: (...args) => dispatch(addToFavorite(...args)),
    deleteFromFavorite: (...args) => dispatch(deleteFromFavorite(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesContainer);
