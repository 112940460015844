export const it = `
active = ATTIVO
add_to_favorites =
     .title = Aggiungi ai favoriti
adversaries = Avversari
adversaries_24h_update = ultimi 30gg - aggiornato in 24h
adversary = Avversario
alt_ioc_event_img =
     .alt = Immagine eventi IOC
api_doc = API doc
api_description = Documentazione delle API disponibili per l'integrazione programmata con la piattaforma TS-Intelligence.
api_token = API token
aria_add_to_favorites =
     .aria-label = Aggiungi ai favoriti
ask_analysts = Fai una domanda ai nostri analisti
breach = Breach
cancel = Annulla
categories = Categorie
cdn_token = CDN token
change_password = Modifica Password
change_password_msg_error = La modifica non è andata a buon fine, siete pregati di riprovare.
change_password_msg_success = Password modificata con successo.
changelog = Changelog
context = Aree
continents = Continenti
current_password = Password attuale
custom_reports = Custom Report
cyber_threat_risk = Cyber Threat Risk
dashboard = Dashboard
dateRanges_7d = ultimi 7 giorni
dateRanges_all = tutto
dateRanges_h24 = ultime 24 ore
dateRanges_m3 = ultimi 3 mesi
date_publication = Data pubblicazione 
date_update = Data aggiornamento
default_img_placeholder =
     .alt = Segnaposto immagine
df_bro = Bro
df_csv = CSV
df_csv_isd = CSV (solo IDS)
df_misp_json = MISP JSON
df_misp_json_no_attachments = MISP JSON (no allegati)
df_misp_xml = MISP XML
df_misp_xml_no_attachments = MISP XML (no allegati)
df_open_ioc = OpenIOC
df_rpz = RPZ Zone File
df_snort = Snort
df_snort_attributes = Snort (solo attributi)
df_stix = STIX XML
df_stix_no_attachments = STIX XML (no allegati)
df_suricata = Suricata
df_text_file = Text File
df_text_file_ids = Text (solo IDS)
df_yara_attributes = Yara (solo attributi)
doc_copy_link_msg = Link copiato negli appunti
doc_copy_link_title = Clicca per copiare il link negli appunti
doc_toc = Indice
doc_toggle_compact = Compatto 
doc_toggle_expand = Esteso
documentations = Documentazione
documentations_api = API doc
e_mail = E-mail
event = Event
expiration_date = DATA SCADENZA LICENZA
exploits = Exploits
extra_modules = Moduli aggiuntivi
faq = FAQ
favorites = Favoriti
favorites_add_to = 
     .title = Aggiungi ai favoriti
favorites_remove_from =
     .title =  Rimuovi dai favoriti
feed_token = Feed token
filter_by = Filtra per
home = Home
in_adversaries = in Avversari
in_ioc_events = in Eventi IOC
incident_response_threat_hunting = Incident Response/Threat Hunting
insert_current_password = Inserire la password attuale
insight = Insight
investigation_ticket_on_demand = Investigation Ticket On Demand
ioc_all_events = Tutti gli eventi
ioc_counter = Contatore IOC
ioc_event_malware = Evento generato dalla detonazione di malware samples catalogati: indicatori validati dalla componente tecnologica.
ioc_event_trusted = Evento generato da Fonti Trusted: informazioni provenienti da partner selezionati di TS-WAY.
ioc_event_ts_analysts = Evento generato da analisti TS-WAY tramite l’analisi di fonti aperte: informazioni provenienti dal mondo OSINT ma sottoposte a un controllo di veridicità e attendibilità da parte degli analisti di TS-WAY, del CIOC – Cyber Intelligence Operation Center.
ioc_event_ts_auto = Evento generato da tecnologie proprietarie sviluppate da TS-WAY: tecnologie sviluppate nell’ambito di attività R&D di TS-WAY le quali, con un profondo livello di automatizzazione, riescono a fornire output verificati in termini di indicatori di compromissione.
ioc_event_ts_manual = Evento generato in modo manuale da analisti TS-WAY: gli indicatori forniti sono il risultato di un’approfondita e altamente verticale analisi svolta dal personale del CIOC – Cyber Intelligence Operation Center – di TS-WAY.
ioc_events = Eventi IOC
ioc_events_malware = Eventi generati dalla detonazione di malware samples catalogati: indicatori validati dalla componente tecnologica.
ioc_events_trusted = Eventi generati da Fonti Trusted: informazioni provenienti da partner selezionati di TS-WAY.
ioc_events_ts_analysts = Eventi generati da analisti TS-WAY tramite l’analisi di fonti aperte: informazioni provenienti dal mondo OSINT ma sottoposte a un controllo di veridicità e attendibilità da parte degli analisti di TS-WAY, del CIOC – Cyber Intelligence Operation Center.
ioc_events_ts_auto = Eventi generati da tecnologie proprietarie sviluppate da TS-WAY: tecnologie sviluppate nell’ambito di attività R&D di TS-WAY le quali, con un profondo livello di automatizzazione, riescono a fornire output verificati in termini di indicatori di compromissione.
ioc_events_ts_manual = Eventi generati in modo manuale da analisti TS-WAY: gli indicatori forniti sono il risultato di un’approfondita e altamente verticale analisi svolta dal personale del CIOC – Cyber Intelligence Operation Center – di TS-WAY.
language = Lingua
last_update = Ultimo aggiornamento
license_type = Tipo di licenza
license_type_advanced =  Il piano <license_strong>Advanced</license_strong> è pensato per tutte quelle realtà che hanno la necessità di avere un approccio avanzato, contestualizzato e preventivo alle minacce malware attraverso la cyber threat intelligence.
license_type_basic = Il piano <license_strong>Basic</license_strong> è pensato per tutte quelle realtà e ai CISO/CSO che si avvicinano al tema della cyber threat intelligence ed hanno necessità di accedere alle informazioni validate rispetto minacce, vulnerabilità emergenti e data breach.
license_type_professional = Il piano <license_strong>Professional</license_strong> è pensato per tutte quelle realtà che hanno la necessità di avere un approccio alla cyber threat intelligence maturo ed approfondito sia di tipo APT che con particolare riferimento ed approfondimento delle minacce di tipo structured crime.
login = accedi
login_button = Accedi
login_error_message = Username e Password errati
logout = Esci
manage_account = Gestione account
minutes_ago = minuti fa
most_active_adversaries = Avversari più attivi
name = Nome
nations = Nazioni
new_password = Nuova password (minimo 8 caratteri)
no_exploits_found = Nessun exploit trovato
no_ioc_event_found = Nessun evento IOC trovato
no_pages_found = Nessuna pagina trovata
no_report_found =
     .label = Nessun report trovato
no_report_found = Nessun rapporto trovato
no_reports_found = Nessun rapporto trovato
no_ticket_found = Nessun ticket trovato
no_tools_found = Nessun tool trovato
not_active = NON ATTIVO
notifications = Notifiche
notifications_email_custom_reports = E-mail rapporti speciali 
notifications_email_reports = E-mail rapporti
notifications_email_tickets = E-mail tickets
pages = Pagine
password_doesnt_match = Le password non corrispondono.
password_is_too_short = Password più corta di 8 caratteri.
password_reset = Password dimenticata?
pdf_download =
     .title = Scarica PDF
profile = Profilo
related_reports = Report correlati
repeat_new_password = Ripeti nuova password
replies = risposte
reply = Risposta
report = Report
reports = Reports
reports_list_title_reports = Reports
research = Ricerca
reset = Reset
reset_password_change_password = Cambia password 
reset_password_reset_password = Reimposta password
reset_password_send_code = Invia
resources = Risorse
result_for = Risultato per
results_for = Risultati per
save = Salva
search = Ricerca
search_term =
    .placeholder = Ricerca temine...
select_language = Seleziona lingua
send = Invia
settings = Impostazioni
special_reports = Special Reports
supply_chain_threat_monitoring = Supply Chain Threat Monitoring
table_last_day = Ultimo giorno
table_total = Totale
table_typology = Tipologia
tailored_threat_intelligence_monitoring = Tailored Threat Intelligence Monitoring
threat = Threat
threats = Threats
ticket_category = Categoria
ticket_content = Contenuto
ticket_content_placeholder =
    .placeholder = Inserisci il contenuto del ticket...
ticket_file_upload = Carica file
ticket_file_upload_input =
    .data-value = Scegli file...
ticket_new = Nuovo Ticket
ticket_select_category = Seleziona categoria...
ticket_send = Invia
ticket_subject = Oggetto
ticket_subject_placeholder =
     .placeholder = Inserisci l'oggetto del ticket... 
tickets = Ticket
ts_red_line = TS Red Line
user = Utente
vulnerability = Vulnerability
`;
