import InlineSpinner from '../Spinner/InlineSpinner';
import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Localized } from '@fluent/react';
import { prettyDate } from '../../tools/date';

export default class ReportsRow extends Component {
  static defaultProps = {
    noImage: false,
  };

  state = {
    favoriteLoading: false,
  };

  renderTLPColor = TLP => {
    return `tlp-${TLP ? TLP.toLowerCase() : ''}`;
  };

  toggleFavorite = () => {
    const { addToFavorite, deleteFromFavorite } = this.props;
    const { favorite, id } = this.props.report;

    this.setState({ favoriteLoading: true });

    if (!favorite) {
      addToFavorite(id)
        .then(() => this.setState({ favoriteLoading: false }))
        .catch(() => this.setState({ favoriteLoading: false }));
    } else {
      deleteFromFavorite(id).then(() =>
        this.setState({ favoriteLoading: false })
      );
    }
  };

  render() {
    const { odd, report, noImage } = this.props;
    const { favoriteLoading } = this.state;
    const { favorite, date, id, tlp, echo_report_cat } = report;
    const TLP = tlp && tlp.length ? tlp[0].name : false;

    return (
      <li className={`row report-item media ${odd ? 'my-4' : ''}`}>
        {!noImage && (
          <Col className="col-12" xl={3} lg={3}>
            <Localized id="default_img_placeholder" attrs={{ alt: true }}>
              <img
                className="report-cover-img img-fluid"
                src={report.featured_image || '/img/img-placeholder.jpg'}
                alt="default_img_placeholder"
              />
            </Localized>
          </Col>
        )}
        <Col className="col-12" xl={9} lg={9}>
          <div className="media-body">
            <div className="row justify-content-between">
              <div className="col-10 mb-1 report-list-title">
                <Link to={`/report/${id}`}>
                  <h3
                    className="report-title"
                    dangerouslySetInnerHTML={{ __html: report.title.rendered }}
                  />
                </Link>
              </div>
              <div className="col-1">
                {favoriteLoading ? (
                  <InlineSpinner width={24} height={24} />
                ) : !favorite ? (
                  <Localized id="favorites_add_to" attrs={{ title: true }}>
                    <i
                      className={`fa fa-star${favorite ? '' : '-o'} fa-lg`}
                      onClick={this.toggleFavorite}
                      title="favorites_add_to"
                    />
                  </Localized>
                ) : (
                  <Localized id="favorites_remove_from" attrs={{ title: true }}>
                    <i
                      className={`fa fa-star${favorite ? '' : '-o'} fa-lg`}
                      onClick={this.toggleFavorite}
                      title="favorites_remove_from"
                    />
                  </Localized>
                )}
              </div>
            </div>
            <div className="report-tags">
              {(echo_report_cat || []).map(tag => (
                <span key={tag.id} className="badge report-tag">
                  {tag.name}
                </span>
              ))}
            </div>
            <div
              className="report-excerpt"
              dangerouslySetInnerHTML={{ __html: report.excerpt.rendered }}
            />
            <div className={`badge mr-1 ${this.renderTLPColor(TLP)}`}>
              TLP:{TLP.toUpperCase()}
            </div>
            <span className="date-label small">
              {prettyDate(new Date(date))}
            </span>
          </div>
        </Col>
      </li>
    );
  }
}
