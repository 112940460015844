import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdversariesPage from '../../components/AdversariesPage';
import AdversariesList from '../../components/AdversariesList';
import { getAdversariesList } from '../../actions/adversary';

class AdversariesPageContainer extends Component {
  static defaultProps = {
    per_page: "10",
  }

  urlParamsToRequestData = () => {
    const { isSearch, searchQuery } = this.props;
    let params = new URLSearchParams(this.props.location.search.substring(1));
    let page = "1";
    const regex = /^[0-9]*$/g;
    
    if (params.get("page") && params.get("page").match(regex)) {
      page = params.get("page");
    } else {
      page = "1";
    }

    let data = {page: page, per_page: this.props.per_page,};
    if (isSearch) {
      data = { ...data, search: searchQuery, }
    } else {
      let filters = {};
      if (params.get("adversary_area") && params.get("adversary_area").match(regex)) {
        filters["adversary_area"] = params.get("adversary_area");
      } else if (params.get("adversary_country") && params.get("adversary_country").match(regex)) {
        filters["adversary_country"] = params.get("adversary_country");
      } else if (params.get("adversary_continent") && params.get("adversary_continent").match(regex)) {
        filters["adversary_continent"] = params.get("adversary_continent");
      }
      
      if (Object.keys(filters).length > 0) {
        data = { ...data, filters }
      }
    }

    return data;
  }

  componentDidMount() {
    const requestData = this.urlParamsToRequestData();
    this.props.getAdversaries(requestData);
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const requestData = this.urlParamsToRequestData();
      this.props.getAdversaries(requestData);
    }
  }

  render() {
    const {
      adversaries,
      availableFilters,
      currentFilters,
      history,
      isLoading,
      isSearch,
      location,
      match,
      page,
      pagination,
      searchQuery,
      user,
    } = this.props;

    const pathname = location ? location.pathname : "";

    return (
      isSearch ?
        <AdversariesList
          isLoading={isLoading}
          isSearch={isSearch}
          list={adversaries}
          page={page}
          pagination={pagination}
          searchQuery={searchQuery}
          showPagination
          url={pathname}
        />
      :
        <AdversariesPage
          adversaries={adversaries}
          availableFilters={availableFilters}
          currentFilters={currentFilters}
          history={history}
          isLoading={isLoading}
          match={match}
          page={page}
          pagination={pagination}
          url={pathname}
          user={user}
        />
    );
  }
}

function mapStateToProps(state) {
  return {
    adversaries: state.adversaries.adversaries,
    availableFilters: state.adversaries.availableFilters,
    currentFilters: state.adversaries.currentFilters,
    isLoading: state.adversaries.isLoading,
    pagination: state.adversaries.pagination,
    page: state.adversaries.page,
  };
}

function mapDispatchToProps(dispatch){
  return {
    getAdversaries: (...args) => dispatch(getAdversariesList(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdversariesPageContainer);
