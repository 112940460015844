import React from "react";
import ReportsListContainer from "../../../containers/ReportsList";

const report = ({ query, location }) => {
  return (
    <div className="col">
      <ReportsListContainer
        reportsType="SEARCH_REPORT"
        isSearch
        searchQuery={query}
        location={location}
        showPagination
        urlPagination={true}
      />
    </div>
  );
};

export default report;
