import React, { Component } from 'react';
import Spinner from '../Spinner';
import Adversary from './Adversary';
import Pagination from '../Pagination';
import './AdversariesList.scss';
import { Localized } from '@fluent/react';
export default class AdversariesList extends Component {
  static defaultProps = {
    // count: 4,
    list: [],
    isLoading: false,
  };

  render() {
    const {
      currentFilters,
      isLoading,
      isSearch,
      list,
      page,
      pagination,
      searchQuery,
      showPagination,
      url,
    } = this.props;

    return (
      <div className="card">
        <div className="card-header">
          {!isSearch ? (
            <h2>
              <i className="fa fa-bolt ts-blue2" aria-hidden="true" />
              &nbsp;
              <span className="ts-blue2">
                <Localized id="threats">threats</Localized>&nbsp;/{' '}
              </span>
              <Localized id="adversaries">adversaries</Localized>
            </h2>
          ) : (
            <h2>
              <i className="fa fa-search ts-blue2" aria-hidden="true" />
              &nbsp;
              <span className="ts-blue2">
                <Localized id="research">research</Localized>&nbsp;/{' '}
              </span>
              <Localized id="result_for">result_for</Localized>{' '}
              <span className="font-italic">"{searchQuery}"</span>{' '}
              <Localized id="in_adversaries">in_adversaries</Localized>
            </h2>
          )}
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="card-body">
            <ul className="list-group ioc-list mb-5">
              {list.map((actorRow, i) => (
                <Adversary key={actorRow.id} odd={i % 2 !== 1} {...actorRow} />
              ))}
            </ul>
            {showPagination && (
              <Pagination
                activeFilters={currentFilters}
                isSearch={isSearch}
                page={page}
                pagination={pagination}
                searchQuery={isSearch ? searchQuery : ''}
                searchType={isSearch ? 'adversaries' : ''}
                url={`${url}?`}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
