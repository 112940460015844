/**
 * Preleva il singolo report, dall'endpoint 'reports/id'.
 * @param {object} data
 * @param {object} data.params
 * @param {number} data.params.ID L'ID del report da prelevare.
 */
export function getReport(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_REPORT_REQUEST'
    });

    const d = await api.get(`reports/${data.params.ID}`);

    if (d.error) {
      dispatch({
        type: 'GET_REPORT_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_REPORT_SUCCESS',
        data: d.data
      });
    }
  };
}
/**
 * Preleva i report correlati ad un id.
 *
 * @param {object} data
 * @param {object} data.params
 * @param {object} data.params.ID ID del report da cui prelevare i report correlati.
 * @param {any} router
 */
export function getReportRelated(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_REPORT_RELATED_REQUEST'
    });

    const d = await api.get(`reports/${data.params.ID}/related`);

    if (d.error) {
      dispatch({
        type: 'GET_REPORT_RELATED_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_REPORT_RELATED_SUCCESS',
        id: data.params.ID,
        data: d.data
      });
    }
  };
}
