import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CardHeader, CardBody } from 'reactstrap';
import classnames from 'classnames';

export default class FilterCard extends Component {
  render() {
    const { title, iconClass, filterItems, activeFilter } = this.props;

    return (
      <div className="filter-card">
        <CardHeader className="filter-box-header">
          <i className={iconClass} aria-hidden="true" />
          <span>{title}</span>
        </CardHeader>

        <CardBody className="filter-box-content">
          <div className="filter-box">
            <ul className="filter-box-ul">
              {filterItems.map(item => {
                return (
                  <li key={item.id} className={classnames('filter-box-item', { active: activeFilter === item.id })}>
                    <Link
                      className="btn filter-box-item-btn" 
                      to={`/adversaries?page=1&${item.taxonomy}=${item.id}`} 
                      dangerouslySetInnerHTML={{ __html: item.name }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </CardBody>
      </div>
    );
  }
}