import React, { Component } from 'react';
import { connect } from 'react-redux';
import Login from '../../components/Login';
import { login } from '../../actions/session';

class LoginContainer extends Component {
  render() {
    const { login, loginErrorMessage } = this.props;

    return <Login login={login} loginErrorMessage={loginErrorMessage} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (...args) => dispatch(login(...args)),
  };
}

function mapStateToProps(state) {
  return {
    loginErrorMessage: state.session.loginErrorMessage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
