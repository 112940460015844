/**
 *  Effettua una chiamata HTTP in GET all'endpoint '/reports/favorites', per leggere la lista dei preferiti.
 *
 * @param {object} data - Oggetto che verra' transformato in query string.
 */
export function getFavoritesList(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_FAVORITES_REQUEST'
    });

    const d = await api.get('reports/favorites', data);

    if (d.error) {
      dispatch({
        type: 'GET_FAVORITES_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_FAVORITES_SUCCESS',
        data: d.data
      });
    }
  };
}
/**
 * Aggiunge un report ai preferiti
 *
 * @param {number} id - ID del report da aggiungere ai preferiti
 * @param {any} router
 */
export function addToFavorite(id, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'ADD_TO_FAVORITE_REQUEST'
    });

    const d = await api.get(`reports/favorites/add/${id}`);

    if (d.error) {
      dispatch({
        type: 'ADD_TO_FAVORITE_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'ADD_TO_FAVORITE_SUCCESS',
        id
      });
    }
  };
}
/**
 * Rimuove un report dai preferiti.
 *
 * @param {number} id - ID del report da rimuovere dai preferiti.
 * @param {any} router
 */
export function deleteFromFavorite(id, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'DELETE_FROM_FAVORITE_REQUEST'
    });

    const d = await api.get(`reports/favorites/remove/${id}`);

    if (d.error) {
      dispatch({
        type: 'DELETE_FROM_FAVORITE_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'DELETE_FROM_FAVORITE_SUCCESS',
        id
      });
    }
  };
}
