import React, { Component } from 'react';
import { connect } from 'react-redux';
import NewTicket from '../../components/NewTicket';
import { addNewTicket, getTicketsInfo, addNewTicketReplyAttachment } from '../../actions/ticket';

class TicketPageContainer extends Component {
  componentDidMount() {
    this.props.getTicketsInfo();
  }

  render() {
    return <NewTicket {...this.props} />;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addNewTicket: (...args) => dispatch(addNewTicket(...args)),
    getTicketsInfo: (...args) => dispatch(getTicketsInfo(...args)),
    addNewTicketReplyAttachment: (...args) =>
      dispatch(addNewTicketReplyAttachment(...args))
  };
};

const mapStateToProps = (state) => {
  return {
    info: state.ticket.info
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketPageContainer);
