import React, { Component } from 'react';
import AppShell from '../AppShell';
import ReportsListContainer from '../../containers/ReportsList';
import ImpactContainer from '../../containers/Impact';
import ThreatSourcesContainer from '../../containers/ThreatSources';

export default class DashboardBusiness extends Component {
  render() {
    const { dateFilter, user, location } = this.props;

    if(!user){
      return null;
    } else if (user.organization.license !== 'advanced focused'){
      return null;
    }


    return (
      <AppShell {...this.props} >
        <div className="row">
          <div className="col col-12 col-xl-7 pb-4">
            <ReportsListContainer
              dateFilter={dateFilter}
              link="/custom_reports"
              location={location}
              noImage
              perPage="5"
              reportsType="focused"
              showPagination={true}
              title="Custom Reports"
              urlPagination={true}
            />
          </div>

          <div className="col col-12 col-xl-5">
            <ImpactContainer />
            <ThreatSourcesContainer dateFilter={dateFilter}/>
          </div>
        </div>
      </AppShell>
    );
  }
}
