import React, { Component} from 'react';
import { connect} from 'react-redux';
import ThreatSources from '../../components/ThreatSources';

import { getThreatSourcesGraph} from '../../actions/threats';

class ThreatSourcesContainer extends Component {
  componentDidMount() {
    const { hasFetchedData, getThreatSourcesGraph } = this.props;

    if (!hasFetchedData) {
      getThreatSourcesGraph({});
    }
  }

  parseData = () => {
    const { data } = this.props;

    const tmp = {};

    for (const item of data) {
      if(!tmp[item.threat_source_parent]) {
        tmp[item.threat_source_parent] = { name: item.threat_source_parent, children: []}
     }
     tmp[item.threat_source_parent].children.push({name: item.threat_source_children, size: item.count})
    }
    return {
      name: 'test',
      children: Object.keys(tmp).map(e => tmp[e])
    }
  }

  componentDidUpdate(nextProps) {
    const { getThreatSourcesGraph } = this.props;
    if (this.props.dateFilter !== nextProps.dateFilter) {
      getThreatSourcesGraph({});
    }
  }

  render() {
    return (
      <ThreatSources {...this.props} data={this.parseData()}/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasFetchedData: state.threats.hasFetchedDataThreatSourcesGraph,
    data: state.threats.threatSourcesGraph,
    isLoading: state.threats.isLoadingThreatSourcesGraph,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getThreatSourcesGraph: (...args) => {
      dispatch(getThreatSourcesGraph(...args))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThreatSourcesContainer)
