import React, { Component } from "react";
import AppShell from "../AppShell";
import Attachments from "./Attachments";
import EmptyState from "../EmptyState";
import NewReply from "./NewReply";
import TicketReplies from "./TicketReplies";
import { Link } from "react-router-dom";
import { Localized } from "@fluent/react";
import { prettyDate } from "../../tools/date";
import "./Ticket.scss";

export default class Ticket extends Component {
  statusIcon(status) {
    switch (status.name) {
      case "Nuovo":
        return <i className="fa fa-clock-o ticket-clock fa-2x" />;
      case "In corso":
        return <i className="fa fa-refresh ticket-refresh fa-spin fa-2x" />;
      case "Chiuso":
        return <i className="fa fa-check-circle-o ticket-check fa-2x" />;
      default:
        return null;
    }
  }

  render() {
    const { ticket } = this.props;

    if (!ticket) {
      return (
        <AppShell noFilterBar {...this.props}>
          <div className="container pXXLarge">
            <div className="hero-unit text-center">
              <EmptyState
                img="/img/report.svg"
                label={
                  <Localized id="no_ticket_found">no_ticket_found</Localized>
                }
              />
            </div>
          </div>
        </AppShell>
      );
    }

    const {
      id,
      subject,
      description,
      owner_extra_info,
      comment,
      is_closed,
      attachment,
      created_date,
      status_extra_info,
    } = ticket;

    return (
      <AppShell noFilterBar {...this.props}>
        <div className="row justify-content-center">
          <div className="col col-12 col-md-10">
            <div className="card border-light mb-4 ticket">
              <div className="card-header ticket-header">
                <ol className="breadcrumb custom-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <Localized id="home">home</Localized>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/tickets">
                      <Localized id="tickets">tickets</Localized>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{id}</li>
                </ol>
                {this.statusIcon(status_extra_info)}
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col col-12 mb-2 col-sm-2 text-sm-center ticket-left">
                    <img
                      className="ticket-avatar img-circle img-responsive"
                      src={owner_extra_info.big_photo}
                      alt=""
                    />
                    <h5 className="ticket-owner">
                      {owner_extra_info.username}
                    </h5>
                  </div>

                  <div className="col col-12 col-sm-10 ticket-right">
                    <h3 className="ticket-right-header">{subject}</h3>
                    <p> {description} </p>
                    <Attachments attachments={attachment} />
                    <div className="blog-meta clearfix">
                      <small>
                        {" "}
                        {comment.length}{" "}
                        <Localized id="replies">replies</Localized>{" "}
                      </small>
                      <small>{prettyDate(new Date(created_date))}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-md-10">
            <TicketReplies replies={comment} />
            {!is_closed && (
              <div className="card">
                <div className="card-body">
                  <NewReply {...this.props} is_closed={is_closed} />
                </div>
              </div>
            )}
          </div>
        </div>
      </AppShell>
    );
  }
}
