import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser, editUser } from '../../actions/user';
import Profile from '../../components/Profile'


class ProfileContainer extends Component {
  componentDidMount() {
    const { user } = this.props;
    const ID = this.props.match.params.id;

    if (!user) {
       this.props.getUser(ID);
    }
  }

  render() {
    return <Profile {...this.props} />
  }
}


const mapStateToProps = (state, ownProps) => {
  const ID = ownProps.match.params.id;
  return {
    data: state.user,
    user: state.user.user[ID || "me"],
    userEdited: state.user.userEdited,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUser: (...args) => dispatch(getUser(...args)),
    editUser: (...args) => dispatch(editUser(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
