import React, { PureComponent } from 'react';
import SideBarContainer from '../../containers/SideBar';
import HeaderBar from '../HeaderBar';
import FilterBarContainer from '../../containers/FilterBar';

export default class AppShell extends PureComponent {

  render() {
    const {
      children,
      match,
      noDateFilter,
      noFilterBar,
      query,
      searchType,
      user,
    } = this.props;

    const isHomepage = (match.path === "/");
    return (
      <div>
        <HeaderBar query={query} searchType={searchType} />
        <div className="app-body">
          <SideBarContainer user={user} match={match} />
          <main className="main">
            {!!!noFilterBar && (
              <FilterBarContainer isHomepage={isHomepage} noDateFilter={noDateFilter} />
            )}

            <div className="container-fluid">
              {children}
            </div>
          </main>
        </div>
      </div>
    );
  }
}
