/**
 * Effettua il donwload della lista dei tools a partire dall'actor ID.
 * @param {object} data
 * @param {object} data.params
 * @param {object} data.params.name ACTOR name
 * @param {object} data.query - Query string
 */
export function getToolsFromActorName(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_ADVERSARY_TOOLS_REQUEST'
    });

    const d = await api.get(
      `threats/actors/${data.params.name}/tools`,
      data.query
    );

    if (d.error) {
      dispatch({
        type: 'GET_ADVERSARY_TOOLS_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_ADVERSARY_TOOLS_SUCCESS',
        data: d.data,
        id: data.params.name
      });
    }
  };
}
