import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardBusiness from '../../components/DashboardBusiness';


class DashboardBusinessContainer extends Component {
  render() {
    return <DashboardBusiness {...this.props} />;
  }
}

export default connect(
  (state) => ({
    dateFilter: state.impact.dateFilter,
  }),
)(DashboardBusinessContainer);
