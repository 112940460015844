/*export function getContrast50(hexcolor){
  return (parseInt(hexcolor.substring(1), 16) > 0xffffff/2) ? '#333':'#fff';
}
*/

export function getContrast50(hexcolor){
	hexcolor = hexcolor.substring(1);
	var r = parseInt(hexcolor.substr(0,2),16);
	var g = parseInt(hexcolor.substr(2,2),16);
	var b = parseInt(hexcolor.substr(4,2),16);
	var yiq = ((r*299)+(g*587)+(b*114))/1000;
	return (yiq >= 128) ? '#333' : '#fff';
}
