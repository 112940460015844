import React, { Component } from 'react';
import { Localized } from '@fluent/react';
import { LicenseTypeCol } from './LicenseTypeCol';
import { LicenseModule } from './LicenseModule';

import './Settings.scss';

export default class License extends Component {
  static defaultProps = {
    organization: {},
  };

  render() {
    const { license, expires, services } = this.props.organization;

    return (
      <div className="card border-light price">
        <div className="card-header">
          <h2>
            <i className="fa fa-sliders ts-blue2" aria-hidden="true" />
            <span className="ts-blue2">
              {' '}
              <Localized id="settings">settings</Localized>
            </span>{' '}
            / <Localized id="manage_account">manage_account</Localized>
          </h2>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-xl-5 col-lg-5">
              <h1 className="license-title">
                <Localized id="license_type">license_type</Localized>
              </h1>
              <LicenseTypeCol
                label="Basic"
                type="basic"
                currentLicense={license}
                expireDate={expires}
              >
                <div className="tlp">
                  <div className={`badge badge-success tlp-white`}>
                    TLP:WHITE
                  </div>
                </div>
                <div className="description">
                  <Localized
                    id="license_type_basic"
                    elems={{
                      license_strong: <strong></strong>,
                    }}
                  >
                    <p>license_type_basic</p>
                  </Localized>
                </div>
              </LicenseTypeCol>
              <LicenseTypeCol
                label="Professional"
                type="professional"
                currentLicense={license}
                expireDate={expires}
              >
                <div className="tlp">
                  <div className={`badge badge-success tlp-white`}>
                    TLP:WHITE
                  </div>
                  <div className={`badge badge-success tlp-green`}>
                    TLP:GREEN
                  </div>
                </div>
                <div className="description">
                  <Localized
                    id="license_type_professional"
                    elems={{
                      license_strong: <strong></strong>,
                    }}
                  >
                    <p>license_type_professional</p>
                  </Localized>
                </div>
              </LicenseTypeCol>
              <LicenseTypeCol
                label="Advanced"
                type="advanced"
                currentLicense={license}
                expireDate={expires}
              >
                <div className="tlp">
                  <div className={`badge badge-success tlp-white`}>
                    TLP:WHITE
                  </div>
                  <div className={`badge badge-success tlp-green`}>
                    TLP:GREEN
                  </div>
                  <div className={`badge badge-success tlp-amber`}>
                    TLP:AMBER
                  </div>
                  <div className={`badge badge-success tlp-red`}>TLP:RED</div>
                </div>
                <div className="description">
                  <Localized
                    id="license_type_advanced"
                    elems={{
                      license_strong: <strong></strong>,
                    }}
                  >
                    <p>license_type_advanced</p>
                  </Localized>
                </div>
              </LicenseTypeCol>
            </div>

            <div className="col-12 col-xl-7 col-lg-7">
              <h1 className="license-title">
                <Localized id="extra_modules">extra_modules</Localized>
              </h1>
              <div className="row">
                <LicenseModule
                  id="Cyber Threat Risk"
                  services={services}
                  name={
                    <Localized id="cyber_threat_risk">
                      cyber_threat_risk
                    </Localized>
                  }
                />
                <LicenseModule
                  id="TS Red Line"
                  services={services}
                  name={<Localized id="ts_red_line">ts_red_line</Localized>}
                />
                {/* id è temporaneo per i test */}
                <LicenseModule
                  id="Tailored Threat Intelligence Monitoring"
                  services={services}
                  name={
                    <Localized id="tailored_threat_intelligence_monitoring">
                      tailored_threat_intelligence_monitoring
                    </Localized>
                  }
                />
                <LicenseModule
                  id="Supply Chain Threat Monitoring"
                  services={services}
                  name={
                    <Localized id="supply_chain_threat_monitoring">
                      supply_chain_threat_monitoring
                    </Localized>
                  }
                />
                <LicenseModule
                  id="Investigation Ticket On Demand"
                  services={services}
                  name={
                    <Localized id="investigation_ticket_on_demand">
                      investigation_ticket_on_demand
                    </Localized>
                  }
                />
                <LicenseModule
                  id="Incident Response/Threat Hunting"
                  services={services}
                  name={
                    <Localized id="incident_response_threat_hunting">
                      incident_response_threat_hunting
                    </Localized>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
