import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

export default class TooltipItem extends Component {
  state = {
      tooltipOpen: false,
  };

  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen, });
  }

  render() {
    return (<span>
      <span className="attr-alert-ico" id={`attr-tooltip-${this.props.id}`}>
        <i className="fa fa-warning"></i>
      </span>
      <Tooltip className="attr-tooltip" placement="left" isOpen={this.state.tooltipOpen} target={`attr-tooltip-${this.props.id}`} toggle={this.toggle}>
        Possibile falso positivo
      </Tooltip>
    </span>);
  }
}