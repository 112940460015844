import React, { Component } from "react";
import { Table as RTable } from "reactstrap";
import { Link } from "react-router-dom";
import "./Table.scss";
import Pagination from "../Pagination";
import tableNameToLabel from "../SearchPage/util/tableNameToLabel";

const ipRegex = new RegExp(
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g
);

const urlRegex = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g
);

export default class Table extends Component {
  static defaultProps = {
    title: "DNS",
    payload: {
      table_name: "certificate_aggregation_table_data",
      searched_type: ["Certificate", "DNS", "STOCAZZO"],
      fields: ["Date", "IP"],
      sources: ["cymon", "detux", "censys"],
      data: [
        {
          date: "2018-01-19T07:47:55+00:00",
          ip: "",
          domain: "",
          email: "",
          url: "",
          filename: "",
          mutex: "",
          registry_key: "",
          md5: "",
          sha1: "",
          sha256: "",
          certificate: "",
          os: "",
          network: "",
          http: "",
          title: "",
          menace_type: "",
          timestamp: "",
          open_ports: [
            "80/http",
            "0/lookup",
            "25/smtp",
            "443/https_www",
            "443/https",
            "80/http_www"
          ],
          malicious: 0
        }
      ]
    }
  };

  state = {
    page: 1,
    size: 20
  };

  goNext = () => this.setState(state => ({ page: state.page + 1 }));
  goBack = () => this.setState(state => ({ page: state.page - 1 }));
  goToPage = page => this.setState(state => ({ page }));

  renderRowContent = content => {
    if (urlRegex.test(content)) {
      return <a href={content}>{content}</a>;
    } else if (ipRegex.test(content)) {
      return <Link to={`/search/ip/${content}`}>{content}</Link>;
    }
    return content;
  };
  render() {
    const { payload } = this.props;
    const { page, size } = this.state;
    payload.data = payload.data || [];
    payload.sources = payload.sources || [];
    const total = (payload.data || []).length;

    return (
      <div className="table-card">
        <div className="table-card-header">
          <h1> {tableNameToLabel(payload.table_name)} </h1>
          <div className="table-card-header-side">
            <p>Searched type:</p>
            <div className="table-badge-wrapper">
              {payload.searched_types
                ? payload.searched_types.map(e => (
                    <div className="badge badge-info table-badge">{e}</div>
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className="table-card-content">
          <div className="table-card-content-sources">
            <p>Sources:</p>
            {payload.sources.map(e => (
              <div className="badge badge-success badge-pill table-badge">
                {e}
              </div>
            ))}
          </div>
          <RTable responsive>
            <thead className="table-card-content-head">
              <tr>{payload.fields.map(field => <th>{field}</th>)}</tr>
            </thead>
            <tbody className="table-card-content-body">
              {payload.data.slice(page * size - size, page * size).map(row => (
                <tr>
                  {Object.keys(row)
                    .filter(e => payload.fields.includes(e))
                    .map(item => <td>{this.renderRowContent(row[item])}</td>)}
                </tr>
              ))}
            </tbody>
          </RTable>
          {payload.data.map}
        </div>
        <div className="table-card-footer">
          {total > size && (
            <Pagination
              page={page}
              pagination={{ pages: Math.floor(total / size) }}
              goNext={this.goNext}
              goBack={this.goBack}
              goToPage={this.goToPage}
            />
          )}
        </div>
      </div>
    );
  }
}
