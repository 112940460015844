import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAdversary } from '../../actions/adversary';
import Adversary from '../../components/Adversary';

class AdversaryContainer extends Component {
  componentDidMount() {
    this.getAdversaryIfNotDownloaded();
  }

  getAdversaryIfNotDownloaded = id => {
    const { adversary } = this.props;
    const ID = id || this.props.match.params.id;
    if (!adversary[ID]) {
      this.props.getAdversary({ params: { ID } });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.getAdversaryIfNotDownloaded(nextProps.match.params.id);
    }
  }
  render() {
    const { adversary } = this.props;
    const ID = this.props.match.params.id;
    return <Adversary {...this.props} adversary={adversary[ID]}/>;
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAdversary: (...args) => dispatch(getAdversary(...args))
  };
};
export default connect(state => ({
  adversary: state.adversaries.adversary,
}), mapDispatchToProps)(AdversaryContainer);
