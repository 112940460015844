import React, { Component } from "react";
import numeral from "numeral";

export default class IOCcounter extends Component {
  renderDelta = (type, delta, orgId) => {
    let className = "";
    let middlePath = "global";

    if (orgId && orgId !== -1) {
      middlePath = `org/${orgId}`;
    }

    if (delta === 0) {
      className = "delta delta-normal";
    } else if (delta > 0) {
      className = "delta delta-up";
    } else {
      className = "delta";
    }

    return (
      <td className={className}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            process.env.REACT_APP_CDNAPI_URL_PROD ||
            "https://cdn-intelligence.ts-way.com"
          }/cdn/ioc/${middlePath}/${type}/delta?token=${localStorage.getItem(
            "cdn_token"
          )}`}
        >
          + {delta}
        </a>
      </td>
    );
  };

  renderCount = (type, count, orgId) => {
    let middlePath = "global";

    if (orgId && orgId !== -1) {
      middlePath = `org/${orgId}`;
    }

    return (
      <td className="count-number">
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${
              process.env.REACT_APP_CDNAPI_URL_PROD ||
              "https://cdn-intelligence.ts-way.com"
            }/cdn/ioc/${middlePath}/${type}?token=${localStorage.getItem(
              "cdn_token"
            )}`}
          >
            {`+ ${numeral(count).format("0.[0] a").toUpperCase()}`}
          </a>
        </div>
      </td>
    );
  };

  render() {
    let { type } = this.props;

    if (type === "hashes") {
      // TODO: da modificare nome icona e ioc.js per rendere tutto "hash"
      type = "hash";
    }

    return (
      <tr>
        <td>
          <i className={`fa ts-icon ts-icon-${this.props.type}`} />
        </td>
        {this.renderCount(type, this.props.count, this.props.orgId)}
        {this.renderDelta(type, this.props.delta, this.props.orgId)}
      </tr>
    );
  }
}
