import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Spinner from '../Spinner';
import AttributesContainer from '../../containers/Attributes';
import GalaxyTags from '../IOCEventsList/GalaxyTags';
import { getContrast50 } from '../../tools/colors';
import { prettyDate } from '../../tools/date';
import './IOCPage.scss';
import { Localized } from '@fluent/react';

export default class IOCPage extends Component {
  searchURL = tag => {
    const { location, actorName } = this.props;

    let urlParams = new URLSearchParams(location.search.substring(1));

    let tags = urlParams.get('tag_filter');
    if (!tags) {
      tags = [];
    } else {
      try {
        tags = JSON.parse(tags);
      } catch (e) {
        tags = [];
      }
    }

    if (actorName) {
      tags.push(`misp-galaxy:threat-actor="${actorName}"`);
    }

    tags.push(tag);

    urlParams.set(
      'tag_filter',
      JSON.stringify(tags.filter((v, i, a) => a.indexOf(v) === i))
    );
    urlParams.set('type', 'events');
    urlParams.set('page', '1');

    return `/search?${urlParams.toString()}`;
  };

  state = {
    dropdownOpen: false,
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  downloadEvent = (id, format, attachment = false) => {
    this.props.downloadEvent({ id, format, attachment });
  };

  // renderGalaxy = (tags) => {
  //   const galaxiesObj = {};

  //   tags.forEach((g) => {
  //     if (g.galaxy_id === 'NULL') {
  //       return;
  //     }

  //     galaxiesObj[g.galaxy_name] = galaxiesObj[g.galaxy_name] || [];
  //     galaxiesObj[g.galaxy_name].push({
  //       galaxy_id: g.galaxy_id,
  //       value: g.galaxy_cluster_name,
  //       id: g.galaxy_cluster_id,
  //       colour: g.colour,
  //     });
  //   });

  //   return (
  //     <div className="report-tags">
  //       {Object.keys(galaxiesObj).length > 0 &&
  //         Object.keys(galaxiesObj).map((g) => (
  //           <div key={g} className="galaxies report-tags">
  //             <span className="galaxy-title">{g}:</span>
  //             {galaxiesObj[g].map((v) => {
  //               switch (v.galaxy_id) {
  //                 case 188:
  //                 case '188':
  //                   return (
  //                     <span
  //                       key={v.id}
  //                       className="badge report-tag"
  //                       style={{ backgroundColor: v.colour, color: getContrast50(v.colour) }}
  //                     >
  //                       <Link to={`/tool/${v.id}`}>{v.value}</Link>
  //                     </span>
  //                   );
  //                 case 186:
  //                 case '186':
  //                   return (
  //                     <span
  //                       key={v.id}
  //                       className="badge report-tag"
  //                       style={{ backgroundColor: v.colour, color: getContrast50(v.colour) }}
  //                     >
  //                       <Link to={`/adversary/${v.value}`}>{v.value}</Link>
  //                     </span>
  //                   );
  //                 default:
  //                   return (
  //                     <span
  //                       key={v.id}
  //                       className="badge report-tag"
  //                       style={{ backgroundColor: v.colour, color: getContrast50(v.colour) }}
  //                     >
  //                       {v.value}
  //                     </span>
  //                   );
  //               }
  //             })}
  //           </div>
  //         ))}
  //     </div>
  //   );
  // };

  render() {
    const { event, isLoading, location, match } = this.props;
    const ID = match.params.id;

    if (!event && isLoading) {
      return <Spinner />;
    } else if (!event) {
      return null;
    }

    const tags = event.tag,
      isYara = /yara/.test(event.attribute),
      isSnort = /snort/.test(event.attribute);

    const desc = {
      '-1': <Localized id="all_events">all_events</Localized>,
      1: <Localized id="ioc_event_ts_manual">ioc_event_ts_manual</Localized>,
      289: <Localized id="ioc_event_trusted">ioc_event_trusted</Localized>,
      292: <Localized id="ioc_event_ts_auto">ioc_event_ts_auto</Localized>,
      294: <Localized id="ioc_event_malware">ioc_event_malware</Localized>,
      295: (
        <Localized id="ioc_event_ts_analysts">ioc_event_ts_analysts</Localized>
      ),
    };

    const orgImages = {
      '-1': 'globe',
      1: 'TS',
      289: 'threat_hunting_engine',
      292: 'TS_gathering_engine',
      294: 'ext_sandbox',
      295: 'TS_threat_forecast',
      377: 'telsy',
    };

    return (
      <div className="row">
        <div className="col">
          <div className="card ioc-page">
            <div className="card-header">
              <Dropdown
                className="btn btn-ligth btn-btnless float-right"
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
              >
                <DropdownToggle caret>
                  <i className="fa fa-download ioc" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.downloadEvent(ID, 'csv')}>
                    CSV
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'csv-ids')}
                  >
                    CSV (solo IDS)
                  </DropdownItem>
                  <DropdownItem onClick={() => this.downloadEvent(ID, 'snort')}>
                    Snort
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'suricata')}
                  >
                    Suricata
                  </DropdownItem>
                  {isYara && (
                    <DropdownItem
                      onClick={() => this.downloadEvent(ID, 'yara-attributes')}
                    >
                      Yara (solo attributi)
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => this.downloadEvent(ID, 'bro')}>
                    Bro
                  </DropdownItem>
                  {isSnort && (
                    <DropdownItem
                      onClick={() => this.downloadEvent(ID, 'snort-attributes')}
                    >
                      Snort (solo attributi)
                    </DropdownItem>
                  )}
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'misp-xml', true)}
                  >
                    MISP XML
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'misp-xml')}
                  >
                    MISP XML (no allegati)
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'misp-json', true)}
                  >
                    MISP JSON
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'misp-json')}
                  >
                    MISP JSON (no allegati)
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'openioc')}
                  >
                    OpenIOC
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'stix', true)}
                  >
                    STIX XML
                  </DropdownItem>
                  <DropdownItem onClick={() => this.downloadEvent(ID, 'stix')}>
                    STIX XML (no allegati)
                  </DropdownItem>
                  <DropdownItem onClick={() => this.downloadEvent(ID, 'rpz')}>
                    RPZ Zone File
                  </DropdownItem>
                  <DropdownItem onClick={() => this.downloadEvent(ID, 'text')}>
                    Text File
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.downloadEvent(ID, 'text-ids')}
                  >
                    Text (solo IDS)
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <h1 className="ioc-page-header">
                {orgImages[event.org_id] ? (
                  <img
                    className="org-image"
                    src={`/img/org/${event.org_id}.svg`}
                    alt="organization"
                  />
                ) : (
                  <img
                    className="org-image"
                    src={`/img/org/-1.svg`}
                    alt="organization"
                  />
                )}
                {event.info}
              </h1>
              <p className="font-italic">{desc[event.org_id]}</p>
              <div className="report-tags">
                {tags.map(
                  tag =>
                    tag.galaxy_id === 'NULL' && (
                      <span
                        key={tag.id}
                        className="badge report-tag"
                        style={{
                          backgroundColor: tag.colour,
                          color: getContrast50(tag.colour),
                        }}
                      >
                        <Link to={this.searchURL(tag.name)}>{tag.name}</Link>
                      </span>
                    )
                )}
              </div>

              {/*this.renderGalaxy(tags)*/}
              <GalaxyTags tags={tags} location={location} />
              <span className="small">
                <Localized id="last_update">last_update</Localized>{' '}
                {prettyDate(new Date(event.timestamp * 1000))}
              </span>
            </div>

            <div className="card-body">
              <AttributesContainer eventID={ID} location={location} size={20} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
