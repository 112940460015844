/**
 * Effettua una richiesta API all'endpoint '/ticket', facendo il download della lista dei ticket.
 * Mettendoli poi nel reducer, se la richiesta va a buon fine.
 * @param {object} data
 * @param {any} router
 */
export function getTicketsList(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_TICKETS_LIST_REQUEST'
    });

    const d = await api.get('ticket', data);

    if (d.error) {
      dispatch({
        type: 'GET_TICKETS_LIST_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_TICKETS_LIST_SUCCESS',
        data: d.data
      });
    }
  };
}
/**
 * Richiedi il donwload dal back-end di un ticket singolo
 *
 * @param {object} data
 * @property {string} data.params.id ID Del ticket richiesto
 * @param {any} router
 */
export function getTicket(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_TICKET_REQUEST'
    });

    const d = await api.get(`ticket/${data.params.id}`);

    if (d.error) {
      dispatch({
        type: 'GET_TICKET_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_TICKET_SUCCESS',
        data: d.data
      });
    }
  };
}

/**
 * Crea un nuovo ticket. Effettuando una richiuesta di tipo POST a '/ticket'.
 *
 *
 * @param {object} data
 * @param {any} router
 */
export function addNewTicket(data, router) {
  return (dispatch, getState, api) => {
    return new Promise(async (resolve, reject) => {
      dispatch({
        type: 'ADD_NEW_TICKET_REQUEST'
      });

      const d = await api.post('ticket', data);

      if (d.error) {
        dispatch({
          type: 'ADD_NEW_TICKET_FAILED',
          message: d.error
        });
      } else {
        dispatch({
          type: 'ADD_NEW_TICKET_SUCCESS',
          data: d.data
        });
        resolve(d.data);
        if (router) router.history.push('/tickets');
      }
    });
  };
}

/**
 * addNewTicketReply
 * Aggiunge una risposta ad un ticket.
 * @param {number} id Id del ticket a cui aggiungere la risposta
 * @param {object} data bod del payload
 */
export function addNewTicketReply(id, data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'ADD_NEW_TICKET_REPLY_REQUEST'
    });

    const d = await api.post(`ticket/${id}/comment`, data);
    if (d.error) {
      dispatch({
        type: 'ADD_NEW_TICKET_REPLY_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'ADD_NEW_TICKET_REPLY_SUCCESS',
        id,
        data: d.data
      });
    }
  };
}
/**
 * Aggiunge un attachment ad un ticket.
 * Effettuando una richiesta di tipo POST.
 * @param {number} id - ID del ticket a cui aggiungere l'attachment
 * @param {FormData} file - FormData dell'attachment
 */
export function addNewTicketReplyAttachment(id, file, router) {
  return async (dispatch, getState, api) => {
    return new Promise(async (resolve, reject) => {
      dispatch({
        type: 'ADD_NEW_TICKET_REPLY_ATTACHMENT_REQUEST'
      });

      const d = await api.postWithFile(`ticket/${id}/attachment`, file);
      if (d.error) {
        dispatch({
          type: 'ADD_NEW_TICKET_REPLY_ATTACHMENT_FAILED',
          message: d.error
        });
      } else {
        dispatch({
          type: 'ADD_NEW_TICKET_REPLY_ATTACHMENT_SUCCESS',
          id,
          data: d.data
        });

        resolve(d.data);

        if (router) {
          router.history.push(`/ticket/${id}`);
        }
      }
    });
  };
}

export function getTicketsInfo(router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_TICKET_INFO_REQUEST'
    });

    const d = await api.get(`ticket/infos`);

    if (d.error) {
      dispatch({
        type: 'GET_TICKET_INFO_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_TICKET_INFO_SUCCESS',
        data: d.data
      });
    }
  };
}
