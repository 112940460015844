import domain from './domain';
import general from './general';
import report from './report';
import string from './string';
import adversaries from './adversaries';
import events from './events';

const typeToTemplate = {
  domain,
  report: report,
  adversaries: adversaries,
  string,
  events,
};

/**
 * Partendo dal tipo di ricerca, utilizzando l'oggetto {typeToTemplate} di cui sopra, possiamo definire
 * degli elementi custom per ogni ricerca.
 *
 * @param {string} type - Il tipo della ricerca
 * @returns {ReactElement} - L'elemento adatto al tipo di ricerca.
 */
export default type => typeToTemplate[type] || general;
