import { DateTime } from 'luxon';


const initialState = {
  impact: [],
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false,
  dateFilter: {
    filter: {
      date_start: DateTime.local().minus({ days: 7 }).toFormat('yyyy-LL-dd HH:mm:ss'),
    },
    label: 'Ultimi 7 giorni'
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_IMPACT_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
      };

    case 'GET_IMPACT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        impact: action.data,
      };

    case 'GET_IMPACT_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
      };

    case 'EDIT_IMPACT_FILTER':
      return {
        ...state,
        isLoading: true,
        hasFetchedData: false,
        dateFilter: action.filter
      }

    default:
      return state;
  }
}
