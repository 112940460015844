/**
 * Preleva il singolo report, dall'endpoint 'reports/id'.
 * @param {object} data
 * @param {object} data.params
 * @param {number} data.params.ID L'ID del report da prelevare.
 */
export function getDynamic(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_DYNAMIC_REQUEST',
    });

    const d = await api.get(`categories/${data.params.category}/${data.params.ID}`);

    if (d.error) {
      dispatch({
        type: 'GET_DYNAMIC_FAILED',
        message: d.error,
      });
    } else {
      dispatch({
        type: 'GET_DYNAMIC_SUCCESS',
        data: d.data,
      });
    }
  };
}
