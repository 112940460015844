import React, { Component } from 'react';
import {connect} from 'react-redux';
import RankingList from '../../components/RankingList';
import {getAdversaryRankingList} from '../../actions/adversary';

class AdversaryRankingListContainer extends Component {
  componentDidMount(){
    if(!this.props.hasFetchedData){
      this.props.getAdversaryRankingList();
    }
  }

  render(){
    return (<RankingList {...this.props}/>);
  }
}

function mapDispatchToProps(dispatch){
  return {
    getAdversaryRankingList: (...args) => dispatch(getAdversaryRankingList(...args)),
  };
}

function mapStateToProps (state) {
  return {
    adversaries: state.adversary.adversaries,
    isLoading: state.adversary.isLoading,
    hasFetchedData: state.adversary.hasFetchedData,
    requestErrorMessage: state.adversary.requestErrorMessage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdversaryRankingListContainer);
