import React, { Component } from "react";

const ignoreRow = ["raw_whois"];

export default class WhoisTable extends Component {
  static defaultProps = {
    payload: {
      data: []
    }
  };

  renderDataTable = () => {
    const { payload: { data } } = this.props;
    if (!data || !data.length) return null;
    const td = [];
    const dataToView = data[0];
    for (const key in dataToView) {
      if (
        dataToView.hasOwnProperty(key) &&
        !ignoreRow.includes(key) &&
        dataToView[key] !== "" &&
        dataToView[key] !== 0 &&
        dataToView[key] !== null
      ) {
        const keyLabel = key.split("_")[1];
        td.push(<WhoisTd keyLabel={keyLabel} value={dataToView[key]} />);
      }
    }
    return td || null;
  };
  render() {
    const { payload } = this.props;

    return (
      <div className="table-card">
        <div className="table-card-header">
          <h1> Whois record: </h1>
          <div className="table-card-header-side">
            <p>Searched type:</p>
            <div className="table-badge-wrapper">
              {payload.searched_type
                ? payload.searched_type.map(e => (
                    <div className="badge badge-info table-badge">{e}</div>
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className="table-card-content-vertical">
          <table>{this.renderDataTable()}</table>
        </div>
      </div>
    );
  }
}

const WhoisTd = ({ keyLabel, value }) => (
  <tr>
    <td>{keyLabel}</td>
    <td>{value}</td>
  </tr>
);
