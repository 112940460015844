import React, { Component } from 'react';
import AppShell from '../AppShell';
import { Row, Col } from 'reactstrap';
import AdversaryDetails from './AdversaryDetails';
import Tools from '../../containers/Tools';
import Exploits from '../../containers/Exploits';
import Reports from '../../containers/ReportsList';
import IOCEventsListContainer from '../../containers/IOCEventsList';
import './Adversary.scss';

export default class Adversary extends Component {
  render() {
    const { adversary } = this.props;
    if (!adversary) return null;
    const {
      adversary_country,
      title,
      content,
      adversary_area,
      featured_image,
    } = adversary;
    const flag = adversary_country.length
      ? adversary_country[0].name
      : undefined;

    const mispActor = adversary['misp-actor'][0].id;
    //const actor_id = adversary.id;
    return (
      <AppShell noFilterBar {...this.props}>
        <div className="adversary-single">
          <Row>
            <Col xl="6" lg="12">
              <AdversaryDetails
                title={title}
                content={content}
                flag={flag}
                featured_image={featured_image}
                adversary_area={adversary_area}
                className="details"
              />

              <Reports
                showPagination
                perPage={5}
                reportsType={`adversary-${adversary.id}`}
                mispActor={mispActor}
                globalSpecial
              />
            </Col>

            <Col xl="6" lg="12" className="">
              <IOCEventsListContainer
                size={3}
                actorName={title.rendered}
                eventsType={`adversary-${adversary.id}`}
                {...this.props}
                downloadEvent={this.props.downloadEvent}
                showPagination
                showiocDownload
                showORGFilter
              />

              <Tools size={10} actorName={title.rendered} />

              <Exploits size={10} actorName={title.rendered} />
            </Col>
          </Row>
        </div>
      </AppShell>
    );
  }
}
