import React, { Component } from 'react';
import SearchBar from './SearchBar';
import GLR from '../../containers/GLR';
import './HeaderBar.scss';

export default class HeaderBar extends Component {
  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  searchbarToggle(e) {
    e.preventDefault();
    const headerbar = document.getElementById('headerbar');
    const searchbar = document.getElementById('searchbar');
    headerbar.classList.toggle('expanded');
    searchbar.classList.toggle('showSearchbar');
  }

  render() {
    const { query, searchType } = this.props;

    return (
      <header className="app-header d-print-none" id="headerbar">
        <div className="logo-header">
          <a href="/">
            <div className="headbar-brand"></div>
          </a>

          <button
            className="navbar-toggler d-md-none mr-auto"
            type="button"
            onClick={this.mobileSidebarToggle}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <button
            className="navbar-toggler d-sm-down-none"
            type="button"
            onClick={this.sidebarToggle}
          >
            <span className="navbar-toggler-icon">
              <div className="badge badge-pill echo-beta-badge">Beta</div>
            </span>
          </button>
        </div>

        {/* <div className="loghi">
          <img src="/img/logo-telsy-ts.png" alt="logo" />
          <img src="/img/logo_telsy_black.png" alt="logo" />
        </div> */}

        {/* <GLR /> */}

        <button
          className="navbar-toggler d-md-none"
          type="button"
          onClick={this.searchbarToggle}
          aria-label="Toggle searchbar"
        >
          <i className="fa fa-search fa-lg open-search" aria-label="Search" />
          <i
            className="fa fa-times fa-lg close-search"
            aria-label="Close search"
          />
          {/* <span className="close-search">X</span> */}
        </button>
        <div className="header-collapse px-md-2 mr-md-3" id="searchbar">
          <SearchBar query={query} searchType={searchType} />
        </div>
      </header>
    );
  }
}

/*className Notifiche extends Component {
  render() {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="div">
          <i className="icon-bell font-2xl" />
          <Badge pill="pill" color="danger">
            5
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          <div className="notification">
            <div className="notification-toolbar">
           <div className="notification-toolbar-actions">
              <i className="fa fa-search" aria-hidden="true" /> View All
            </div>
            <h3 className="notification-toolbar-title">Recent Notifications (3)</h3>
            </div>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}*/
