import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="container-fluid text-center">
    <div className="row">
      <div className="col mt-5">
        <h1 className="display-3 mr-4">Ops!</h1>
        <p className="text-muted">La pagina che stai cercando non esiste.</p>
        <p className="mt-5 text-center"><Link className="btn btn-primary" to="/">Torna alla homepage</Link></p>
      </div>
    </div>
  </div>
);

export default NotFound;
