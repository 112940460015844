import React, { Component } from 'react';
import { connect } from 'react-redux';
import Impact from '../../components/Impact';
import { getImpactData, editImpactDateFilter } from '../../actions/impact';

class ImpactContainer extends Component {
  componentDidMount() {
    if (!this.props.hasFetchedData) {
      this.props.getImpactData();
    }
  }
  render() {
    return <Impact {...this.props} />;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getImpactData: (...args) => {
      dispatch(getImpactData(...args));
    },
    editImpactDateFilter: (...args) => dispatch(editImpactDateFilter(...args))
  };
};
export default connect(state => ({
  impact: state.impact.impact,
  isLoading: state.impact.isLoading,
  requestErrorMessage: state.impact.requestErrorMessage,
  hasFetchedData: state.impact.hasFetchedData,
  dateFilter: state.impact.dateFilter,
}), mapDispatchToProps)(ImpactContainer);
