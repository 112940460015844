import { combineReducers } from 'redux';
import session from './session';
import reports from './reports';
import report from './report';
import ioc from './ioc';
import threats from './threats';
import ticket from './ticket';
import adversary from './adversary';
import impact from './impact';
import adversaries from './adversaries';
import favorites from './favorites';
import tools from './tools';
import tool from './tool';
import exploits from './exploits';
import user from './user';
import glr from './glr';
import settings from './settings';
import attributes from './attributes';
import dynamics from './dynamics';
import dynamic from './dynamic';

const appReducer = combineReducers({
  session,
  reports,
  ioc,
  report,
  threats,
  ticket,
  adversary,
  impact,
  adversaries,
  favorites,
  tools,
  tool,
  exploits,
  user,
  glr,
  settings,
  attributes,
  dynamics,
  dynamic,
});

export default appReducer;
