import React, { Component } from 'react';
import { Localized } from '@fluent/react';
import { connect } from 'react-redux';
import { getUser } from '../../actions/user';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdversaryContainer from '../../containers/Adversary';
import AdversariesPageContainer from '../../containers/AdversariesPage';
import DashboardCommon from '../../components/DashboardCommon';
import DashboardBusinessContainer from '../../containers/DashboardBusiness';
import APIDoc from '../../components/ApiDoc';
import FavoritesContainer from '../../containers/Favorites';
import IOCEventsPage from '../../components/IOCEventsPage';
import IOCPageContainer from '../../containers/IOCPage';
import LoginContainer from '../../containers/Login';
import ResetPasswordContainer from '../../containers/ResetPassword';
import NewTicketContainer from '../../containers/NewTicket';
import NotFound from '../../components/NotFound';
import ProfileContainer from '../../containers/Profile';
import DynamicContainer from '../../containers/Dynamic';
import ReportsPage from '../../components/ReportsPage';
import DynamicsPage from '../../components/DynamicsPage';
import SearchPage from '../../components/SearchPage';
import SettingsContainer from '../../containers/Settings';
import TicketContainer from '../../containers/Ticket';
import TicketPageContainer from '../../containers/TicketPage';
import ToolPageContainer from '../../containers/ToolPage';
import ReportContainer from '../../containers/Report';

class PrivateRoute extends Component {
  render() {
    const {
      component: Component,
      isAuthenticated,
      exact,
      path,
      ...rest
    } = this.props;
    // info: {...rest} <=> {computedMatch, location, user, >reportsType<, >title<}

    return (
      <Route
        exact={exact}
        path={path}
        render={props =>
          // info: {...props} <=> {history, location, match, staticContext}
          isAuthenticated ? (
            <Component {...rest} {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
}

class AuthenticatedRoute extends Component {
  render() {
    const { component: Component, isAuthenticated, exact, path } = this.props;

    return (
      <Route
        exact={exact}
        path={path}
        render={props =>
          // info: {...props} <=> {history, location, match, staticContext}
          isAuthenticated ? (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }
}

class Root extends Component {
  render() {
    const { isAuthenticated, user } = this.props;

    if (isAuthenticated && !user) {
      this.props.getUser();
    }

    return (
      <div id="content">
        <BrowserRouter>
          <Switch>
            <AuthenticatedRoute
              path="/login"
              exact
              component={LoginContainer}
              isAuthenticated={isAuthenticated}
            />
            <AuthenticatedRoute
              path="/reset_password"
              exact
              component={ResetPasswordContainer}
              isAuthenticated={isAuthenticated}
            />
            <Route path="/404" component={NotFound} />
            <PrivateRoute
              path="/"
              exact
              component={DashboardCommon}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/custom"
              exact
              component={DashboardBusinessContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/report/:id"
              component={ReportContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/IOCEvents"
              component={IOCEventsPage}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/adversaries"
              component={AdversariesPageContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/search"
              component={SearchPage}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/tickets"
              component={TicketPageContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/ticket/new/:title?/:ticketType?"
              component={NewTicketContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/ticket/:id"
              component={TicketContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/settings"
              component={SettingsContainer}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              path="/adversary/:id"
              component={AdversaryContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/favorite"
              component={FavoritesContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/IOC/:id"
              component={IOCPageContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/tool/:id"
              component={ToolPageContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/reports"
              component={ReportsPage}
              reportsType="GLOBAL_REPORTS"
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/special_reports"
              component={ReportsPage}
              reportsType="SPECIAL_REPORTS"
              isAuthenticated={isAuthenticated}
              title={
                <Localized id="special_reports">special_reports</Localized>
              }
              user={user}
            />
            <PrivateRoute
              path="/custom_reports"
              component={ReportsPage}
              reportsType="focused"
              isAuthenticated={isAuthenticated}
              title={<Localized id="custom_reports">custom_reports</Localized>}
              user={user}
            />
            <PrivateRoute
              path="/profile"
              component={ProfileContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/user/:id"
              component={ProfileContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/profile"
              component={ProfileContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              path="/user/:id"
              component={ProfileContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <PrivateRoute
              exact
              path="/documentazione/api"
              component={APIDoc}
              isAuthenticated={isAuthenticated}
              pageType="api-doc"
              user={user}
              title={
                <Localized id="documentations">documentations_api</Localized>
              }
            />
            <PrivateRoute
              path="/documentazione"
              component={DynamicsPage}
              pageType="documentazione"
              isAuthenticated={isAuthenticated}
              user={user}
              title={<Localized id="documentations">documentations</Localized>}
            />

            <PrivateRoute
              path="/changelog"
              component={DynamicsPage}
              pageType="changelog"
              isAuthenticated={isAuthenticated}
              user={user}
              title={<Localized id="changelog">changelog</Localized>}
            />
            <PrivateRoute
              path="/faq"
              component={DynamicsPage}
              pageType="faq"
              isAuthenticated={isAuthenticated}
              user={user}
              title={<Localized id="faq">faq</Localized>}
            />
            <PrivateRoute
              path="/page/:category/:id"
              component={DynamicContainer}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.session.isAuthenticated,
    user: state.user.user['me'],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: () => dispatch(getUser()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Root);
