import React, { Component } from 'react';
import { connect } from 'react-redux';
import Exploits from '../../components/Adversary/Exploits';

import { getExploitFromActorName } from '../../actions/exploits';
class ExploitsContainer extends Component {
  static defaultProps = {
    size: 10,
  }

  state = {
    page: 1,
  }

  goNext = () => this.setState({ page: this.state.page + 1 });
  goBack = () => this.setState({ page: this.state.page - 1 });
  goToPage = (page) => this.setState({ page });

  componentDidMount() {
    const { actorName, exploits } = this.props;
    if (!exploits[actorName]) {
      this.getExploits(actorName);
    }
  }

  componentDidUpdate(pP, pS) {
    const { actorName } = this.props;
    if (this.state.page !== pS.page) {
      this.getExploits(actorName);
    }
  }

  getExploits = (actorName) => {
    this.props.getExploit({
      params: {
        name: actorName,
      },
      query: {
        from: ((this.state.page  * this.props.size) - this.props.size),
        to: this.props.size,
      }
    });
  }

  render() {
    const { exploits, actorName } = this.props;

    let tool = exploits[actorName]
    return (
      <Exploits {...this.props} exploits={tool} {...this.state} goNext={this.goNext} goBack={this.goBack} goToPage={this.goToPage} />
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getExploit: (...args) => dispatch(getExploitFromActorName(...args))
  }
}

export default connect(
  (state) => ({
    exploits: state.exploits.exploits,
    isLoading: state.exploits.isLoading,
    hasFetchedData: state.exploits.hasFetchedData,
    requestErrorMessage: state.exploits.requestErrorMessage
  }),
  mapDispatchToProps
)(ExploitsContainer);
