const initialState = {
  ioc: {},
  iocs: {},
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_IOC_COUNTER_LIST_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
      };

    case 'GET_IOC_COUNTER_LIST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        ioc: action.data,
        lastUpdate: action.lastUpdate,
      };

    case 'GET_IOC_COUNTER_LIST_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
      };


      case 'GET_IOC_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
      };

    case 'GET_IOC_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        iocs: {
          ...state.iocs,
          [action.id]: action.data
        }
      };

    case 'GET_IOC_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
      };

    default:
      return state;
  }
}
