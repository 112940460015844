import React, { Component } from "react";
import { Localized } from "@fluent/react";
import AppShell from "../AppShell";
import Spinner from "../Spinner";
import "./Profile.scss";

export default class Profile extends Component {
  state = {
    isEditing: false,
    userEdited: false,
    requestFeedback: false,
    currentPassword: "",
    password: "",
    password_repeat: "",
    isPasswordMatch: true,
    isPasswordOk: true,
    isCurrentOk: true,
    errorMatch: false,
    errorLenght: false,
    errorCurrent: false,
  };

  componentDidUpdate() {
    if (!this.state.isEditing && this.state.userEdited) {
      this.setState({ userEdited: false, requestFeedback: true });
    }
  }

  toggleEdit = () => {
    //e && e.preventDefault();

    this.setState((state) => ({
      isEditing: !state.isEditing,
      requestFeedback: false,
      isPasswordMatch: true,
      isPasswordOk: true,
      isCurrentOk: true,
      currentPassword: "",
      password: "",
      password_repeat: "",
      errorMatch: false,
      errorLenght: false,
      errorCurrent: false,
    }));
  };

  onChange = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  validateForm = (currenPsw, psw, pswRepeat) => {
    let pswMatch = false;
    let pswOk = false;
    let currentOk = false;

    if (currenPsw) {
      if (this.state.currentPassword.length >= 8) {
        currentOk = true;
        this.setState({ isCurrentOk: true, errorCurrent: false });
      } else {
        currentOk = false;
        this.setState({
          isCurrentOk: false,
          errorCurrent: (
            <Localized id="insert_current_password">
              insert_current_password
            </Localized>
          ),
        });
      }
    }

    if (psw) {
      if (this.state.password.length >= 8) {
        pswMatch = true;
        this.setState({ isPasswordOk: true, errorLenght: false });
      } else {
        pswMatch = false;
        this.setState({
          isPasswordOk: false,
          errorLenght: (
            <Localized id="password_is_too_short">
              password_is_too_short
            </Localized>
          ),
        });
      }
    }

    if (pswRepeat) {
      if (this.state.password === this.state.password_repeat) {
        pswOk = true;
        this.setState({ isPasswordMatch: true, errorMatch: false });
      } else {
        pswOk = false;
        this.setState({
          isPasswordMatch: false,
          errorMatch: (
            <Localized id="password_doesnt_match">
              password_doesnt_match
            </Localized>
          ),
        });
      }
    }

    return pswMatch && pswOk && currentOk;
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { id, firstname, lastname, name, email } = this.props.user;

    const isValid = this.validateForm(true, true, true);

    if (!isValid) {
      return false;
    }

    this.props.editUser(id, {
      password: this.state.password === "" ? undefined : this.state.password,
      current_password:
        this.state.currentPassword === ""
          ? undefined
          : this.state.currentPassword,
      name: name,
      email: email,
      first_name: firstname,
      last_name: lastname,
    });

    this.setState({ userEdited: true });
    this.toggleEdit();
  };

  render() {
    const {
      user,
      data: { error, isLoading },
    } = this.props;
    const {
      isEditing,
      requestFeedback,
      isPasswordMatch,
      isPasswordOk,
      isCurrentOk,
      errorMatch,
      errorLenght,
      errorCurrent,
    } = this.state;

    /*if(userEdited && isEditing){
      this.toggleEdit();
      return null;
    }*/

    if (!user) {
      return null;
    }

    if (isLoading) {
      return <Spinner />;
    }

    const { name, email } = user;

    return (
      <AppShell {...this.props} noFilterBar>
        <div className="row justify-content-center">
          <div className="col col-lg-10 col-xl-8">
            <div className="card border-light">
              <div className="card-header" id="user-header">
                <h2>
                  <i className="fa fa-user-o mr-2" />{" "}
                  <Localized id="profile">profile</Localized>{" "}
                </h2>
              </div>
              {!isEditing ? (
                <div id="user-profile">
                  <div className="card-body p-4">
                    {requestFeedback &&
                      (error ? (
                        <div className="alert alert-danger" role="alert">
                          <Localized id="change_password_msg_error">
                            change_password_msg_error
                          </Localized>
                        </div>
                      ) : (
                        <div className="alert alert-success" role="alert">
                          <Localized id="change_password_msg_success">
                            change_password_msg_success
                          </Localized>
                        </div>
                      ))}

                    <p className="px-2 py-1">
                      <span className="text-muted h5 font-weight-bold">
                        <Localized id="name">name</Localized>:{" "}
                      </span>
                      <span className="ml-3 d-inline-block">{name}</span>
                    </p>
                    <p className="px-2 py-1">
                      <span className="text-muted h5 font-weight-bold">
                        <Localized id="e_mail">e_mail</Localized>:{" "}
                      </span>
                      <span className="ml-3 d-inline-block">{email}</span>
                    </p>
                    <p className="px-2 py-1">
                      <span className="text-muted h5 font-weight-bold">
                        <Localized id="api_token">api_token</Localized>:{" "}
                      </span>
                      <span className="ml-3 d-block">
                        {localStorage.getItem("api_token")}
                      </span>
                    </p>
                    <p className="px-2 py-1">
                      <span className="text-muted h5 font-weight-bold">
                        <Localized id="cdn_token">cdn_token</Localized>:{" "}
                      </span>
                      <span className="ml-3 d-block">
                        {localStorage.getItem("cdn_token")}
                      </span>
                    </p>
                    <p className="px-2 py-1">
                      <span className="text-muted h5 font-weight-bold">
                        <Localized id="feed_token">feed_token</Localized>:{" "}
                      </span>
                      <span className="ml-3 d-block">
                        {localStorage.getItem("feed_token")}
                      </span>
                    </p>
                  </div>

                  <div className="card-footer text-center">
                    <button
                      className="btn btn-primary"
                      onClick={this.toggleEdit}
                    >
                      <Localized id="change_password">
                        change_password
                      </Localized>
                    </button>
                  </div>
                </div>
              ) : (
                <div id="user-form">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group px-5 pt-4">
                      <label htmlFor="pwd">
                        {errorCurrent ? (
                          <span className="text-danger">{errorCurrent}</span>
                        ) : (
                          <Localized id="current-password">
                            current-password
                          </Localized>
                        )}
                      </label>
                      <input
                        className={`form-control ${
                          isCurrentOk ? "" : "password-wrong"
                        }`}
                        type="password"
                        autoComplete="off"
                        value={this.state.currentPassword}
                        onChange={(e) => this.onChange("currentPassword", e)}
                        onBlur={() => this.validateForm(true, false, false)}
                      />
                    </div>
                    <div className="form-group px-5">
                      <label htmlFor="pwd">
                        {errorLenght ? (
                          <span className="text-danger">{errorLenght}</span>
                        ) : (
                          <Localized id="new_password">new_password</Localized>
                        )}
                      </label>
                      <input
                        className={`form-control ${
                          isPasswordOk ? "" : "password-wrong"
                        }`}
                        type="password"
                        value={this.state.password}
                        autoComplete="off"
                        onChange={(e) => this.onChange("password", e)}
                        onBlur={() => this.validateForm(false, true, false)}
                      />
                    </div>
                    <div className="form-group px-5 pb-4">
                      <label htmlFor="pwd-repeat">
                        {errorMatch ? (
                          <span className="text-danger">{errorMatch}</span>
                        ) : (
                          <Localized id="repeat_new_password">
                            repeat_new_password
                          </Localized>
                        )}
                      </label>
                      <input
                        className={`form-control ${
                          isPasswordMatch ? "" : "password-wrong"
                        }`}
                        type="password"
                        autoComplete="off"
                        value={this.state.password_repeat}
                        onChange={(e) => this.onChange("password_repeat", e)}
                        onBlur={() => this.validateForm(false, false, true)}
                      />
                    </div>

                    <div className="card-footer text-center">
                      <button
                        className="btn btn-primary mr-4"
                        type="button"
                        onClick={this.toggleEdit}
                      >
                        <Localized id="cancel">cancel</Localized>
                      </button>
                      <button className="btn btn-primary" type="submit">
                        <Localized id="send">send</Localized>
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </AppShell>
    );
  }
}
