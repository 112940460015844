import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AppShell from "../AppShell";
import AdversariesList from "../AdversariesList";
import Map from "../Map";
import AdversariesFilter from "./AdversariesFilter";
import "./AdversariesPage.scss";
import { Localized } from "@fluent/react";

export default class AdversariesPage extends Component {
  onZoom = () => {
    this.map.onZoomIn();
  };

  onReset = () => {
    this.map.onReset();
  };

  onFilter = (item) => {
    this.props.history.push(`/adversaries?page=1&${item.taxonomy}=${item.id}`);
  };

  render() {
    const {
      adversaries,
      availableFilters,
      currentFilters,
      isLoading,
      match,
      page,
      pagination,
      user,
      url,
    } = this.props;

    return (
      <AppShell match={match} noFilterBar user={user}>
        <Row>
          <Col md={{ size: 6 }} sm={{ size: 12, offset: 0 }}>
            <AdversariesList
              currentFilters={currentFilters}
              isLoading={isLoading}
              list={adversaries}
              page={page}
              pagination={pagination}
              showPagination
              url={url}
            />
          </Col>
          <Col sm={12} md={{ size: 6 }}>
            <Map
              ref={(map) => (this.map = map)}
              filter={availableFilters}
              cbClick={this.onFilter}
              isLoading={isLoading}
            />
            <Row>
              <Col sm={12} md={{ size: 4, offset: 4 }}>
                <div className="reset-box">
                  <Link to="/adversaries?page=1">
                    <button
                      className="btn filter-box-btn"
                      onClick={this.onReset}
                    >
                      <Localized id="reset">reset</Localized>
                    </button>
                  </Link>
                </div>
              </Col>
            </Row>
            <AdversariesFilter
              currentFilters={currentFilters}
              availableFilters={availableFilters}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </AppShell>
    );
  }
}
