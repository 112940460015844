/**
 * Preleva dal backend la lista degli eventi IOC.
 * Viene diviso lo storage all'interno dello store di Redux, in base al parametro {eventsType}
 * @-param {string} eventsType - Tipologia di eventi, ad esempio: DEFAULT.
 * @-param {object} data
 * @-param {object} data.params - Oggetto che verrà transofrmato in query-string nella richiesta.
 * @-param {string} data.params.from - Size della risposta dalle api - Paginazione
 * @-param {string} data.params.to - Paginazione
 * @-param {number} data.params.ID - Opzionale - Preleva il singolo evento IOC.
 */
export function getThreatsList(eventsType, data) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_THREAT_EVENTS_REQUEST',
      eventsType,
    });

    let d = null;
    if (data.params.ID) {
      if (Array.isArray(data.params.ID)) {
        d = {
          data: (
            await Promise.all(
              data.params.ID.map((id) => api.get(`threats/event/${id}`, data.params).then((dd) => dd.data)),
            )
          ).filter((d) => !!d),
        };
      } else {
        d = await api.get(`threats/event/${data.params.ID}`, data.params);
      }
    } else {
      d = await api.get(`threats/event`, data.params);
    }

    const orgID = data.params.org_id;
    data.params = undefined;

    if (d.error) {
      dispatch({
        type: 'GET_THREAT_EVENTS_FAILED',
        message: d.error,
      });
    } else {
      dispatch({
        type: 'GET_THREAT_EVENTS_SUCCESS',
        data: d.data,
        eventsType,
        page: data.page,
        orgID: orgID,
      });
    }
  };
}

/**
 * Effettua la ricerca degli eventi IOC, a partire da una query.
 * Internamente genera un eventsType, da utilizzare per dividere logicamente i dati dalle dashboard, da quelle per singole query. Nello store di Redux essi verrano messi in un oggetto con chiave eventsType, e value.
 * @-param {string} [query=''] Stringa da ricercare
 * @-param {object} params Query-string
 */
export function getThreatsListSearch(query = '', data) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_THREAT_EVENTS_REQUEST',
      eventsType: `SEARCH_${query.slice(0, 5)}`,
    });

    const d = await api.get(`threats/search/${encodeURIComponent(query)}`, data.params);
    const orgID = data.params.org_id;

    if (d.error) {
      dispatch({
        type: 'GET_THREAT_EVENTS_FAILED',
        message: d.error,
      });
    } else {
      dispatch({
        type: 'GET_THREAT_EVENTS_SUCCESS',
        data: d.data,
        eventsType: `SEARCH_${query.slice(0, 5)}`,
        page: data.page,
        orgID: orgID,
      });
    }
  };
}
/**
 * Preleva i dati necessari per popolare il widget Threats Sources, nella dashboard business.
 * Preleva il filtro di data dallo store di Redux.
 */
export function getThreatSourcesGraph(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_THREAT_SOURCES_GRAPH_REQUEST',
    });

    const dateFilter = getState().impact.dateFilter;
    const d = await api.get('reports/threatSources', dateFilter.filter);

    if (d.error) {
      dispatch({
        type: 'GET_THREAT_SOURCES_GRAPH_FAILED',
        message: d.error,
      });
    } else {
      dispatch({
        type: 'GET_THREAT_SOURCES_GRAPH_SUCCESS',
        data: d.data,
      });
    }
  };
}
/**
 * Action utilizzata per effettuare il download.
 * Effettua il donwload in maniera programmatica, creando internamente un elemento di tipo <a />, ed asseggnandoli un Blob.
 * [DOC](https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link)
 * @param {object} data
 * @param {number} data.id - ID dell'evento da prelevare.
 * @param {string} data.format - Formato con cui effettuare il download dell'evento.
 */
export function downloadEvent(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_EVENT_DOWNLOAD_REQUEST',
    });

    const d = await api.get(`threats/event/${data.id}/download/${data.format}`, { attachment: data.attachment }, false);

    if (!d) {
      dispatch({
        type: 'GET_EVENT_DOWNLOAD_FAILED',
        message: d.error,
      });
    } else {
      dispatch({
        type: 'GET_EVENT_DOWNLOAD_SUCCESS',
      });

      const blob = new Blob([d], {
          type: 'octet/stream',
        }),
        element = document.createElement('a'),
        url = window.URL.createObjectURL(blob);

      element.href = url;
      element.download = data.filename || `${data.id}.${data.format}`;
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };
}
