import React, { Component } from 'react';

export class SettingSwitch extends Component {
  static defaulProps = {
    checked: false,
    onToggle: () => undefined,
    title: '',
  };

  render() {
    const { title, checked, onToggle } = this.props;

    return (
      <div className="mb-3">
        <label className="switch switch-3d switch-success">
          <input
            type="checkbox"
            className="switch-input"
            checked={checked}
            onChange={() => onToggle(!checked)}
            value=""
          />
          <span className="switch-label" />
          <span className="switch-handle" />
          <div className="label-switch">{title}</div>
        </label>
      </div>
    );
  }
}
