/**
 * Preleva i settings dell'utente, dalle api, per poi settarle nel reducer.
 * @param {id} id ID dell'utente.
 * @param {object} data Parametri della GET
 * @param {any} router
 */
export function getSetting(id, data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_SETTINGS_REQUEST'
    });

    const d = await api.get('settings', data);

    if (d.error) {
      dispatch({
        type: 'GET_SETTINGS_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_SETTINGS_SUCCESS'
      });
    }
  };
}
/**
 * Modifica tramite una chiamata POST, i settings dell'utente.
 *
 * @param {number} id  ID dell'utente su cui modificare i settings.
 * @param {object} data Shallow object contente le modifiche da effettuare.
 */
export function editSettings(id, data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'SETTINGS_SAVE_REQUEST'
    });

    const d = await api.post(`users/${id}/settings`, data);

    if (d.error) {
      dispatch({
        type: 'SETTINGS_SAVE_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'SETTINGS_SAVE_SUCCESS',
        id: 'me',
        data: d.data
      });
    }
  };
}
