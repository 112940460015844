/**
 * Preleva la lista dei report.
 * @-param {object} data
 * @-param {string} data.type Stringa che rappresenta il reportsType, usato internamente per isolare nel reducer, le varie tipologie di report.
 * @-param {object} data.hashFilter Oggetto che contiene il filtro da applicare ai reports. { filter: { }}
 * @-param {any} router
 */
export function getReportsList(data) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_REPORTS_LIST_REQUEST',
      reportsType: data.type
    });

    const dateFilter = data.hashFilter ? (
      getState().impact.dateFilter
    ) :(
      { filter: {} }
    );

    const d = await api.get('reports', { ...data.params, ...dateFilter.filter });

    if (d.error) {
      dispatch({
        type: 'GET_REPORTS_LIST_FAILED',
        message: d.error,
        reportsType: data.type
      });
    } else {
      dispatch({
        type: 'GET_REPORTS_LIST_SUCCESS',
        reportsType: data.type,
        data: d.data,
        page: data.params.page,
        extra: d._extra
      });
    }
  };
}
