import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export default class OrgFilterDropdown extends Component {
  state = {
    dropdownOpen: false,
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  render() {
    const { changeOrgFilter, orgId } = this.props;

    return (
      <Dropdown
        className="count-org-filter"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle caret>
          <img src={`/img/org/${orgId}.svg`} alt="Selected organization" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => changeOrgFilter(-1)}>
            <img src="/img/org/-1.svg" alt="" />
          </DropdownItem>
          <DropdownItem onClick={() => changeOrgFilter(1)}>
            <img src="/img/org/1.svg" alt="" />
          </DropdownItem>
          <DropdownItem onClick={() => changeOrgFilter(292)}>
            <img src="/img/org/292.svg" alt="" />
          </DropdownItem>
          <DropdownItem onClick={() => changeOrgFilter(294)}>
            <img src="/img/org/294.svg" alt="" />
          </DropdownItem>
          <DropdownItem onClick={() => changeOrgFilter(295)}>
            <img src="/img/org/295.svg" alt="" />
          </DropdownItem>
          <DropdownItem onClick={() => changeOrgFilter(289)}>
            <img src="/img/org/289.svg" alt="" />
          </DropdownItem>
          <DropdownItem onClick={() => changeOrgFilter(377)}>
            <img src="/img/org/377.svg" alt="" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
