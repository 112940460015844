import React, { Component } from 'react';
import { connect } from 'react-redux';
import Attributes from '../../components/Attributes';
import { getAttributesFromEventId } from '../../actions/attributes';

class AttributesContainer extends Component {
  static defaultProps = {
    size: 10,
  }

  componentDidMount() {
    const { eventID, attributes } = this.props;
    if (!attributes[eventID]) {
      this.getAttributes(eventID);
    }
  }

  componentDidUpdate(prevProps) {
    const { eventID } = this.props;
    if (this.props.location.search !== prevProps.location.search) {
      this.getAttributes(eventID);
    }
  }

  getAttributes = (eventID) => {
    let params = new URLSearchParams(this.props.location.search.substring(1));
    let page = "1";
    const regex = /^[0-9]*$/g;
    
    if (params.get("page") && params.get("page").match(regex)) {
      page = params.get("page");
    } else {
      page = "1";
    }

    this.props.getAttributes({
      page: page,
      params: {
        ID: eventID,
      },
      query: {
        from: ((page * this.props.size) - this.props.size),
        to: this.props.size,
      }
    });
  }

  render() {
    const { attributes, eventID, isLoading, location, page, size } = this.props;
    let attrs = attributes[eventID];
    const pathname = location ? location.pathname : "";

    return (
      <Attributes attributes={attrs} isLoading={isLoading} size={size} page={page} url={pathname} />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAttributes: (...args) => dispatch(getAttributesFromEventId(...args))
  }
}

const mapStateToProps = (state) => {
  return {
    attributes: state.attributes.attributes,
    isLoading: state.attributes.isLoading,
    page: state.attributes.page,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AttributesContainer);
