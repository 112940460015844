import React, { useState, useEffect } from 'react';
import { Localized } from '@fluent/react';
import './LanguageSelect.scss';

export default function LanguageSelect({ label }) {
  const [selectLanguageValue, setSelectLanguageValue] = useState();

  useEffect(() => {
    if (
      localStorage.getItem('language') &&
      localStorage.getItem('language') !== 'undefined'
    ) {
      setSelectLanguageValue(localStorage.getItem('language'));
    } else {
      localStorage.setItem('language', navigator.language);
      setSelectLanguageValue(navigator.language);
    }
  }, []);

  return (
    <div className="lang-select">
      {/* <label
        htmlFor="select-language"
        style={!label ? { display: 'none' } : { display: 'inline-block' }}
      >
        <Localized id="select_language">select_language</Localized>
      </label>{' '}
      <select
        id="select-language"
        name="select-language"
        value={selectLanguageValue}
        onChange={e => {
          localStorage.setItem('language', e.target.value);
          setSelectLanguageValue(e.target.value);
          window.location.reload(false);
        }}
      >
        <option value="en-US">English</option>
        <option value="it-IT">Italiano</option>
      </select> */}
    </div>
  );
}
