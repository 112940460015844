const initialState = {
  tickets: [],
  ticket: {},
  info: {},
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_TICKETS_LIST_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true
      };

    case 'GET_TICKETS_LIST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        tickets: action.data
      };

    case 'GET_TICKETS_LIST_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false
      };

    case 'ADD_NEW_TICKET_SUCCESS':
      if (!action.data.attachment) {
        action.data.attachment = [];
      }
      return {
        ...state,
        tickets: [action.data, ...state.tickets],
        ticket: {
          ...state.ticket,
          [action.data.id]: action.data
        }
      };
    case 'GET_TICKET_SUCCESS':
      return {
        ...state,
        ticket: {
          ...state.ticket,
          [action.data.id]: action.data
        }
      };

    case 'ADD_NEW_TICKET_REPLY_SUCCESS':
      return {
        ...state,
        ticket: {
          ...state.ticket,
          [action.id]: {
            ...state.ticket[action.id],
            comment: action.data.comment
          }
        }
      };

    case 'ADD_NEW_TICKET_REPLY_ATTACHMENT_SUCCESS':
      return {
        ...state,
        ticket: {
          ...state.ticket,
          [action.id]: {
            ...state.ticket[action.id],
            attachment: [...state.ticket[action.id].attachment, action.data]
          }
        }
      };

    case 'GET_TICKET_INFO_SUCCESS':
      return {
        ...state,
        info: {
          ...state.info,
          ...action.data
        }
      };

    default:
      return state;
  }
}
