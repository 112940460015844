/**
 * Effettua una richiesta HTTP GET, dall'endpoint 'reports/impact'.
 * Preleva il dateFilter dallo stato redux.
 * @param {object} data
 * @param {object} data.filter DateFilter
 */
export function getImpactData(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_IMPACT_REQUEST'
    });

    const dateFilter = getState().impact.dateFilter;

    const d = await api.get('reports/impact', dateFilter.filter);

    if (d.error) {
      dispatch({
        type: 'GET_IMPACT_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_IMPACT_SUCCESS',
        data: d.data
      });
    }
  };
}
/**
 * Modifica il filtro per gli impact {DateFilter}
 *
 * @param {object} filter Il nuovo filtro da sostituire a quello attuale
 */
export function editImpactDateFilter(filter, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'EDIT_IMPACT_FILTER',
      filter
    });

    dispatch(getImpactData());
  };
}
