import React, { Component } from 'react';
import AppShell from '../AppShell';
import ReportsListContainer from '../../containers/ReportsList';

export default class ReportsPage extends Component {
  render() {
    const { location, match, reportsType, title, user } = this.props;

    return (
      <AppShell match={match} noFilterBar user={user}>
        <div className="row">
          <div className="col">
            <ReportsListContainer
              location={location}
              perPage="10"
              reportsType={reportsType}
              showPagination
              showFeedRSS
              title={title}
              urlPagination={true}
            />
          </div>
        </div>
      </AppShell>
    );
  }
}
