import React, { Component } from 'react';
import './EmptyState.scss';
import { Card, CardBody, Jumbotron } from 'reactstrap';
export default class EmptyState extends Component {
  static defaultProps = {
    label: 'No items found',
    img: '',
  };

  render() {
    const { label, img } = this.props;

    return (
      <Card>
        <CardBody>
          <div className="row empty-list">
            <div className="col-12 col-xl-6 col-lg-6">
              <img className="img-fluid" alt="No content" src={img} />
            </div>
            <div className="col-12 col-xl-6 col-lg-6">
              <Jumbotron>
                <h2>{label}</h2>
              </Jumbotron>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

/* 
      return (
        <div className="card-body empty-state">
          <img
            className="empty-state-img"
            src="/img/reports.svg"
            alt="Nessun evento trovato"
          />
          <div className="empty-state-col">
            <h2>Nessun evento trovato</h2>
          </div>
        </div>
      ); */
