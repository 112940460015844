import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeltaList from '../../components/DeltaList';
import { getIOCCounterList } from '../../actions/ioc';

class IOCCounterListContainer extends Component {
  componentDidMount() {
    this.props.getIOCCounterList();
  }

  setOrgId = orgId => {
    this.props.getIOCCounterList(orgId);
  };

  render() {
    return <DeltaList {...this.props} setOrgId={this.setOrgId} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getIOCCounterList: (...args) => dispatch(getIOCCounterList(...args)),
  };
}

function mapStateToProps(state) {
  return {
    ioc: state.ioc.ioc,
    isLoading: state.ioc.isLoading,
    hasFetchedData: state.ioc.hasFetchedData,
    requestErrorMessage: state.ioc.requestErrorMessage,
    lastUpdate: state.ioc.lastUpdate,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IOCCounterListContainer);
