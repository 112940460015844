import React, { Component } from 'react';
import InlineSpinner from '../Spinner/InlineSpinner';
import { Localized } from '@fluent/react';
export default class NewTicketReply extends Component {
  state = {
    comment: '', // Stato contentente la value della textbox
    isSubmitting: false, // Stato contenente lo stato, se il componente e' in fase di submitting o no
  };

  /**
   * onChange handler per la inputbox del commento
   *
   * @memberof NewTicketReply
   *
   */
  onCommentChange = e =>
    this.setState({
      comment: e.target.value,
    });

  /**
   * onSubmit handler
   * Chiama la callback passatagli dal container, per mandare una risposta al ticket corrente.
   * Ed in seguito, pulisce la inputbox.
   */
  onSubmit = () => {
    this.props
      .addNewTicketReply(this.props.ticket.id, {
        comment: this.state.comment,
      })
      .then(() => {
        this.setState({
          comment: '',
        });
      });
  };

  /**
   * onFileUpload - Esegue l'upload del file correntemente selezionato, tramite un azione.
   * Crea internamente un FormData(), a cui viene effettuato l'append del file, per poi poterlo mandare in forma di multi-part al back-end.
   * E crea, in successione, anche un commento al ticket, con il markdown preparato per linkare al file appena caricato.
   */
  onFileUpload = e => {
    const file = e.target.files[0];
    const form = new FormData();

    if (file) {
      form.append('file', e.target.files[0]);

      this.setState({
        isSubmitting: true,
      });

      this.props
        .addNewTicketReplyAttachment(this.props.ticket.id, form)
        .then(e => {
          if (this.state.comment.length > 0) {
            const text = this.state.comment + ` [${e.name}](${e.url})`;
            this.setState({
              comment: text,
            });
          } else {
            const text = `[${e.name}](${e.url})`;
            this.setState(
              {
                comment: text,
              },
              () => {
                this.onSubmit();
              }
            );
          }
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    const { is_closed } = this.props;
    const { comment } = this.state;

    if (is_closed) {
      return null;
    }

    return (
      <div className="ticket-form">
        <div className="row">
          <div className="col col-12 ticket-right">
            <div className="form-group">
              <label htmlFor="content">
                <Localized id="reply">reply</Localized>
              </label>
              <textarea
                className="form-control"
                id="content"
                rows="7"
                value={comment}
                onChange={this.onCommentChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="file-upload" className="d-block">
                <Localized id="ticket_file_upload">
                  ticket_file_upload
                </Localized>{' '}
              </label>
              {this.state.isSubmitting ? (
                <InlineSpinner
                  className="custom-file-spinner"
                  height={35}
                  width={35}
                />
              ) : (
                <span className="custom-file">
                  <input
                    className="custom-file-input"
                    id="file-upload"
                    type="file"
                    onChange={this.onFileUpload}
                  />
                  <Localized
                    id="ticket_file_upload_input"
                    attrs={{ 'data-value': true }}
                  >
                    <span
                      className="custom-file-control"
                      id="ticket-attach"
                      data-value="ticket_file_upload_input "
                    />
                  </Localized>
                </span>
              )}
            </div>

            <div className="ticket-actions">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.onSubmit}
              >
                <Localized id="send">send</Localized>
              </button>
              {/* <label className="custom-file">
                {this.state.isSubmitting && (
                  <InlineSpinner className="custom-file-spinner" height={35} width={35}/>
                )} 
                <input className="custom-file-input" type="file" onChange={this.onFileUpload} /> 
                <span className="custom-file-control"></span>
              </label>  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
