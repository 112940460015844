import React, { Component } from 'react';
import AppShell from '../AppShell';
import IOCEventsListContainer from '../../containers/IOCEventsList';
import Spinner from '../Spinner/index';

export default class IOCEventsPage extends Component {
  render() {
    const { history, isLoading, location, match, user } = this.props;
    return (
      <AppShell noFilterBar match={match} user={user}>
        <div className="row justify-content-center">
          {isLoading ?
            <Spinner /> :
            <div className="col">
              <IOCEventsListContainer 
                history={history}
                location={location}
                match={match}
                showORGFilter={true}
                showPagination={true}
                urlPagination={true}
              />
            </div>
          }
        </div>
      </AppShell>
    );
  }
}
