import React, { Component } from "react";
import AppShell from "../AppShell";
import ReportList from "../ReportsList";
import { Localized } from "@fluent/react";

export default class Favorites extends Component {
  static defaltProps = {
    favorites: [],
  };

  render() {
    const { favorites } = this.props;
    return (
      <AppShell noFilterBar {...this.props}>
        <div className="row justify-content-center">
          <div className="col">
            <ReportList
              title={<Localized id="favorites">favorites</Localized>}
              reports={favorites}
              {...this.props}
            />
          </div>
        </div>
      </AppShell>
    );
  }
}
