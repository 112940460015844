const initialState = {
  tool: {},
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_TOOL_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
      };

    case 'GET_TOOL_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        tool: {
          ...state.tools,
          [action.data.id]: action.data,
        }
      };

    case 'GET_TOOL_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
      };

    default:
      return state;
  }
}
