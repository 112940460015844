import React, { Component } from 'react';
import classnames from 'classnames';
import './InlineSpinner.scss';

export default class InlineSpinner extends Component {
  static defaultProps = {
    width: 65,
    height: 65
  };

  render() {
    const { width, height, className } = this.props;
    return (
      <svg
        className={classnames('inine-spinner', className)}
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    );
  }
}
