const initialState = {
  reports: {},
  isLoading: false,
  requestErrorMessage: "",
  hasFetchedData: false,
  pagination: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "GET_REPORTS_LIST_REQUEST":
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
        reports: {
          ...state.reports,
          [action.reportsType]: []
        }
      };

    case "GET_REPORTS_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        reports: {
          ...state.reports,
          [action.reportsType]: action.data
        },
        page: action.page,
        pagination: {
          ...state.pagination,
          [action.reportsType]: action.extra
        }
      };

    case "GET_REPORTS_LIST_FAILED":
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
        reports: {
          ...state.reports,
          [action.reportsType]: []
        }
      };

    case "ADD_TO_FAVORITE_SUCCESS": {
      let modified = false;
      for (const key in state.reports) {
        const cloneArr = state.reports[key].slice(0);

        for (let report of cloneArr) {
          if (report.id === action.id) {
            report.favorite = true;
            modified = true;
          }
        }
        if (modified) {
          return {
            ...state,
            reports: {
              ...state.reports,
              [key]: cloneArr
            }
          };
        }
      }
      return state;
    }

    case "DELETE_FROM_FAVORITE_SUCCESS": {
      let modified = false;
      for (const key in state.reports) {
        const cloneArr = state.reports[key].slice(0);

        for (let report of cloneArr) {
          if (report.id === action.id) {
            report.favorite = false;
            modified = true;
          }
        }
        if (modified) {
          return {
            ...state,
            reports: {
              ...state.reports,
              [key]: cloneArr
            }
          };
        }
      }
      return state;
    }

    default:
      return state;
  }
}
