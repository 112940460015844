import React, { Component } from 'react';
import { Col, Row, Card, CardHeader, CardBody, Table } from 'reactstrap';
import AppShell from '../AppShell';

export default class ToolPage extends Component {
  static defaultProps = {
    tool: {}
  };

  render() {
    const {tool} = this.props;
    //const { id, name, description, attributes } = this.props.tool;

    return (
      <AppShell noFilterBar {...this.props}>
        <Row>
          <Col sm={{ size: 10, offset: 1 }}>
            <Card>
              <CardHeader>
                <h1>{tool.name}</h1>
              </CardHeader>
              { tool.description ? (<CardBody>{tool.description}</CardBody>) : '' }
            </Card>

            { tool.attributes ? (<Card>
              <CardHeader>Details</CardHeader>
              <CardBody>
                <Table striped>
                  <thead>
                    <tr>
                      <th>key</th>
                      <th>value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tool.attributes.map((d, i) => (
                      <tr key={i} >
                        <td>{d.field}</td>
                        <td>{d.value}</td>
                      </tr>
                    ))
                    // key: count delta
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>) : ''}
          </Col>
        </Row>
      </AppShell>
    );
  }
}
