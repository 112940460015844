import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import * as d3 from 'd3';
import './ThreatSources.scss';
import Spinner from '../Spinner';

export default class ThreatSources extends Component {
  componentDidMount() {
    const { isLoading, hasFetchedData, data } = this.props;
    if (!isLoading && hasFetchedData && data.length) {
      this.createChart();
    }
  }

  componentDidUpdate(nextProps) {
    if ((nextProps.data !== this.props.data) && !this.props.isLoading){
      this.createChart();
    }
  }

  createChart = () => {
    if (!this.node) return;

    let { data: root } = this.props;
    const svg = d3.select(this.node);

    const margin = 20,
      diameter = 700,
      g = svg
        .append('g')
        .attr(
          'transform',
          'translate(' + diameter / 2 + ',' + diameter / 2 + ')'
        );
    var color = d3
      .scaleLinear()
      .domain([-1, 5])
      .range(['#fff', '#4461a7'])
      .interpolate(d3.interpolateRgb);

    var pack = d3
      .pack()
      .size([diameter - margin, diameter - margin])
      .padding(2);

    root = d3
      .hierarchy(root)
      .sum(function(d) {
        return d.size;
      })
      .sort(function(a, b) {
        return b.value - a.value;
      });

    var focus = root,
      nodes = pack(root).descendants(),
      view;

    var circle = g
      .selectAll('circle')
      .data(nodes)
      .enter()
      .append('circle')
      .attr('class', function(d) {
        return d.parent
          ? d.children ? 'node' : 'node node--leaf'
          : 'node node--root';
      })
      .style('fill', function(d) {
        return d.children ? color(d.depth) : null;
      })
      .on('click', function(d) {
        if (focus !== d) {
          zoom(d)
        }
        d3.event.stopPropagation();
      });

    /*var text = g
      .selectAll('text')
      .data(nodes)
      .enter()
      .append('text')
      .attr('class', 'label')
      .style('fill-opacity', function(d) {
        return d.parent === root ? 1 : 0;
      })
      .style('display', function(d) {
        return d.parent === root ? 'inline' : 'none';
      })
      .text(function(d) {
        return d.data.name;
      });
    */

    var node = g.selectAll('circle,text');

    const zoom = d => {
      focus = d;

      var transition = d3
        .transition()
        .duration(d3.event.altKey ? 7500 : 750)
        .tween('zoom', function(d) {
          var i = d3.interpolateZoom(view, [
            focus.x,
            focus.y,
            focus.r * 2 + margin
          ]);
          return function(t) {
            zoomTo(i(t));
          };
        });

      transition
        .selectAll('text')
        .filter(function(d) {
          return d.parent === focus || this.style.display === 'inline';
        })
        .style('fill-opacity', function(d) {
          return d.parent === focus ? 1 : 0;
        })
        .on('start', function(d) {
          if (d.parent === focus) this.style.display = 'inline';
        })
        .on('end', function(d) {
          if (d.parent !== focus) this.style.display = 'none';
        });
    };

    const zoomTo = v => {
      var k = diameter / v[2];
      view = v;
      node.attr('transform', function(d) {
        return 'translate(' + (d.x - v[0]) * k + ',' + (d.y - v[1]) * k + ')';
      });
      circle.attr('r', function(d) {
        return d.r * k;
      });
    };

    svg.style('background', color(-1)).on('click', function() {
      zoom(root);
    });

    zoomTo([root.x, root.y, root.r * 2 + margin]);
  };

  render() {
    const { isLoading, hasFetchedData, data } = this.props;
    if (isLoading || !hasFetchedData) {
      return <Spinner />;
    }

    if (!data || !data.children || !data.children.length) return null;
    return (
      <Card>
        <CardHeader>
          <h2>Sorgenti Threat</h2>
        </CardHeader>
        <CardBody className="threatsources-card">
          <svg
            className="threatsources-wrapper"
            preserveAspectRatio= "xMinYMin meet"
            viewBox="0 0 700 700"
            ref={node => (this.node = node)}
          />
        </CardBody>
      </Card>
    );
  }
}
