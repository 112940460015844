import React, { Component } from "react";

const Attachment = ({ name, url }) => (
  <li className="list-group-item ticket-attachments-item">
    <i className="fa fa-paperclip" aria-hidden="true" />
    <a href={url} download={name} target="_blank" rel="noopener noreferrer">
      <div className="small">{name}</div>
    </a>
  </li>
);

export default class Attachments extends Component {
  static defaultProps = {
    attachments: [],
  };

  render() {
    const { attachments } = this.props;
    if (!attachments.length) return null;

    return (
      <div className="ticket-attachments">
        <h3>Attachments</h3>
        <ul className="list-group ioc-list">
          {attachments.map((e, i) => (
            <Attachment key={i} {...e} />
          ))}
        </ul>
      </div>
    );
  }
}
