import React, { Component } from 'react';
import AppShell from '../AppShell';
import DynamicListContainer from '../../containers/DynamicsList';
import { Card, CardBody, Row } from 'reactstrap';
import { Localized } from '@fluent/react';

import './api-doc-link.scss';

export default class DynamicPage extends Component {
  render() {
    const { location, match, pageType, title, user } = this.props;
    //    console.log('pageType', pageType);
    return (
      <AppShell match={match} noFilterBar user={user}>
        <div className="row">
          {pageType === 'documentazione' || pageType === 'documentation' ? (
            <div className="col-12">
              <Card>
                <CardBody>
                  <Row className="text-left">
                    <a id="api-doc-link" className="" href={`/${pageType}/api`}>
                      <div className="col-12">
                        <h2 className="title">
                          <i className="fa fa-book"></i>{' '}
                          <Localized id="api_doc">api_doc</Localized>
                        </h2>
                      </div>
                      <div className="col-12">
                        <hr />
                        <p className="description">
                          <Localized id="api_description">
                            api_description
                          </Localized>
                        </p>
                      </div>
                    </a>
                  </Row>
                </CardBody>
              </Card>
            </div>
          ) : (
            ''
          )}
          <div className="col">
            <DynamicListContainer
              location={location}
              perPage="10"
              pageType={pageType}
              showPagination
              showFeedRSS
              title={title}
              urlPagination={true}
            />
          </div>
        </div>
      </AppShell>
    );
  }
}
