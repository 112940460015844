import React, { PureComponent } from "react";
import { Row, Col, Card } from "reactstrap";
import FilterCard from "./FilterCard";
import { Localized } from "@fluent/react";

export default class AdversariesFilter extends PureComponent {
  render() {
    const { availableFilters, isLoading, currentFilters } = this.props;
    const { area = [], continent = [], country = [] } = availableFilters;
    let areaFilter = null,
      countryFilter = null,
      continentFilter = null;

    if (isLoading) {
      return null;
    }

    if (currentFilters) {
      if (currentFilters.adversary_area) {
        areaFilter = parseInt(currentFilters.adversary_area, 10);
      }
      if (currentFilters.adversary_country) {
        countryFilter = parseInt(currentFilters.adversary_country, 10);
      }
      if (currentFilters.adversary_continent) {
        continentFilter = parseInt(currentFilters.adversary_continent, 10);
      }
    }

    return (
      <Row>
        <Col>
          <Card>
            <FilterCard
              title={<Localized id="continents">continents</Localized>}
              iconClass="fa fa-globe"
              activeFilter={continentFilter}
              filterItems={continent}
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <FilterCard
              title={<Localized id="nations">nations</Localized>}
              iconClass="fa fa-flag"
              activeFilter={countryFilter}
              filterItems={country}
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <FilterCard
              title={<Localized id="context">context</Localized>}
              iconClass="fa fa-building-o"
              activeFilter={areaFilter}
              filterItems={area}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}
