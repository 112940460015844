export const en = `
active = ACTIVE
add_to_favorites = 
    .title = Add to favorites
adversaries = Adversaries
adversaries_24h_update = last 30dd - updated 24h ago
adversary = Adversary
alt_ioc_event_img = 
    .alt = IOC events image
api_doc = API doc
api_description = Documentation of the API set for TS-Intelligence platform integration.
api_token = API token
aria_add_to_favorites = 
    .aria-label = Add to favorites
ask_analysts = Ask to analysts
breach = Breach
cancel = Cancel
categories = Categories
cdn_token = CDN token
change_password = Change Password
change_password_msg_error = Sorry, an error has occurred. Password wasn't change. Please retry.
change_password_msg_success = Password successfully changed.
changelog = Changelog
context = Context
continents = Continents
current_password = Current Password
custom_reports = Custom Reports
cyber_threat_risk = Cyber Threat Risk
dashboard = Dashboard
dateRanges_7d = last 7 days
dateRanges_all = all
dateRanges_h24 = last 24 hours
dateRanges_m3 = last 3 months 
date_publication = Pubblication Date 
date_update = Update Date
default_img_placeholder = 
    .alt = Image Placeholder
df_bro = Bro
df_csv = CSV
df_csv_isd = CSV (only IDS)
df_misp_json = MISP JSON
df_misp_json_no_attachments = MISP JSON (no attachments)
df_misp_xml = MISP XML
df_misp_xml_no_attachments = MISP XML (no attachments)
df_open_ioc = OpenIOC
df_rpz = RPZ Zone File
df_snort = Snort
df_snort_attributes = Snort (only attributes)
df_stix = STIX XML
df_stix_no_attachments = STIX XML (no attachments)
df_suricata = Suricata
df_text_file = Text File
df_text_file_ids = Text (only  IDS)
df_yara_attributes = Yara (only attributes)
doc_copy_link_msg = Link copied to clipboard
doc_copy_link_title = Click to copy the link to the clipboard
doc_toc = Content index
doc_toggle_compact = Compact 
doc_toggle_expand = Expand
documentations = Documentations
documentations_api = API doc
e_mail = E-mail
event = Event
expiration_date = LICENSE EXIPIRATION DATE
exploits = Exploits
extra_modules = Extra Modules
faq = FAQ
favorites = Favorites
favorites_add_to =
     .title =  Add to favorites
favorites_remove_from =
     .title =  Remove from favorites
feed_token = Feed token
filter_by = Filter by
home = Home
in_adversaries = in Adversaries
in_ioc_events = in IOC Events
incident_response_threat_hunting = Incident Response/Threat Hunting
insert_current_password = Insert current password
insight = Insight
investigation_ticket_on_demand = Investigation Ticket On Demand
ioc_all_events = All IOC Events
ioc_counter = IOC Counter
ioc_event_malware = Evento generato dalla detonazione di malware samples catalogati: indicatori validati dalla componente tecnologica.
ioc_event_trusted = Evento generato da Fonti Trusted: informazioni provenienti da partner selezionati di TS-WAY.
ioc_event_ts_analysts = Evento generato da analisti TS-WAY tramite l’analisi di fonti aperte: informazioni provenienti dal mondo OSINT ma sottoposte a un controllo di veridicità e attendibilità da parte degli analisti di TS-WAY, del CIOC – Cyber Intelligence Operation Center.
ioc_event_ts_auto = Evento generato da tecnologie proprietarie sviluppate da TS-WAY: tecnologie sviluppate nell’ambito di attività R&D di TS-WAY le quali, con un profondo livello di automatizzazione, riescono a fornire output verificati in termini di indicatori di compromissione.
ioc_event_ts_manual = Evento generato in modo manuale da analisti TS-WAY: gli indicatori forniti sono il risultato di un’approfondita e altamente verticale analisi svolta dal personale del CIOC – Cyber Intelligence Operation Center – di TS-WAY.
ioc_events = IOC Events
ioc_events_malware = Eventi generati dalla detonazione di malware samples catalogati: indicatori validati dalla componente tecnologica.
ioc_events_trusted = Eventi generati da Fonti Trusted: informazioni provenienti da partner selezionati di TS-WAY.
ioc_events_ts_analysts = Eventi generati da analisti TS-WAY tramite l’analisi di fonti aperte: informazioni provenienti dal mondo OSINT ma sottoposte a un controllo di veridicità e attendibilità da parte degli analisti di TS-WAY, del CIOC – Cyber Intelligence Operation Center.
ioc_events_ts_auto = Eventi generati da tecnologie proprietarie sviluppate da TS-WAY: tecnologie sviluppate nell’ambito di attività R&D di TS-WAY le quali, con un profondo livello di automatizzazione, riescono a fornire output verificati in termini di indicatori di compromissione.
ioc_events_ts_manual = Eventi generati in modo manuale da analisti TS-WAY: gli indicatori forniti sono il risultato di un’approfondita e altamente verticale analisi svolta dal personale del CIOC – Cyber Intelligence Operation Center – di TS-WAY.
language = Language
last_update = Last update
license_type = License type
license_type_advanced =  Il piano <license_strong>Advanced</license_strong> è pensato per tutte quelle realtà che hanno la necessità di avere un approccio avanzato, contestualizzato e preventivo alle minacce malware attraverso la cyber threat intelligence.
license_type_basic = Il piano <license_strong>Basic</license_strong> è pensato per tutte quelle realtà e ai CISO/CSO che si avvicinano al tema della cyber threat intelligence ed hanno necessità di accedere alle informazioni validate rispetto minacce, vulnerabilità emergenti e data breach.
license_type_professional = Il piano <license_strong>Professional</license_strong> è pensato per tutte quelle realtà che hanno la necessità di avere un approccio alla cyber threat intelligence maturo ed approfondito sia di tipo APT che con particolare riferimento ed approfondimento delle minacce di tipo structured crime.
login = login
login_button = Login
login_error_message = Wrong Username and Password
logout = Logout
manage_account = Account
minutes_ago = minutes ago
most_active_adversaries = Most active adversaries
name = Name
nations = Nations
new_password = New Password (at least 8 characters)
no_exploits_found = No exploits found
no_ioc_event_found = No IOC events found
no_pages_found = No pages found
no_report_found = 
    .label = No report found
no_report_found = Nessun rapporto trovato
no_reports_found = No reports found
no_ticket_found = No ticket found
no_tools_found = No tools found
not_active = NOT ACTIVE
notifications = Notifications
notifications_email_custom_reports = E-mail custom reports 
notifications_email_reports = E-mail reports
notifications_email_tickets = E-mail tickets
pages = Pages
password_reset = Reset password
password_doesnt_match = Passwords doesn't match.
password_is_too_short = Passwords must be at least 8 characters.
pdf_download = 
    .title = Download PDF 
profile = Profile
related_reports = Related Reports
repeat_new_password = Repeat new password
replies = Replies
reply = Reply
report = Report
reports = Reports
reports_list_title_reports = Reports
research = Research
reset = Reset
reset_password_change_password = Change password 
reset_password_reset_password = Reset password
reset_password_send_code = Send
resources = Resources
result_for = Result for
results_for = Results for
save = Save
search = Search
search_term = 
    .placeholder = Search terms...
select_language = Select language
send = Send
settings = Settings
special_reports = Special Reports
supply_chain_threat_monitoring = Supply Chain Threat Monitoring
table_last_day = Last Day
table_total = Total
table_typology = Typology
tailored_threat_intelligence_monitoring = Tailored Threat Intelligence Monitoring
threat = Threat
threats = Threats
ticket_category = Category
ticket_content = Content
ticket_content_placeholder = 
    .placeholder = Insert ticket content...
ticket_file_upload = File Upload
ticket_file_upload_input = 
    .data-value = Select file...
ticket_new = New Ticket
ticket_select_category = Select category...
ticket_send = Send
ticket_subject = Subject
ticket_subject_placeholder = 
    .placeholder = Insert ticket subject... 
tickets = Tickets
ts_red_line = TS Red Line
user = User
vulnerability = Vulnerability
`;
