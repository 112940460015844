const initialState = {
  adversaries: [],
  adversary: {},
  availableFilters: {},
  currentFilters: {},
  isLoading: false,
  requestErrorMessage: '',
  pagination: {},
  page: 1,
  hasFetchedData: false,
  //per_page: 10,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_ADVERSARIES_REQUEST':
      return {
        ...state,
        isLoading: true
        // hasFetchedData: false,
      };

    case 'GET_ADVERSARIES_SUCCESS':
      return {
        ...state,
        adversaries: action.data.results,
        availableFilters: action.filters,
        currentFilters: action.currentFilters,
        isLoading: false,
        pagination: {
          ...state.pagination,
          ...action.extra
        },
        page: action.page,
        // hasFetchedData: true,
      };

    case 'GET_ADVERSARIES_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
        adversaries: []
      };

    case 'EDIT_ADVERSARIES_FILTER':
      return {
        ...state,
        isLoading: true,
        currentFilters: action.filter,
        //page: 1
      };

    case 'CLEAR_ADVERSARIES_FILTER':
      return {
        ...state,
        isLoading: true,
        currentFilters: {},
        page: 1
      };

    case 'GET_ADVERSARY_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true
      };

    case 'GET_ADVERSARY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        adversary: {
          ...state.adversary,
          [action.data.title.rendered]: action.data
        }
      };

    case 'GET_ADVERSARY_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false
      };

    case 'EDIT_ADVERSARY_PAGE':
      return {
        ...state,
        isLoading: true,
        page: action.page
      };

    default:
      return state;
  }
}
