import React, { Component } from 'react';
import ReportsListContainer from '../../containers/ReportsList';
import AdversaryRankingListContainer from '../../containers/AdversaryRankingList';
import IOCCounterListContainer from '../../containers/IOCCounterList';
import TicketsListContainer from '../../containers/TicketsList';
import AppShell from '../AppShell';

export default class DashboardCommon extends Component {
  render() {
    const { user } = this.props;

    if (!user) return null;

    return (
      <AppShell {...this.props} noDateFilter={true} noFilterBar={true}>
        <div className="row">
          <div className="col col-12 col-xl-7">
            <ReportsListContainer reportsType="GLOBAL_REPORTS" link="/reports" perPage={4} />
            {user.organization.license !== 'basic' && (
              <ReportsListContainer
                reportsType="SPECIAL_REPORTS"
                title="Special Report"
                link="/special_reports"
                perPage={4}
              />
            )}
          </div>

          <div className="col col-12 col-xl-5">
            <AdversaryRankingListContainer link="/adversaries" />
            <IOCCounterListContainer link="/IOCEvents" />
            <TicketsListContainer limit={5} link="/tickets" />
          </div>
        </div>
      </AppShell>
    );
  }
}
