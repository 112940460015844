import React, { Component } from 'react';

export default class SingleImpact extends Component {
  static defaultProps = {
    icon: '',
    count: 0
  };

  renderTLPColor = () => {
    const { severity } = this.props;
    switch (severity) {
      case 'Low':
        return 'bg-success';
      case 'High':
        return 'bg-danger';
      case 'Medium':
        return 'bg-warning';
      default:
        return 'bg-info';
    }
  };

  render() {
    const { icon, count, impact } = this.props;
    return (
      <div className="col-md-6 impact mb-4">
        <div className={`card-body ${this.renderTLPColor()}`}>
          <div className="h1 text-right mb-0">
            <i className={icon} aria-hidden="true" />
          </div>
          <div className="h4 mb-0 impact-number">{count}</div>
          <small className="text-uppercase font-weight-bold">
            {impact}
          </small>
        </div>
      </div>
    );
  }
}