import React, { Component } from "react";
import { Card, CardHeader, CardBody, Table } from "reactstrap";
import Paginations from "../Pagination";
import Spinner from "../Spinner";
import EmptyState from "../EmptyState";
import { Localized } from "@fluent/react";

export const Exploit = (props) => (
  <tr>
    <td className="text-center">
      <a href={`http://cve.circl.lu/cve/${props.CVE}`} target="blank">
        {props.CVE}{" "}
        <i className="fa fa-external-link fa-1" aria-hidden="true"></i>
      </a>
    </td>
  </tr>
);

export default class Exploits extends Component {
  static defaultProps = {
    exploits: [],
  };

  render() {
    const { exploits, size, hasFetchedData } = this.props;
    const pagination = { pages: 0 };

    if (!hasFetchedData && !exploits.length) {
      return <Spinner />;
    }

    if (exploits && exploits.length > 0) {
      pagination.pages = Math.ceil(exploits[0].contatore / size);
      return (
        <Card className="mb-4">
          <CardHeader>
            <h2>
              <i
                className="fa ts-icon-exploits ts-blue2"
                aria-hidden="true"
              ></i>
              <Localized id="exploits">exploits</Localized>
            </h2>
          </CardHeader>
          <CardBody>
            <Table striped>
              <tbody>
                {exploits.map((e, i) => (
                  <Exploit key={i} {...e} />
                ))}
              </tbody>
            </Table>
            <Paginations pagination={pagination} {...this.props} />
          </CardBody>
        </Card>
      );
    }

    return (
      <EmptyState
        img="/img/reports.svg"
        label={<Localized id="no_exploits_found">no_exploits_found</Localized>}
      />
    );
  }
}
