import React, { Component } from 'react';
import {connect} from 'react-redux';
import TicketsList from '../../components/TicketsList';
import {getTicketsList} from '../../actions/ticket';

class TicketListContainer extends Component {
  componentDidMount() {
    if(!this.props.hasFetchedData){
      this.props.getTicketsList();
    }
  }

  render(){
    return (<TicketsList {...this.props}/>);
  }
}

function mapDispatchToProps(dispatch){
  return {
    getTicketsList: (...args) => dispatch(getTicketsList(...args)),
  };
}

function mapStateToProps(state){
  return {
    tickets: state.ticket.tickets,
    isLoading: state.ticket.isLoading,
    hasFetchedData: state.ticket.hasFetchedData,
    requestErrorMessage: state.ticket.requestErrorMessage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketListContainer);
