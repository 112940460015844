import React, { Component } from 'react';
import { Localized } from '@fluent/react';
import EmptyState from '../EmptyState';
import IOC from './IOC';
import Pagination from '../Pagination';
export default class IOCList extends Component {
  downloadEvent = (id, format, attachment = false) => {
    this.props.downloadEvent({ id, format, attachment });
  };

  render() {
    const {
      activeFilters,
      goBack,
      goNext,
      goToPage,
      isSearch,
      searchQuery,
      orgImages,
      page,
      showPagination,
      size,
      url,
      urlPagination,
      location,
      actorName,
    } = this.props;

    let { events } = this.props;

    if (events && events.length > 0) {
      const pagination = {
        pages: Math.ceil(events[0].contatore / size),
      };

      if (!Array.isArray(events)) {
        events = [events];
      }

      return (
        <div className="ioc-events card-body">
          <ul className="list-group ioc-list">
            {events.map(e => (
              <IOC
                key={e.id}
                {...e}
                downloadEvent={this.downloadEvent}
                orgImages={orgImages}
                location={location}
                actorName={actorName}
              />
            ))}
          </ul>
          {showPagination && (
            <Pagination
              activeFilters={activeFilters}
              page={page}
              pagination={pagination}
              goBack={goBack}
              goNext={goNext}
              goToPage={goToPage}
              isSearch={isSearch}
              searchQuery={searchQuery}
              searchType={isSearch ? 'events' : ''}
              url={urlPagination ? `${url}?` : ''}
              location={location}
            />
          )}
        </div>
      );
    } else {
      return (
        <EmptyState
          img="/img/reports.svg"
          label={
            <Localized id="no_ioc_event_found">no_ioc_event_found</Localized>
          }
        />
      );
    }
  }
}
