import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../reducer';
import API from '../API';
const api = (window._api = new API());

api.CDN = new API(
  'cdn_token',
  `${process.env.REACT_APP_CDNAPI_URL_PROD || 'https://cdn-intelligence.ts-way.com'}/cdn/`,
);

const middleWare = [thunk.withExtraArgument(api)];

const createStoreWithMiddleware = applyMiddleware(...middleWare)(createStore);

// Ho settanto la configurazione per usare il devtool (Chrome) per redux :)
const store = createStoreWithMiddleware(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
