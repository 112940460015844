import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTool } from '../../actions/tool';
import ToolPage from '../../components/ToolPage';

class ToolPageContainer extends Component {
  static defaultProps = {
    tool: {}
  }

  componentDidMount() {
    this.getToolIfNotDownloaded();
  }

  getToolIfNotDownloaded = id => {
    const { tool } = this.props;
    const ID = id || this.props.match.params.id;
    if (!tool[ID]) {
      this.props.getTool({ params: { ID } });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.getToolIfNotDownloaded(nextProps.match.params.id);
    }
  }

  render() {
    const { tool } = this.props;
    const ID = this.props.match.params.id;

    return (
      <ToolPage {...this.props} tool={tool[ID]}/>
    )
  }
}

function mapDispatchToProps(dispatch){
  return {
    getTool: (...args) => dispatch(getTool(...args)),
  };
}

export default connect(
  (state) =>
  ({
    tool: state.tool.tool,
  }),
  mapDispatchToProps
)(ToolPageContainer);
