import React, { Component } from 'react';
import { Localized } from '@fluent/react';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner';
import Paginations from '../Pagination';
import PropTypes from 'prop-types';

import EmptyState from '../EmptyState';
import ReportsRow from './ReportsRow';
import './ReportList.scss';

export default class ReportsList extends Component {
  static defaultProps = {
    reports: [],
    title: (
      <Localized id="reports_list_title_reports">
        reports_list_title_reports
      </Localized>
    ),
    link: '#',
    showPagination: false,
  };
  // title: "Reports"

  static contextTypes = {
    router: PropTypes.object,
  };

  printableTag = tagName => {
    return tagName
      .replace(/(^\w|[\W]\w)/g, c => c.toUpperCase())
      .replace(/-/g, ' ');
  };

  addToFavorite = id => this.props.addToFavorite(id);

  deleteFromFavorite = id => this.props.deleteFromFavorite(id);

  searchURL = tag => {
    const { reportsType, mispActor } = this.props;
    const { location } = window;
    const urlParams = new URLSearchParams(location.search.substring(1));

    let tags = urlParams.get('tag_filter');
    if (!tags) {
      tags = [];
    } else {
      try {
        tags = JSON.parse(tags);
      } catch (e) {
        tags = [];
      }
    }

    if (!tags.includes(tag)) {
      tags.push(tag);
    }
    //const tags = [tag];

    urlParams.set('tag_filter', JSON.stringify(tags));
    urlParams.set('type', 'report');
    urlParams.set('page', '1');

    if (mispActor) {
      urlParams.set('misp_actor', mispActor);
    }

    switch (reportsType) {
      case 'SPECIAL_REPORTS':
        urlParams.set('is_special', true);
        break;

      case 'focused':
        urlParams.set('is_custom', true);
        break;

      default:
        console.warn('type not recognized');
    }

    return `/search?${urlParams.toString()}`;
  };

  removeFilterTag = idx => {
    const { location } = window;
    let urlParams = new URLSearchParams(location.search.substring(1));
    let tags = urlParams.has('tag_filter')
      ? JSON.parse(urlParams.get('tag_filter'))
      : false;
    if (!tags) {
      return;
    }

    tags.splice(idx, 1);

    let url = '';
    let basePath = '/reports';

    if (tags.length > 0) {
      urlParams.set('page', '1');
      urlParams.set('tag_filter', JSON.stringify(tags));
      basePath = '/search';
    } else {
      if (urlParams.has('query')) {
        basePath = '/search';
      }

      urlParams.delete('tag_filter');
    }

    url = `${basePath}?${urlParams.toString()}`;

    this.context.router.history.push(url);
  };

  render() {
    const {
      goBack,
      goNext,
      goToPage,
      hasFetchedData,
      isSearch,
      link,
      noHeader,
      page,
      pagination,
      reports,
      reportsType,
      searchQuery,
      showFeedRSS,
      showPagination,
      title,
      url,
      urlPagination,
    } = this.props;

    const { location } = window;

    if (!hasFetchedData && !reports.length) {
      return <Spinner />;
    }

    if (hasFetchedData && !reports.length) {
      return (
        <EmptyState
          img="/img/reports.svg"
          label={<Localized id="no_reports_found">no_reports_found</Localized>}
        />
      );
    }

    let method = '';

    switch (reportsType) {
      case 'GLOBAL_REPORTS':
        method = 'global';
        break;

      case 'SPECIAL_REPORTS':
        method = 'special';
        break;

      case 'focused':
        method = 'custom';
        break;

      default:
        method = 'global';
    }

    const api_url =
        process.env.REACT_APP_API_URL_PROD ||
        'https://dashboard-telsy.echo2.dev.tigersecurity.private/api/',
      feedUrl = `${api_url}feed/${method}?token=${localStorage.getItem(
        'feed_token'
      )}`;

    let urlParams = new URLSearchParams(location.search.substring(1));
    let tags = urlParams.has('tag_filter')
      ? JSON.parse(urlParams.get('tag_filter'))
      : false;
    return (
      <div className="card">
        {!!!noHeader && (
          <div className="card-header">
            {!isSearch ? (
              <h2>
                <i className="fa fa-bar-chart ts-blue2" aria-hidden="true" />
                &nbsp;
                <Link to={link}>
                  <span className="ts-blue2">
                    <Localized id="insight">insight</Localized> /{' '}
                  </span>
                  {title}
                </Link>
                {showFeedRSS ? (
                  <a
                    className="feed-icon"
                    href={feedUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="RSS"
                  >
                    <i className={`fa fa-rss`} aria-label="FeedRSS" />
                  </a>
                ) : (
                  ''
                )}
                <div style={{ float: 'right' }}>
                  <span className="badge report-tag">
                    <Link to={this.searchURL('threat')}>
                      <Localized id="threat">threat</Localized>
                    </Link>
                  </span>
                  <span className="badge report-tag">
                    <Link to={this.searchURL('vulnerability')}>
                      <Localized id="vulnerability">vulnerability</Localized>
                    </Link>
                  </span>
                  <span className="badge report-tag">
                    {' '}
                    <Link to={this.searchURL('breach')}>
                      <Localized id="breach">breach</Localized>
                    </Link>
                  </span>
                </div>
              </h2>
            ) : (
              <h2>
                <i className="fa fa-search ts-blue2" aria-hidden="true" />
                &nbsp;
                <span className="ts-blue2">
                  <Localized id="search">search</Localized> /{' '}
                </span>
                {searchQuery ? (
                  <span>
                    <Localized id="results_for">results_for</Localized>{' '}
                    <span className="font-italic">"{searchQuery}"</span> in{' '}
                    {title}
                  </span>
                ) : (
                  <span>{title}</span>
                )}
                <div style={{ float: 'right' }}>
                  <span className="badge report-tag">
                    <Localized id="threat">threat</Localized>
                  </span>
                  <span className="badge report-tag">
                    <Link to={this.searchURL('vulnerability')}>
                      <Localized id="vulnerability">vulnerability</Localized>
                    </Link>
                  </span>
                  <span className="badge report-tag">
                    {' '}
                    <Link to={this.searchURL('breach')}>
                      <Localized id="breach">breach</Localized>
                    </Link>
                  </span>
                </div>
                {tags && (
                  <div>
                    {tags.map((t, idx) => (
                      <span key={idx} className="badge report-tag" title={t}>
                        <i
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.removeFilterTag(idx)}
                          className="fa fa-times-circle"
                          aria-hidden="true"
                        ></i>{' '}
                        {this.printableTag(t)}
                      </span>
                    ))}
                  </div>
                )}
              </h2>
            )}
          </div>
        )}

        <div className="card-body">
          <ul className="list-unstyled">
            {reports.map((d, i) => (
              <ReportsRow
                addToFavorite={this.addToFavorite}
                deleteFromFavorite={this.deleteFromFavorite}
                key={i}
                odd={i % 2 === 1}
                report={d}
              />
            ))}
          </ul>

          {showPagination && (
            <Paginations
              goBack={goBack}
              goNext={goNext}
              goToPage={goToPage}
              isSearch={isSearch}
              page={page}
              pagination={pagination}
              searchQuery={isSearch ? searchQuery : ''}
              searchType={isSearch ? 'report' : ''}
              url={urlPagination ? `${url}?` : ''}
            />
          )}
        </div>
      </div>
    );
  }
}
