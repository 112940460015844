import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicsList from '../../components/DynamicsList';
import { getDynamicList } from '../../actions/dynamics';

class DynamicsListContainer extends Component {
  static defaultProps = {
    perPage: '10',
  };

  state = {
    page: '1',
  };

  goNext = () => this.setState({ page: this.state.page + 1 });

  goBack = () => this.setState({ page: this.state.page - 1 });

  goToPage = page => this.setState({ page });

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const {
      getDynamicList,
      // globalSpecial,
      //      isSearch = false,
      perPage,
      pageType,
      //      searchQuery = '',
      urlPagination,
    } = this.props;

    const { location } = window;
    let urlParams = location
      ? new URLSearchParams(location.search.substring(1))
      : false;
    let page = this.state.page;

    if (urlPagination) {
      const regex = /^[0-9]*$/g;

      if (
        urlParams &&
        urlParams.get('page') &&
        urlParams.get('page').match(regex)
      ) {
        page = urlParams.get('page');
      } else {
        page = '1';
      }

      // if (isSearch && searchQuery) {
      //   const params = {
      //     global: true,
      //     globalspecial: true,
      //     search: searchQuery,
      //     page,
      //     per_page: perPage,
      //     tag_filter: urlParams.has('tag_filter') ? urlParams.get('tag_filter') : '[]',
      //   };

      //   getReportsList({ type: reportsType, params });
      //   return;
      // }
    }

    // HACK: Find a better way to manage per_page
    const params = {
      page,
      per_page: perPage,
      // tag_filter: urlParams.has('tag_filter') ? urlParams.get('tag_filter') : '[]',
    };

    getDynamicList(pageType, {
      type: pageType,
      //hashFilter: !!dateFilter,
      params,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { pageType, perPage, location } = this.props;

    if (
      (location && location.search !== prevProps.location.search) ||
      pageType !== prevProps.pageType ||
      this.state.page !== prevState.page ||
      perPage !== prevProps.perPage
    ) {
      this.getData();
    }
  }

  render() {
    const {
      hasFetchedData,
      // isSearch = false,
      link,
      location,
      pagination,
      page,
      perPage,
      contents,
      pageType,
      // searchQuery = '',
      showPagination,
      title,
      urlPagination,
    } = this.props;

    const pathname = location ? location.pathname : '';
    const contents_ = contents[pageType]
      ? perPage
        ? contents[pageType].slice(0, perPage)
        : contents[pageType]
      : [];

    return (
      <DynamicsList
        goBack={this.goBack}
        goNext={this.goNext}
        goToPage={this.goToPage}
        hasFetchedData={hasFetchedData}
        // isSearch={isSearch}
        link={link}
        page={page}
        pagination={pagination}
        contents={contents_}
        pageType={pageType}
        // searchQuery={searchQuery}
        showPagination={showPagination}
        title={title}
        url={pathname}
        urlPagination={urlPagination}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contents: state.dynamics.contents,
    page: state.dynamics.page,
    pagination: state.dynamics.pagination[ownProps.pageType],
    isLoading: state.dynamics.isLoading,
    hasFetchedData: state.dynamics.hasFetchedData,
    // requestErrorMessage: state.reports.requestErrorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDynamicList: (...args) => dispatch(getDynamicList(...args)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicsListContainer);
