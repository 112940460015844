import React, { Component } from "react";
import { Link } from "react-router-dom";
import GalaxyTags from "./GalaxyTags";
import IOCDownloadDropdown from "./IOCDownloadDropdown";
import { getContrast50 } from "../../tools/colors";
import { prettyDate } from "../../tools/date";
import { Localized } from "@fluent/react";

export default class IOC extends Component {
  searchURL = (tag) => {
    const { location, actorName } = this.props;

    let urlParams = new URLSearchParams(location.search.substring(1));

    let tags = urlParams.get("tag_filter");
    if (!tags) {
      tags = [];
    } else {
      try {
        tags = JSON.parse(tags);
      } catch (e) {
        tags = [];
      }
    }

    if (actorName) {
      tags.push(`misp-galaxy:threat-actor="${actorName}"`);
    }

    tags.push(tag);

    urlParams.set(
      "tag_filter",
      JSON.stringify(tags.filter((v, i, a) => a.indexOf(v) === i))
    );
    urlParams.set("type", "events");
    urlParams.set("page", "1");

    return `/search?${urlParams.toString()}`;
  };

  render() {
    const {
      attribute,
      downloadEvent,
      id,
      info,
      name,
      org_id,
      orgImages,
      timestamp,
      tag: tags,
      title,
      location,
    } = this.props;

    return (
      <li className="list-group-item list-events">
        <IOCDownloadDropdown
          attribute={attribute}
          downloadEvent={downloadEvent}
          id={id}
        />

        <Link className="event-link" to={`/IOC/${id}`}>
          {orgImages[org_id] ? (
            <img src={`/img/org/${org_id}.svg`} alt="" />
          ) : (
            <img src="/img/org/-1.svg" alt="" />
          )}
          <span className="event-title">{title || info || name}</span>
        </Link>

        <div className="report-tags d-print-none">
          {tags
            .sort((a, b) => a.id - b.id)
            .map(
              (tag) =>
                tag.galaxy_id === "NULL" && (
                  <span
                    key={tag.id}
                    className="badge report-tag"
                    title={tag.name}
                    style={{
                      backgroundColor: tag.colour,
                      color: getContrast50(tag.colour),
                    }}
                  >
                    <Link to={this.searchURL(tag.name)}>{tag.name}</Link>
                  </span>
                )
            )}
        </div>

        <GalaxyTags tags={tags} location={location} />

        <div className="text-muted d-print-none">
          <span className="small">
            <Localized id="last_update">last_update</Localized>{" "}
            {prettyDate(new Date(timestamp * 1000))}
          </span>
        </div>
      </li>
    );
  }
}
