import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Localized } from "@fluent/react";

export default class IOCDownloadDropdown extends Component {
  state = {
    dropdownOpen: false,
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    const { attribute, downloadEvent, id } = this.props;

    return (
      <Dropdown
        className="float-right d-print-none"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle caret>
          <i className="fa fa-download ioc" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => downloadEvent(id, "csv")}>
            <Localized id="df_csv">df_csv</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "csv-ids")}>
            <Localized id="df_csv_isd">df_csv_isd</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "suricata")}>
            <Localized id="df_suricata">df_suricata</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "snort")}>
            <Localized id="df_snort">df_snort</Localized>
          </DropdownItem>
          {/yara/.test(attribute) && (
            <DropdownItem onClick={() => downloadEvent(id, "yara-attributes")}>
              <Localized id="df_yara_attributes">df_yara_attributes</Localized>
            </DropdownItem>
          )}
          <DropdownItem onClick={() => downloadEvent(id, "bro")}>
            <Localized id="df_bro">df_bro</Localized>
          </DropdownItem>
          {/snort/.test(attribute) && (
            <DropdownItem onClick={() => downloadEvent(id, "snort-attributes")}>
              <Localized id="df_snort_attributes">
                df_snort_attributes
              </Localized>
            </DropdownItem>
          )}
          <DropdownItem onClick={() => downloadEvent(id, "misp-xml", true)}>
            <Localized id="df_misp_xml">df_misp_xml</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "misp-xml")}>
            <Localized id="df_misp_xml_no_attachments">
              df_misp_xml_no_attachments
            </Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "misp-json", true)}>
            <Localized id="df_misp_json">df_misp_json</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "misp-json")}>
            <Localized id="df_misp_json_no_attachments">
              df_misp_json_no_attachments
            </Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "openioc")}>
            <Localized id="df_open_ioc">df_open_ioc</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "stix", true)}>
            <Localized id="df_stix">df_stix</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "stix")}>
            <Localized id="df_stix_no_attachments">
              df_stix_no_attachments
            </Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "rpz")}>
            <Localized id="df_rpz">df_rpz</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "text")}>
            <Localized id="df_text_file">df_text_file</Localized>
          </DropdownItem>
          <DropdownItem onClick={() => downloadEvent(id, "text-ids")}>
            <Localized id="df_text_file_ids">df_text_file_ids</Localized>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
