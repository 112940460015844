/**
 * Preleva gli IOC Events from 'threats/iocs/'.
 * @param {object} data - Oggetto da transformare in query string nella richiesta.
 * @param {any} router
 */
export function getIOCCounterList(orgId, router) {
  return async (dispatch, getState, api, apiCdn) => {

    async function getCountCDN(path) {
      let middlePath = 'global';
      if (orgId && orgId !== -1) {
        middlePath = `org/${orgId}`;
      }
      const count = await api.CDN.get(`ioc/${middlePath}/${path}/count`, {}, false, true);
      return parseInt(count, 10);
    }

    dispatch({
      type: 'GET_IOC_COUNTER_LIST_REQUEST'
    });

    //const d = await api.get('threats/iocs', data);

    try {
      const data = {
        "yara": {
          "count": await getCountCDN('yara'),
          "delta": await getCountCDN('yara/delta'),
        },
        "url": {
          "count": await getCountCDN('url'),
          "delta": await getCountCDN('url/delta'),
        },
        "domain": {
          "count": await getCountCDN('domain'),
          "delta": await getCountCDN('domain/delta'),
        },
        "ip": {
          "count": await getCountCDN('ip'),
          "delta": await getCountCDN('ip/delta')
        },
        "hashes": {
          "count": await getCountCDN('hash'),
          "delta": await getCountCDN('hash/delta'),
        },
      };

      const lastUpdate = await api.CDN.get(`ioc/lastUpdate`, {}, true);

      dispatch({
        type: 'GET_IOC_COUNTER_LIST_SUCCESS',
        data,
        lastUpdate,
      });
    } catch (e) {
      dispatch({
        type: 'GET_IOC_COUNTER_LIST_FAILED',
        message: e
      });
    }
  };
}
/**
 * Preleva il singolo IOC, partendo da un ID.
 * @param {number} id L'ID dell'IOC da prelevare.
 */
export function getIOC(id, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_IOC_REQUEST'
    });

    const d = await api.get(`threats/event/${id}`);

    if (d.error) {
      dispatch({
        type: 'GET_IOC_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_IOC_SUCCESS',
        data: d.data,
        id
      });
    }
  };
}