import React, { Component } from 'react';
import { connect } from 'react-redux';
import TicketPage from '../../components/TicketPage';
import {getTicketsList} from '../../actions/ticket';

class TicketPageContainer extends Component {
  componentDidMount(){
    if(!this.props.hasFetchedData){
      this.props.getTicketsList();
    }
  }

  render() {
    return (
      <TicketPage {...this.props} />
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTicketsList: (...args) => {
      dispatch(getTicketsList(...args))
    }
  }
}
export default connect(
  (state) => ({
    tickets: state.ticket.tickets,
    isLoading: state.ticket.isLoading,
    hasFetchedData: state.ticket.hasFetchedData,
  }),
  mapDispatchToProps
)(TicketPageContainer);
