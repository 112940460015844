import React, { Component } from "react";
import { Localized } from "@fluent/react";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import Adversary from "./Adversary";
import "./RankingList.scss";

export default class RankingList extends Component {
  static defaultProps = {
    adversaries: [],
  };

  render() {
    const { adversaries, link, isLoading } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div className="card">
        <div className="card-header">
          <Link to={link}>
            <h2>
              <i className="fa fa-bolt ts-blue2" />{" "}
              <span className="ts-blue2">
                <Localized id="threats">threats</Localized> /{" "}
              </span>
              <Localized id="most_active_adversaries">
                most_active_adversaries
              </Localized>
            </h2>
          </Link>
          <span className="small right">
            <Localized id="adversaries_24h_update">
              adversaries_24h_update
            </Localized>
          </span>
        </div>
        <div className="card-body">
          <table className="table table-striped text-center">
            <tbody>
              {adversaries.map((d, i) => (
                <Adversary key={i} {...d} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
