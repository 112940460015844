import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResetPassword from '../../components/ResetPassword';
import { emailResetPassword, resetPassword } from '../../actions/session';

class ResetPasswordContainer extends Component {
  render() {
    const { emailResetPassword, resetPassword, loginErrorMessage, emailSent } = this.props;

    return (
      <ResetPassword
        emailResetPassword={emailResetPassword}
        resetPassword={resetPassword}
        loginErrorMessage={loginErrorMessage}
        emailSent={emailSent}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    emailResetPassword: (...args) => dispatch(emailResetPassword(...args)),
    resetPassword: (...args) => dispatch(resetPassword(...args)),
  };
}

function mapStateToProps(state) {
  return {
    loginErrorMessage: state.session.loginErrorMessage,
    emailSent: state.session.emailSent,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
