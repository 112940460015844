import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { prettyDate } from '../../tools/date';

export default class ReportsRow extends Component {
  static defaultProps = {};

  state = {};

  render() {
    const { odd, report, pageType } = this.props;
    const { date, id, page_category } = report;

    return (
      <li className={`media ${odd ? 'my-4' : ''}`}>
        <div className="media-body">
          <div className="mb-1 report-list-title">
            <Link to={`/page/${pageType}/${id}`}>
              <h3 dangerouslySetInnerHTML={{ __html: report.title.rendered }} />
            </Link>
          </div>
          <div className="report-tags">
            {(page_category || []).map(tag => (
              <span key={tag.id} className="badge report-tag">
                {tag.name}
              </span>
            ))}
          </div>
          <div className="report-excerpt" dangerouslySetInnerHTML={{ __html: report.excerpt.rendered }} />
          <span className="date-label small">{prettyDate(new Date(date))}</span>
        </div>
      </li>
    );
  }
}
