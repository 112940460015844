/**
 * Effettua il download del singolo tool, a partire dal proprio ID.
 * Utilizzato nella visualizzazione del singolo TOOL.
 * @param {object} data
 * @param {object} data.params
 * @param {object} data.params.ID - TOOLS ID
 */
export function getTool(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_TOOL_REQUEST'
    });
    const d = await api.get(`threats/tool/${data.params.ID}`);

    if (d.error) {
      dispatch({
        type: 'GET_TOOL_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_TOOL_SUCCESS',
        data: d.data
      });
    }
  };
}
