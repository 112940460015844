import React, { Component } from "react";

export default class IOCDownloadButtons extends Component {
  render() {
    const { actorName, org_id } = this.props;
    const iocToken = localStorage.getItem("cdn_token");
    const orgQuery = org_id === -1 ? "" : `&org_id=${org_id}`;

    return (
      <div className="ioc-download">
        <a
          className="ioc"
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            process.env.REACT_APP_CDNAPI_URL_PROD ||
            "https://cdn-intelligence.ts-way.com"
          }/cdn/ioc/actor/${actorName}/ip?token=${iocToken}${orgQuery}`}
        >
          <i className={`fa ts-icon ts-icon-ip`} />
        </a>
        <a
          className="ioc"
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            process.env.REACT_APP_CDNAPI_URL_PROD ||
            "https://cdn-intelligence.ts-way.com"
          }/cdn/ioc/actor/${actorName}/hash?token=${iocToken}${orgQuery}`}
        >
          <i className={`fa ts-icon ts-icon-hashes`} />
        </a>
        <a
          className="ioc"
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            process.env.REACT_APP_CDNAPI_URL_PROD ||
            "https://cdn-intelligence.ts-way.com"
          }/cdn/ioc/actor/${actorName}/domain?token=${iocToken}${orgQuery}`}
        >
          <i className={`fa ts-icon ts-icon-domain`} />
        </a>
        <a
          className="ioc"
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            process.env.REACT_APP_CDNAPI_URL_PROD ||
            "https://cdn-intelligence.ts-way.com"
          }/cdn/ioc/actor/${actorName}/url?token=${iocToken}${orgQuery}`}
        >
          <i className={`fa ts-icon ts-icon-url`} />
        </a>
        <a
          className="ioc"
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            process.env.REACT_APP_CDNAPI_URL_PROD ||
            "https://cdn-intelligence.ts-way.com"
          }/cdn/ioc/actor/${actorName}/yara?token=${iocToken}${orgQuery}`}
        >
          <i className={`fa ts-icon ts-icon-yara`} />
        </a>
      </div>
    );
  }
}
