const initialState = {
  reports: {},
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false,
  error: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_DYNAMIC_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
        error: false,
      };

    case 'GET_DYNAMIC_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        contents: {
          ...state.reports,
          [action.data.id]: action.data,
        },
      };

    case 'GET_DYNAMIC_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
        error: true,
      };

    default:
      return state;
  }
}
