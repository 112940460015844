/**
 * Effettua la richiesta per prelevare l'utente, a partire da un ID.
 * Il default e' 'me' in modo da prelevare l'utente corrente.
 * @param {string} [id="me"] ID dell'utente da prelevare.
 */
export function getUser(id = 'me', router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_USER_REQUEST'
    });

    if (id === 'me' && !localStorage.getItem('feed_token')) {
      const feedToken = await api.get('auth/feedToken');
      localStorage.setItem('feed_token', feedToken.data.token);
    }

    if (id === 'me' && !localStorage.getItem('cdn_token')) {
      const cdnToken = await api.get('auth/cdnToken');
      api.CDN.setToken(cdnToken.data.token);
      localStorage.setItem('cdn_token', cdnToken.data.token);
    }

    const d = await api.get(`users/${id}`);

    if (d.error) {
      dispatch({
        type: 'GET_USER_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_USER_SUCCESS',
        data: d.data,
        id
      });
    }
  };
}
/**
 * Effettua una richiesta POST per modificare le informazioni relative ad un utente.
 *
 * @-param {number} id ID dell'utente da modificare.
 * @-param {object} data - Query string
 */
export function editUser(id, data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'EDIT_USER_REQUEST'
    });

    const d = await api.post(`users/${id}`, data);

    if (d.error) {
      dispatch({
        type: 'EDIT_USER_FAILED',
        error: d.error
      });
    } 
    else {
      dispatch({
        type: 'EDIT_USER_SUCCESS',
        error: d.error,
        id
      });
    }
  };
}