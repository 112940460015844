import React from 'react';
import {connect} from 'react-redux';
import {logout} from '../../actions/session';
import SideBar from '../../components/SideBar';

function SideBarContainer(props) {
  const { logout, user } = props;

  return (
    <SideBar logout={logout} user={user} />
  );
}

function mapDispatchToProps(dispatch) {
  return {
    logout: (...args) => dispatch(logout(...args)),
  };
}

export default connect(null, mapDispatchToProps)(SideBarContainer);
