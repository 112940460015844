import React, { Component } from "react";
import PropTypes from "prop-types";

export default class GLR extends Component {
  static defaultProps = {
    glr: {
      severity: "",
    },
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  goToPost = () => {
    const {
      glr: { post },
    } = this.props;
    //console.log(post);
    if (!post) return;
    this.context.router.history.push(`/report/${post}`);
  };

  renderGLRIcon = () => {
    const {
      glr: { severity },
    } = this.props;

    switch (severity) {
      case "Medium":
        return "glr-medium";
      case "High":
        return "glr-high";
      case "Low":
        return "glr-low";
      default:
        return "";
    }
  };

  render() {
    const {
      glr: { post },
    } = this.props;
    if (!post) return null;

    return (
      <button className="glr-badge" onClick={this.goToPost}>
        <i className={"fa fa-bullseye fa-2x " + this.renderGLRIcon()} />
        <span
          className={`glr-badge-pill ${this.renderGLRIcon()} badge badge-pill`}
          pill="pill"
          color="danger"
        >
          GLR
        </span>
      </button>
    );
  }
}
