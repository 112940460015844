import React, { Component } from 'react';
import { Localized } from '@fluent/react';

import { NavLink } from 'react-router-dom';
import nav from './_nav';
import './Sidebar.scss';

export default class SideBar extends Component {
  logout = () => {
    this.mobileCloseSidebar();
    this.props.logout();
  };

  minimize() {
    document.body.classList.toggle('brand-minimized');
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileCloseSidebar() {
    const classList = document.body.classList;

    if (classList.contains('sidebar-mobile-show')) {
      setTimeout(function () {
        classList.remove('sidebar-mobile-show');
      }, 200);
    }
  }

  render() {
    const { user } = this.props;

    const title = (item, key) => {
      return (
        <li key={`sidebar_${key}`} className="nav-title">
          <Localized id={item.name}>{item.name}</Localized>
        </li>
      );
    };

    const divider = key => {
      return <li key={`sidebar_${key}`} className="divider" />;
    };

    const navItem = (item, key) => {
      const url = item.url || '';

      const isActive = (match, location) => {
        return url.split('?')[0] === location.pathname;
      };

      if (
        !user ||
        (Array.isArray(item.type) &&
          !item.type.includes(user.organization.license))
      ) {
        return null;
      }

      return (
        <li className="nav-item" key={`sidebar_${key}`}>
          <NavLink
            className="nav-link"
            to={url}
            isActive={isActive}
            onClick={
              item.name === 'logout' ? this.logout : this.mobileCloseSidebar
            }
          >
            {item.icon && <i className={item.icon} />}
            <Localized id={item.name}>{item.name}</Localized>
          </NavLink>
        </li>
      );
    };

    const navType = (item, idx) => {
      return item.title
        ? title(item, idx)
        : item.divider
        ? divider(idx)
        : navItem(item, idx);
    };

    const navList = items => {
      return items.map((item, index) => navType(item, index));
    };

    return (
      <div className="sidebar d-print-none">
        <nav className="sidebar-nav">
          <ul className="nav">{navList(nav.items)}</ul>
        </nav>
        <img
          src="/img/logo-telsy-ts-sidebar.svg"
          alt="logo"
          className="logo-sidebar"
        />
        <button
          className="sidebar-minimizer"
          type="button"
          onClick={this.minimize}
        />
      </div>
    );
  }
}
