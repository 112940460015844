const tableNameToLabel = {
  geoip_aggregation_table_data: 'Mappa',
  whois_aggregation_table_data: 'Whois',
  domain_artefacts_aggregation_table_data: 'Artefatti Correlati',
  certificate_aggregation_table_data: 'Certificati',
  domain_ips_aggregation_table_data: 'Passive DNS',
  domain_telemetry_aggregation_table_data: 'Telemetria',
  email_attributions_aggregation_table_data: 'Attribuzione e-mail',
  email_leaks_aggregation_table_data: 'Leaks',
  filename_artefacts_aggregation_table_data: 'Artefatti',
  hash_artefacts_aggregation_table_data: 'Artefatti',
  hash_connections_aggregation_table_data: 'Connessioni Network',
  hash_similar_artefacts_aggregation_table_data: 'Artefatti similari',
  imphash_artefacts_aggregation_table_data: 'Artefatti',
  ipartefacts_aggregation_table_data: 'Artefatti',
  ipcertificates_aggregation_table_data: 'Certificati',
  ipdomains_aggregation_table_data: 'Domini',
  ipservices_aggregation_table_data: 'Servizi Esposti',
  iptelemetry_aggregation_table_data: 'Telemetria',
  mutex_artefacts_aggregation_table_data: 'Artefatti',
  pdbartefacts_aggregation_table_data: 'Artefatti',
  registry_key_artefacts_aggregation_table_data: 'Artefatti',
  url_artefacts_aggregation_table_data: 'Artefatti',
  url_telemetry_aggregation_table_data: 'Telemetria',
  external_reports: 'Analisi OSINT correlate'
};

/**
 * Partendo dal nome della tabella, restituisce una stringa, corrispondente alla label della tabella stessa.
 * @param {string} name - Il nome interno della tabella
 * @returns {string} - Label Human Friendly
 */
export default name => tableNameToLabel[name] || name;
