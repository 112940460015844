const initialState = {
  glr: {},
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_GLR_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
      };

    case 'GET_GLR_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        glr: action.data,
      };

    case 'GET_GLR_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
      };

    default:
      return state;
  }
}
