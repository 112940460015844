import React, { Component } from 'react';
import { Localized } from '@fluent/react';

import AppShell from '../AppShell';
import ContentDoc from './ContentDoc';
import EmptyState from '../EmptyState';
import Spinner from '../Spinner';
import { prettyDate } from '../../tools/date';
import './Report.scss';

export default class Dynamic extends Component {
  state = {};

  // Workaround
  getEscapedString = string => {
    const el = document.createElement('textarea');
    el.innerHTML = string;
    return encodeURIComponent(el.innerText);
  };

  render() {
    const { isLoading, match, dynamic, user } = this.props;
    if (isLoading) {
      return (
        <AppShell noFilterBar match={match} user={user}>
          <div className="container pXXLarge">
            <div className="hero-unit text-center">
              <Spinner />
            </div>
          </div>
        </AppShell>
      );
    } else if (!dynamic || !dynamic.title) {
      return (
        <AppShell noFilterBar match={match} user={user}>
          <div className="container pXXLarge">
            <div className="hero-unit text-center">
              <EmptyState
                img="/img/report.svg"
                label={
                  <Localized id="no_pages_found">no_pages_found</Localized>
                }
              />
            </div>
          </div>
        </AppShell>
      );
    }

    const { page_category, content, date, modified, title, pageType } = dynamic;
    return (
      <AppShell noFilterBar match={match} user={user}>
        <div className={`row justify-content-center ${pageType}`}>
          <div className="col col-12 col-lg-12">
            <div className="card mb-4 report-content">
              <div className="card-body">
                <div className="report-title page-title">
                  <div className="row">
                    <div className="col-12">
                      <h1
                        className="report-title-h1"
                        dangerouslySetInnerHTML={{ __html: title.rendered }}
                      />
                    </div>
                    <div className="col-12">
                      <div className="badge report-tags">
                        {page_category.map(tag => (
                          <span
                            className="badge report-tag"
                            key={tag.id}
                            dangerouslySetInnerHTML={{ __html: tag.name }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {page_category[0].slug === 'documentazione' ? (
                  <ContentDoc content={dynamic.content}></ContentDoc>
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: content.rendered }} />
                )}

                <div className="report-metadata">
                  <p>
                    <Localized id="date_publication">
                      date_publication
                    </Localized>
                    : {prettyDate(new Date(date))}
                  </p>
                  <p>
                    <Localized id="date_update">date_update</Localized>:{' '}
                    {prettyDate(new Date(modified))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppShell>
    );
  }
}
