import Table from '../../Table';
import LeafletMap from '../../LeafletMap/index';
import WhoisTable from '../../Table/WhoisTable';

const tableNameToComponent = {
  geoip_aggregation_table_data: LeafletMap,
  whois_aggregation_table_data: WhoisTable
};

/**
 * Partendo dalla nome della tabelle, restituisce il componente da visualizzare.
 *  Default to Table.
 * @param {string} name - Il nome della tabella
 * @returns {ReactElement} - L'elemento adatto alla tabella.
 */
export default name => tableNameToComponent[name] || Table;
