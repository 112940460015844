import { DateTime } from "luxon";

const initialState = {
  events: {},
  requestErrorMessage: "",
  hasFetchedData: false,
  page: "1",
  orgID: -1,
  threatSourcesGraph: [],
  isLoadingThreatSourcesGraph: false,
  hasFetchedDataThreatSourcesGraph: false,
  dateFilter: {
    filter: {
      date_start: DateTime.local()
        .minus({ days: 7 })
        .toFormat("yyyy-LL-dd HH:mm:ss")
    },
    label: "Last 7 days"
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "GET_THREAT_EVENTS_REQUEST":
      return {
        ...state,
        events: {
          ...state.events,
          [action.eventsType]: {}
        },
        hasFetchedData: false,
        // isLoading: true
      };

    case "GET_THREAT_EVENTS_SUCCESS":
      return {
        ...state,
        hasFetchedData: true,
        events: { ...state.events, [action.eventsType]: action.data },
        page: action.page,
        orgID: action.orgID
      };

    case "GET_THREAT_EVENTS_FAILED":
      return {
        ...state,
        requestErrorMessage: action.message,
        hasFetchedData: false
      };

    case "GET_THREAT_SOURCES_GRAPH_REQUEST":
      return {
        ...state,
        hasFetchedDataThreatSourcesGraph: false,
        isLoadingThreatSourcesGraph: true
      };

    case "GET_THREAT_SOURCES_GRAPH_SUCCESS":
      return {
        ...state,
        isLoadingThreatSourcesGraph: false,
        hasFetchedDataThreatSourcesGraph: true,
        threatSourcesGraph: action.data
      };

    case "GET_THREAT_SOURCES_GRAPH_FAILED":
      return {
        ...state,
        isLoadingThreatSourcesGraph: false,
        hasFetchedDataThreatSourcesGraph: false
      };

    default:
      return state;
  }
}
