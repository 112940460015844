import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import './LeafletMap.scss';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default class LeafletMap extends Component {
  static defaultProps = {
    latitude: 41.2616,
    longitude: -96.182,
    payload: { data: [] }
  };

  state = {
    zoom: 10
  };

  componentDidMount() {
    setTimeout(() => {
      const { payload: { data } } = this.props;
      if (!data || !data.length) return;
      const latLongBounds = new L.latLngBounds(
        data.map(el => [el.latitude, el.longitude])
      );
      if (this.map && this.map.leafletElement) {
        this.map.leafletElement.fitBounds(latLongBounds.pad(0.5));
      }
    }, 1000);
  }

  render() {
    const { payload: { data } } = this.props;
    const { zoom } = this.state;

    const position = el => [el.latitude, el.longitude];

    return (
      <Map
        animate
        center={[0, 0]}
        zoom={zoom}
        className="table-card"
        style={{ height: '500px' }}
        ref={map => (this.map = map)}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {data.map(row => (
          <Marker position={position(row)}>
            <Popup>
              <span>{row.ip}</span>
            </Popup>
          </Marker>
        ))}
      </Map>
    );
  }
}
