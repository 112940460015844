import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppShell from '../AppShell';
import Spinner from '../Spinner';
import EmptyState from '../EmptyState';
import RelatedReports from './RelatedReports';
import IOCEventsListContainer from '../../containers/IOCEventsList';
import InlineSpinner from '../Spinner/InlineSpinner';
import { prettyDate } from '../../tools/date';
import './Report.scss';
import './PrintReport.scss';
import { Localized } from '@fluent/react';

export default class Report extends Component {
  state = {
    favoriteLoading: false,
  };

  searchURL = tag => {
    const { reportsType, mispActor } = this.props;
    const { location } = window;
    const urlParams = new URLSearchParams(location.search.substring(1));
    const tags = [tag];

    urlParams.set('tag_filter', JSON.stringify(tags));
    urlParams.set('type', 'report');
    urlParams.set('page', '1');

    if (mispActor) {
      urlParams.set('misp_actor', mispActor);
    }

    switch (reportsType) {
      case 'SPECIAL_REPORTS':
        urlParams.set('is_special', true);
        break;

      case 'focused':
        urlParams.set('is_custom', true);
        break;

      default:
        console.warn('type not recognized');
    }

    return `/search?${urlParams.toString()}`;
  };

  printReport = () => {
    window.print();
  };

  renderTLP = tlp => {
    return `tlp-${tlp ? tlp.toLowerCase() : ''}`;
  };

  toggleFavorite = () => {
    const { addToFavorite, deleteFromFavorite } = this.props;
    const { favorite, id } = this.props.report;

    this.setState({ favoriteLoading: true });

    if (!favorite) {
      addToFavorite(id)
        .then(() => this.setState({ favoriteLoading: false }))
        .catch(() => this.setState({ favoriteLoading: false }));
    } else {
      deleteFromFavorite(id).then(() =>
        this.setState({ favoriteLoading: false })
      );
    }
  };

  // Workaround
  getEscapedString = string => {
    const el = document.createElement('textarea');
    el.innerHTML = string;
    return encodeURIComponent(el.innerText);
  };

  render() {
    const { isLoading, match, report, user } = this.props;
    const { favoriteLoading } = this.state;

    if (isLoading) {
      return (
        <AppShell noFilterBar match={match} user={user}>
          <div className="container pXXLarge">
            <div className="hero-unit text-center">
              <Spinner />
            </div>
          </div>
        </AppShell>
      );
    } else if (!report || !report.title) {
      return (
        <AppShell noFilterBar match={match} user={user}>
          <div className="container pXXLarge">
            <div className="hero-unit text-center">
              <Localized id="no_report_found" attrs={{ label: true }}>
                <EmptyState img="/img/report.svg" label="no_report_found" />
              </Localized>
            </div>
          </div>
        </AppShell>
      );
    }

    const api_url =
      process.env.REACT_APP_API_URL_PROD ||
      'https://dashboard-telsy.echo2.dev.tigersecurity.private/api';
    const pdfUrl = `${api_url}/reports/${
      report.id
    }/pdf?token=${localStorage.getItem('api_token')}`;
    //  feedUrl = `${api_url}report/${report.id}/feed?token=${localStorage.getItem('feed_token')}`;

    const {
      category,
      content,
      date,
      echo_report_cat,
      favorite,
      modified,
      related,
      title,
      tlp,
    } = report;

    const TLP = tlp[0].name;

    return (
      <AppShell noFilterBar match={match} user={user}>
        <div className="row justify-content-center">
          <div className="col col-12 col-lg-7">
            <div className="card mb-4 report-content">
              <div className="card-body">
                <div className="report-title">
                  <h1
                    className="report-title-h1"
                    dangerouslySetInnerHTML={{ __html: title.rendered }}
                  />
                  <div className={`badge badge-success ${this.renderTLP(TLP)}`}>
                    {TLP}
                  </div>
                </div>
                <div className="report-tags">
                  {(echo_report_cat || []).map(tag => (
                    <span
                      className="badge report-tag"
                      key={tag.id}
                      dangerouslySetInnerHTML={{ __html: tag.name }}
                    />
                  ))}
                </div>
                <div
                  className="report-content-body"
                  dangerouslySetInnerHTML={{ __html: content.rendered }}
                />
                <div className="report-ticket d-print-none">
                  <Link
                    className="border-0"
                    to={`/ticket/new/${this.getEscapedString(
                      title.rendered
                    )}/4`}
                  >
                    <span className="ask-to">
                      <i
                        className="fa fa-envelope-o mr-2"
                        aria-label="Richiedi informazioni"
                      />
                      <Localized id="ask_analysts">ask_analysts</Localized>
                    </span>
                  </Link>
                </div>

                <div className="report-tools text-right d-print-none">
                  <div className="row justify-content-end">
                    <div className="col-1">
                      <Localized id="pdf_download" attrs={{ title: true }}>
                        <a
                          className="pdf-download"
                          href={pdfUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="pdf_download"
                        >
                          <i
                            className="fa fa-file-pdf-o cursor-pointer"
                            aria-label="pdf_download"
                          />
                        </a>
                      </Localized>
                    </div>
                    <div className="col-1">
                      {favoriteLoading ? (
                        <InlineSpinner width={24} height={24} />
                      ) : !favorite ? (
                        <Localized
                          id="favorites_add_to"
                          attrs={{ title: true }}
                        >
                          <i
                            className={`favorite fa fa-star${
                              favorite ? '' : '-o'
                            } fa-lg`}
                            onClick={this.toggleFavorite}
                            title="favorites_add_to"
                          />
                        </Localized>
                      ) : (
                        <Localized
                          id="favorites_remove_from"
                          attrs={{ title: true }}
                        >
                          <i
                            className={`favorite fa fa-star${
                              favorite ? '' : '-o'
                            } fa-lg`}
                            onClick={this.toggleFavorite}
                            title="favorites_remove_from"
                          />
                        </Localized>
                      )}
                    </div>
                  </div>

                  {/*<a href={feedUrl} target="_blank" title="RSS">
                    <i  
                      className={`fa fa-rss`}
                      aria-label="FeedRSS al del report"
                    />
                  </a>*/}
                </div>
                <div className="report-metadata">
                  <p>
                    <Localized id="date_publication">
                      date_publication
                    </Localized>
                    : {prettyDate(new Date(date))}
                  </p>
                  <p>
                    <Localized id="date_update">date_update</Localized>:{' '}
                    {prettyDate(new Date(modified))}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-lg-5">
            <div className="card mb-4">
              <div className="card-header">
                <h2>
                  <Localized id="categories">categories</Localized>
                </h2>
              </div>
              <div className="card-body">
                <div className="report-tags">
                  {category.map(tag => (
                    <span className="badge report-tag" key={tag.id}>
                      <Link
                        to={this.searchURL(tag.slug)}
                        dangerouslySetInnerHTML={{ __html: tag.name }}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>
            {report['misp-event'].length > 0 && (
              <IOCEventsListContainer
                idList={report['misp-event'].map(m => parseInt(m.slug, 10))}
                showPagination={false}
              />
            )}
            <RelatedReports reports={related} />
          </div>
        </div>
      </AppShell>
    );
  }
}
