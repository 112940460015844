import React, { Component } from 'react';
import { Localized } from '@fluent/react';
import { Card, CardHeader, CardBody, Row } from 'reactstrap';

export default class AdversaryDetails extends Component {
  render() {
    const { title, content, flag, featured_image, adversary_area } = this.props;
    return (
      <Card className="mb-4">
        <CardHeader>
          <h2>
            <i className="fa fa-bolt ts-blue2" aria-hidden="true" />{' '}
            <span className="ts-blue2">
              <Localized id="insight">insight</Localized> /
            </span>{' '}
            <Localized id="adversaries">adversaries</Localized>
          </h2>
        </CardHeader>
        <CardBody>
          <ul className="list-unstyled">
            <li className="media">
              <Row>
                <img
                  src={
                    featured_image ? featured_image : `/img/flags/${flag}.png`
                  }
                  className="img-avatar img-fluid"
                  alt="Flag"
                />
                <div className="media-body col-12">
                  <h3 className="mt-0 mb-1">{title.rendered}</h3>
                  <span
                    dangerouslySetInnerHTML={{ __html: content.rendered }}
                  />
                </div>
              </Row>
            </li>
            <li className="media">
              <div className="report-tags">
                {adversary_area.map(a => (
                  <span
                    key={a.id}
                    className="badge report-tag"
                    dangerouslySetInnerHTML={{ __html: a.name }}
                  />
                ))}
              </div>
            </li>
          </ul>
        </CardBody>
      </Card>
    );
  }
}
