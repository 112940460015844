import React, { Component } from 'react';
import { connect } from 'react-redux';
import FilterBar from '../../components/FilterBar';
import {editImpactDateFilter} from '../../actions/impact';

class FilterBarContainer extends Component {
  render() {
    return <FilterBar {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    editImpactDateFilter: (...args) => dispatch(editImpactDateFilter(...args)),
  }
}

export default connect(
  (state) => ({
    user: state.user.user['me'],
    dateFilter: state.impact.dateFilter,
  }),
  mapDispatchToProps
)(FilterBarContainer);
