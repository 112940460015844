const initialState = {
  reports: {},
  isLoading: false,
  requestErrorMessage: '',
  hasFetchedData: false,
  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_REPORT_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true,
        error: false
      };

    case 'GET_REPORT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        reports: {
          ...state.reports,
          [action.data.id]: action.data
        }
      };

    case 'GET_REPORT_FAILED':
      return {
        ...state,
        isLoading: false,
        requestErrorMessage: action.message,
        hasFetchedData: false,
        error: true
      };
    case 'GET_REPORT_RELATED_SUCCESS':
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.id]: {
            ...state.reports[action.id],
            related: action.data
          }
        }
      };

    case 'ADD_TO_FAVORITE_SUCCESS':
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.id]: {
            ...state.reports[action.id],            
            favorite: true
          }
        }
      };

    case 'DELETE_FROM_FAVORITE_SUCCESS':
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.id]: {
            ...state.reports[action.id],            
            favorite: false
          }
        }
      };

    default:
      return state;
  }
}
