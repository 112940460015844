import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Login.scss';
import { Localized } from '@fluent/react';

export default class ResetPassword extends Component {
  state = {
    email: '',
    password: '',
    repeatedPassword: '',
    currentPassword: '',
    isPasswordEqual: true,
    isPasswordValid: true,
  };

  checkValidPassword = p => p.length >= 8;

  /**
   * Chiama la callback, passatagli dal reducer, con i dati dello stato,
   * in questo caso { username, password }
   */
  emailResetPassword = e => {
    e.preventDefault();

    const { email } = this.state;

    this.props.emailResetPassword({
      email,
    });
  };

  /**
   * Chiama la callback, passatagli dal reducer, con i dati dello stato,
   * in questo caso { username, password }
   */
  resetPassword = (token, firstChange, e) => {
    e.preventDefault();

    const {
      password,
      repeatedPassword: confirmPassword,
      currentPassword,
      isPasswordValid,
      isPasswordEqual,
    } = this.state;

    if (!isPasswordValid || !isPasswordEqual) {
      return;
    }

    this.props.resetPassword(token, firstChange, {
      password,
      confirmPassword,
      currentPassword,
    });
  };

  /**
   * onChange handler per la inputbox username
   */
  onUserNameChange = e => {
    this.setState({
      email: e.target.value,
    });
  };

  /**
   * onChange handler per la inputbox password
   */
  onPasswordChange = e => {
    this.setState({
      password: e.target.value,
      isPasswordEqual: e.target.value === this.state.password,
      isPasswordValid: this.checkValidPassword(e.target.value),
    });
  };

  onRepeatedPasswordChange = e => {
    this.setState({
      repeatedPassword: e.target.value,
      isPasswordEqual: e.target.value === this.state.password,
    });
  };

  onCurrentPasswordChange = e => {
    this.setState({
      currentPassword: e.target.value,
    });
  };

  render() {
    const {
      email,
      password,
      repeatedPassword,
      currentPassword,
      isPasswordEqual,
      isPasswordValid,
    } = this.state;
    const { loginErrorMessage, emailSent } = this.props;

    const { location } = window;
    const urlParams = new URLSearchParams(location.search.substring(1));
    const token = location.hash.substring(1);
    const firstChange = urlParams.has('first_change');

    let errorMessage = '';

    if (!isPasswordEqual) {
      errorMessage = 'Le password non coincidono';
    }

    if (!isPasswordValid) {
      errorMessage =
        'La password non è valida. Deve essere di almeno 8 caratteri.';
    }

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col col-md-10 col-lg-8 col-xl-6 mt-5">
            <form
              className="text-center"
              onSubmit={
                token
                  ? e => this.resetPassword(token, firstChange, e)
                  : this.emailResetPassword
              }
            >
              <Link to="/">
                <img className="logo-login" src="/img/logo.png" alt="logo" />
              </Link>
              <div className="jumbotron login">
                <div className="title-box">
                  {firstChange ? (
                    <Localized id="reset_password_change_password">
                      reset_password_change_password
                    </Localized>
                  ) : (
                    <Localized id="reset_password_reset_password">
                      reset_password_change_password
                    </Localized>
                  )}
                </div>

                {token ? (
                  <div>
                    {firstChange && (
                      <div className="input-group input-group-lg">
                        <span className="input-group-addon">
                          <i className="icon-lock icons font-2xl" />
                        </span>
                        <input
                          className="form-control login-input"
                          type="password"
                          value={currentPassword}
                          placeholder="Password attuale"
                          aria-label="Password"
                          onChange={this.onCurrentPasswordChange}
                        />
                      </div>
                    )}
                    <br />
                    <div className="input-group input-group-lg">
                      <span className="input-group-addon">
                        <i className="icon-lock icons font-2xl" />
                      </span>
                      <input
                        className="form-control login-input"
                        type="password"
                        value={password}
                        placeholder="Nuova Password"
                        aria-label="Password"
                        onChange={this.onPasswordChange}
                      />
                    </div>
                    <br />
                    <div className="input-group input-group-lg">
                      <span className="input-group-addon">
                        <i className="icon-lock icons font-2xl" />
                      </span>
                      <input
                        className="form-control login-input"
                        type="password"
                        value={repeatedPassword}
                        placeholder="Ripeti nuova Password"
                        aria-label="Password"
                        onChange={this.onRepeatedPasswordChange}
                      />
                    </div>
                    <br />
                    <div className="input-group input-group-lg">
                      <button
                        className="btn btn-lg btn-primary btn-block btn-login"
                        type="submit"
                        disabled={
                          password === '' ||
                          !isPasswordEqual ||
                          !isPasswordValid
                        }
                      >
                        Cambia password
                      </button>
                    </div>
                    {loginErrorMessage && (
                      <div className="alert alert-danger">
                        {loginErrorMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger">{errorMessage}</div>
                    )}
                  </div>
                ) : !emailSent ? (
                  <div>
                    <div className="input-group input-group-lg">
                      <span className="input-group-addon">
                        <i className="icon-user icons font-2xl" />
                      </span>
                      <input
                        className="form-control login-input"
                        type="text"
                        value={email}
                        placeholder="E-Mail"
                        aria-label="Username"
                        onChange={this.onUserNameChange}
                      />
                    </div>
                    <br />
                    <div className="input-group input-group-lg">
                      <button
                        className="btn btn-lg btn-primary btn-block btn-login"
                        type="submit"
                      >
                        <Localized id="reset_password_send_code">
                          reset_password_send_code
                        </Localized>
                      </button>
                    </div>
                    {loginErrorMessage && (
                      <div className="alert alert-danger">
                        {loginErrorMessage}
                      </div>
                    )}{' '}
                  </div>
                ) : (
                  <div>Controlla la tua casella di posta.</div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
