import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

export default class Adversary extends Component {
  render() {
    const { adversary_country, content, featured_image, title } = this.props;
    const country = adversary_country && adversary_country[0].name;

    return (
      <li className="media mb-4 adversaries-list-item">
        <Row>
          <Col xl="4" lg="12">
            <Row className="imgs">
              <div className="col-6">
                <img
                  className="img-avatar img-fluid"
                  src={
                    featured_image ? featured_image : '/img/img-placeholder.jpg'
                  }
                  alt="Actor avatar"
                />
              </div>
              <div className="col-6">
                {country ? (
                  <img
                    className="img-flag img-fluid"
                    src={`/img/flags/${country}.png`}
                    alt={country}
                  />
                ) : (
                  <img
                    className="img-flag img-fluid"
                    src={`/img/flags/_noFlag.png`}
                    alt={`No Flag`}
                  />
                )}
              </div>
            </Row>
          </Col>
          <Col xl="7" lg="12">
            <div className="media-body">
              <h3 className="mt-0 mb-1 media-heading adversary-title">
                <Link to={`/adversary/${title.rendered}`}>
                  {title.rendered}
                </Link>
              </h3>
              {content.rendered ? (
                <span dangerouslySetInnerHTML={{ __html: content.rendered }} />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: 'Al momento non ci sono altre informazioni.',
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </li>
    );
  }
}
