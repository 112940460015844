import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Localized } from '@fluent/react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import IOCcounter from './IOCcounter';
import { prettyDate } from '../../tools/date';
import Spinner from '../Spinner';
import './DeltaList.scss';

export default class DeltaList extends Component {
  static defaultProps = {
    ioc: {},
    lastUpdate: {
      timestamp: 0,
      timestampDelta: 0,
    },
  };

  state = {
    dropdownOpen: false,
    orgId: -1,
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  changeOrgId = orgId => {
    const { setOrgId } = this.props;
    this.setState({ orgId });
    setOrgId(orgId);
  };

  render() {
    const { ioc, link, isLoading, lastUpdate } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div className="card delta-list">
        <div className="card-header">
          <Dropdown
            className="count-org-filter btn btn-ligth btn-btnless float-right"
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
          >
            <DropdownToggle caret>
              <img
                src={`/img/org/${this.state.orgId}.svg`}
                alt="Selected organization"
              />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.changeOrgId(-1)}>
                <img src="/img/org/-1.svg" alt="" />
              </DropdownItem>
              <DropdownItem onClick={() => this.changeOrgId(1)}>
                <img src="/img/org/1.svg" alt="" />
              </DropdownItem>
              <DropdownItem onClick={() => this.changeOrgId(292)}>
                <img src="/img/org/292.svg" alt="" />
              </DropdownItem>
              <DropdownItem onClick={() => this.changeOrgId(294)}>
                <img src="/img/org/294.svg" alt="" />
              </DropdownItem>
              <DropdownItem onClick={() => this.changeOrgId(295)}>
                <img src="/img/org/295.svg" alt="" />
              </DropdownItem>
              <DropdownItem onClick={() => this.changeOrgId(289)}>
                <img src="/img/org/289.svg" alt="" />
              </DropdownItem>
              <DropdownItem onClick={() => this.changeOrgId(377)}>
                <img src="/img/org/377.svg" alt="" />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Link to={link}>
            <h2>
              <i className="fa fa-bolt ts-blue2" />{' '}
              <span className="ts-blue2">
                <Localized id="threats">threats</Localized> /{' '}
              </span>
              <Localized id="ioc_counter">ioc_counter</Localized>
            </h2>
          </Link>
        </div>
        <div className="card-body">
          <table className="table table-striped delta-list-table text-center">
            <thead>
              <tr>
                <th>
                  <Localized id="table_typology">table_typology</Localized>
                </th>
                <th>
                  <Localized id="table_total">table_total</Localized>
                  <br />
                  <span className="small right">
                    {prettyDate(new Date(lastUpdate.timestamp))}
                  </span>
                </th>
                <th>
                  <Localized id="table_last_day">table_last_day</Localized>
                  <br />
                  <span className="small right">
                    {prettyDate(new Date(lastUpdate.timestampDelta))}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(ioc).map(d => (
                <IOCcounter
                  key={d}
                  type={d}
                  {...ioc[d]}
                  orgId={this.state.orgId}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
