import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Login.scss';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { Localized } from '@fluent/react';

export default class Login extends Component {
  state = {
    username: '',
    password: '',
  };

  /**
   * Chiama la callback, passatagli dal reducer, con i dati dello stato,
   * in questo caso { username, password }
   */
  login = e => {
    this.props.login({
      username: this.state.username,
      password: this.state.password,
    });
    e.preventDefault();
  };

  /**
   * onChange handler per la inputbox username
   */
  onUserNameChange = e => {
    this.setState({
      username: e.target.value,
    });
  };

  /**
   * onChange handler per la inputbox password
   */
  onPasswordChange = e => {
    this.setState({
      password: e.target.value,
    });
  };

  render() {
    const { username, password } = this.state;
    const { loginErrorMessage } = this.props;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col col-md-10 col-lg-8 col-xl-6 mt-5 form-wrapper">
            <form className="text-center form-content" onSubmit={this.login}>
              <Link to="/">
                <img className="logo-login" src="/img/logo.png" alt="logo" />
              </Link>
              <div className="jumbotron login">
                <div className="input-group input-group-lg">
                  <span className="input-group-addon">
                    <i className="icon-user icons font-2xl" />
                  </span>
                  <input
                    className="form-control login-input"
                    type="text"
                    value={username}
                    placeholder="Username"
                    aria-label="Username"
                    onChange={this.onUserNameChange}
                  />
                </div>
                <br />
                <div className="input-group input-group-lg">
                  <span className="input-group-addon">
                    <i className="icon-lock icons font-2xl" />
                  </span>
                  <input
                    className="form-control login-input"
                    type="password"
                    value={password}
                    placeholder="Password"
                    aria-label="Password"
                    onChange={this.onPasswordChange}
                  />
                </div>
                <div className="login-options">
                  <LanguageSelect />
                  <div className="reset_password">
                    <Link to="/reset_password">
                      <Localized id="password_reset">password_reset</Localized>
                    </Link>
                  </div>
                </div>
                <br />
                <div className="input-group input-group-lg">
                  <button
                    className="btn btn-lg btn-primary btn-block btn-login"
                    type="submit"
                  >
                    <Localized id="login_button">login_button</Localized>
                  </button>
                </div>
                {loginErrorMessage && (
                  <div className="alert alert-danger">
                    <Localized id="login_error_message">
                      {loginErrorMessage}
                    </Localized>
                  </div>
                )}
              </div>
            </form>
            <img
              src="/img/logo-telsy-ts-login.svg"
              alt="logo"
              className="logo-telsy-ts-login"
            />
          </div>
        </div>
      </div>
    );
  }
}
