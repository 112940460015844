const initialState = {
  settings: {},
  isLoading: false,
  hasFetchedData: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_SETTINGS_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true
      };

    case 'GET_SETTINGS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        settings: action.data
      };

    case 'GET_SETTINGS_FAILED':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: false
      };

    case 'SETTINGS_SAVE_REQUEST':
      return {
        ...state,
        hasFetchedData: false,
        isLoading: true
      };

    case 'SETTINGS_SAVE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: true,
        settings: {
          ...action.data,
          ...state.settings,
        }
      };

    case 'SETTINGS_SAVE_FAILED':
      return {
        ...state,
        isLoading: false,
        hasFetchedData: false
      };

    default:
      return state;
  }
}
