import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FlagMapper from './mapper-ita-postal';

export default class Adversary extends Component {
  levelIcon() {
    switch (this.props.delta) {
      case 'up':
        return <i className="fa fa-arrow-circle-up fa-2x low" title="alto" />;
      case '-':
        return <i className="fa fa-minus-circle fa-2x medium" title="medio" />;
      case 'down':
        return <i className="fa fa-arrow-circle-down fa-2x high" title="basso" />;
      default:
        return <i className="fa fa-minus-circle fa-2x medium" title="medio" />;
    }
  }

  stateIcon() {
    return <img src={`img/flags/${FlagMapper[this.props.country]}.png`} alt={this.props.country} height="25px" />;
  }
  
  render() {
    return (<tr>
      <td>{this.levelIcon()}</td>
      <td className="adversary-name">
        <Link to={`/adversary/${this.props.value}`}>{this.props.value}</Link>
      </td>
      <td>{this.stateIcon()}</td>
    </tr>);
  }
}