import React, { Component } from 'react';
import template from './template/index.js';
import AppShell from '../AppShell';
// import './SearchPage.scss';

export default class SearchPage extends Component {
  render() {
    const { location, match, user } = this.props;
    const params = new URLSearchParams(this.props.location.search.substring(1));
    const query = params.get('query') || '';
    const searchType = params.get('type');

    // template(): a seconda del searchType ritorna il componente adatto
    const ComponentToRender = template(searchType);

    return (
      <AppShell match={match} noFilterBar query={query} searchType={searchType} user={user}>
        <div className="row justify-content-center">
          <ComponentToRender location={location} query={query} />
        </div>
      </AppShell>
    );
  }
}

/* const QueryVis = ({ query, type = 'Domain' }) => (
  <div className="search-header">
    <div>
      <h1>Search term: {query}</h1>
    </div>
    <div className="search-header-right">
      <p>Type: </p>
      <span className="badge badge-info">{type}</span>
    </div>
    <hr />
  </div>
);*/
