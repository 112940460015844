import React, { Component } from 'react';
import AppShell from '../AppShell';
import { Row, Col } from 'reactstrap';
import { RedocStandalone } from 'redoc';
import { Card, CardBody } from 'reactstrap';

export default class ApiDoc extends Component {
  render() {
    return (
      <AppShell noFilterBar {...this.props}>
        <Row>
          <Col sm={{ size: 12, offset: 0 }}>
            <Card
              style={{
                height: 'calc(100vh - 55px)',
                overflow: 'auto',
                margin: '-2rem 0 0 0',
              }}
            >
              <CardBody>
                <RedocStandalone
                  specUrl={`${
                    process.env.REACT_APP_API_URL_PROD ||
                    'https://dashboard-telsy.echo2.dev.tigersecurity.private/api'
                  }/swagger.json?token=${localStorage.getItem('api_token')}`}
                  /*
                  // https://github.com/Redocly/redoc#redoc-options-object
                  */
                  options={{
                    theme: {
                      colors: {
                        main: '#e90828',
                      },
                      menu: {
                        borderRadius: '3px 0 0 3px',
                        backgroundColor: '#fff',
                      },
                    },
                    expandDefaultServerVariables: true,
                    expandSingleSchemaField: true,
                    hideDownloadButton: true,
                    hideHostname: true,
                    hideLoading: false,
                    nativeScrollbars: false,
                    scrollYOffset: 50,
                    sortPropsAlphabetically: true,
                    untrustedSpec: true,
                  }}
                  onLoaded={error => {
                    if (!error) {
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </AppShell>
    );
  }
}
