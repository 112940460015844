import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppShell from '../../components/AppShell';
import IOCPage from '../../components/IOCPage';
import { getIOC } from '../../actions/ioc';
import { downloadEvent } from '../../actions/threats';


class IOCPageContainer extends Component {
  componentDidMount() {
    const { iocs } = this.props;
    const ID = this.props.match.params.id;
    if (!iocs[ID]) {
      this.getIOC(ID);
    }
  }

  getIOC = ID => {
    this.props.getIOC(ID)
  }

  parseIOC = () => {
    const { iocs } = this.props;
    const ID = this.props.match.params.id;
    let event = iocs[ID];

    if (event) {
      event = Array.isArray(event) ? event[0] : event;
    }

    return event;
  }

  render() {
    const { downloadEvent, isLoading, location, match, user, } = this.props;

    return (
      <AppShell noFilterBar match={match} user={user}>
        <IOCPage 
          downloadEvent={downloadEvent}
          event={this.parseIOC()}
          isLoading={isLoading}
          location={location}
          match={match}
        />
      </AppShell>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIOC: (...args) => dispatch(getIOC(...args)),
    downloadEvent: (...args) => dispatch(downloadEvent(...args)),
  }
}

const mapStateToProps = (state) => ({
  iocs: state.ioc.iocs,
  isLoading: state.ioc.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(IOCPageContainer);
