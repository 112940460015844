import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getContrast50 } from '../../tools/colors';

export default class GalaxyTags extends Component {
  searchURL = tag => {
    const { location } = this.props;

    let urlParams = new URLSearchParams(location.search.substring(1));

    let tags = urlParams.get('tag_filter');
    if (!tags) {
      tags = [];
    } else {
      try {
        tags = JSON.parse(tags);
      } catch (e) {
        tags = [];
      }
    }

    tags.push(tag);

    urlParams.set('tag_filter', JSON.stringify(tags));
    urlParams.set('type', 'events');
    urlParams.set('page', '1');

    return `/search?${urlParams.toString()}`;
  };

  render() {
    const { tags } = this.props;
    const galaxiesObj = {};

    tags.forEach(g => {
      if (g.galaxy_id === 'NULL') {
        return;
      }

      galaxiesObj[g.galaxy_name] = galaxiesObj[g.galaxy_name] || [];
      galaxiesObj[g.galaxy_name].push({
        name: g.name,
        galaxy_id: g.galaxy_id,
        value: g.galaxy_cluster_name,
        id: g.galaxy_cluster_id,
        colour: g.colour,
      });
    });

    return (
      <div className="report-tags d-print-none">
        {Object.keys(galaxiesObj).length > 0 &&
          Object.keys(galaxiesObj)
            .sort()
            .map(g => (
              <div key={g} className="galaxies report-tags">
                <span className="galaxy-title">{g}:</span>
                {galaxiesObj[g].map(v => {
                  switch (v.galaxy_id) {
                    case 188:
                    case '188':
                      return (
                        <span
                          className="badge report-tag"
                          key={v.id}
                          title={v.value}
                          style={{ backgroundColor: v.colour, color: getContrast50(v.colour) }}
                        >
                          <Link to={this.searchURL(v.name)}>{v.value}</Link>
                          <Link to={`/tool/${v.id}`} style={{ marginLeft: 10, fontSize: 10 }}>
                            <i className="fa fa-external-link" aria-hidden="true"></i>
                          </Link>
                        </span>
                      );
                    case 186:
                    case '186':
                      return (
                        <span
                          className="badge report-tag"
                          key={v.id}
                          title={v.value}
                          style={{ backgroundColor: v.colour, color: getContrast50(v.colour) }}
                        >
                          <Link to={this.searchURL(v.name)}>{v.value}</Link>
                          <Link to={`/adversary/${v.value}`} style={{ marginLeft: 10, fontSize: 10 }}>
                            <i className="fa fa-external-link" aria-hidden="true"></i>
                          </Link>
                        </span>
                      );
                    default:
                      return (
                        <span
                          className="badge report-tag"
                          key={v.id}
                          title={v.value}
                          style={{ backgroundColor: v.colour, color: getContrast50(v.colour) }}
                        >
                          <Link to={this.searchURL(v.name)}>{v.value}</Link>
                        </span>
                      );
                  }
                })}
              </div>
            ))}
      </div>
    );
  }
}
