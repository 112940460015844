/**
 * Classe che si occupa di interfacciarsi con il back-end della piattaforma.
 * Utilizza Fetch, prensente globalmente nella maggioranza dei browser.
 * @class API
 */
export default class API {
  _token = false;
  _url =
    process.env.REACT_APP_API_URL_PROD ||
    'https://dashboard-telsy.echo2.dev.tigersecurity.private/api/';
  /**
   * Creates an instance of API.
   * Se e' presente un token nel localStorage, se lo inizializza internamente.
   * @memberof API
   */
  constructor(key = 'api_token', url) {
    this.setToken(localStorage.getItem(key) || false);
    this.setUserID(localStorage.getItem('user_id') || false);
    this._key = key;

    if (url) {
      this._url = url;
    }
  }
  /**
   * Utilizzata internamente, e' un wrapper attorno a FetchAPI.
   *
   * @param {string} url L'URL assoluto a cui effettuare la richiesta
   * @param {string} method GET | POST | PUT | DELETE
   * @param {any} body Il body della richiesta, se POST | PUT
   * @param {boolean} [json=true]  Definisce se la risposta vuole essere interpretata in JSON.
   * @param {boolean} [rawBody=false] Definisce se il body della richiesta, deve essere transformato in JSON prima di inviarlo. Utilizzato per mandare un FormData.
   * @memberof API
   */
  _fetch(url, method, body, json = true, rawBody = false, text = false) {
    return safeFetch(url, {
      method,
      body: body ? (rawBody ? body : JSON.stringify(body)) : undefined,
      headers: {
        Authorization: this._token ? `Bearer ${this._token}` : undefined,
      },
    }).then(async d => {
      if (!json && !text) {
        return d.blob();
      }

      if (text) {
        return d.text();
      }

      const resJSON = await d.json();

      // Check if sessios is invalid
      if (resJSON.statusCode === 401) {
        if (resJSON.message === 'Password unchanged') {
          const token = this._token;
          this.delToken();
          window.location = `/reset_password?first_change#${token}`;
          return;
        }

        this.delToken();
        window.location.reload();
      }
      resJSON._extra = {};
      if (d.headers.has('x-pages') || d.headers.has('x-count')) {
        resJSON._extra.pages = d.headers.get('x-pages');
        resJSON._extra.count = d.headers.get('x-count');
      }
      return resJSON;
    });
  }
  /**
   * Setter per la proprieta' token all'interno della classe.
   *
   * @param {string} t - Token da settare
   * @memberof API
   */
  setToken(t) {
    this._token = t;
  }

  setUserID(id) {
    this._userID = id;
  }

  getUserID(id) {
    return this._userID;
  }
  /**
   * Rimuove il token dalla classe interna, e lo rimuove anche dal localStorage.
   *
   * @memberof API
   */
  delToken() {
    this._token = false;
    localStorage.removeItem(this._key);
  }
  /**
   * Effettua una richiesta di tipo GET.
   * @param {string} route Route relativa all'url interno delle path. Route su cui effettuare la richiesta.
   * @param {object} [data={}] Oggetto che verra' trasformato in query string nella richiesta GET.
   * @param {boolean} [json=true] Definisce se la risposta verra' interpretata come un JSON.
   * @memberof API
   */
  get(route, data = {}, json = true, text = false) {
    const esc = encodeURIComponent,
      qs = Object.keys(data)
        .map(k => `${esc(k)}=${esc(data[k])}`)
        .join('&');

    return this._fetch(
      `${this._url}/${route}?${qs}`,
      'GET',
      null,
      json,
      undefined,
      text
    );
  }
  /**
   * Effettua una richiesta di tipo POST.
   * @param {string} route  Route relativa all'url interno delle path. Route su cui effettuare la richiesta.
   * @param {object} data Il payload della richiesta post.
   * @memberof API
   */
  post(route, data) {
    return this._fetch(`${this._url}/${route}`, 'POST', data);
  }
  /**
   * Effettua una richiesta POST con il payload di tipo FormData. Non JSON.
   * @param {string} route  Route relativa all'url interno delle path. Route su cui effettuare la richiesta.
   * @param {FormData} file Il file su cui effettuare l'upload.
   * @memberof API
   */
  postWithFile(route, file) {
    return this._fetch(`${this._url}/${route}`, 'POST', file, undefined, true);
  }
}
/**
 * Fetch wrappata internamente, per permettere un behaviur custom.
 * Se la richiesta fallisce, per motivi di rete, o di backend, la richiesta viene effettuata piu' di una volta.
 * Parametri customizzabili all'interno.
 *
 * @param {string} url - URL assoluta
 * @param {object} options - Opzioni della fetch
 */
function safeFetch(url, options) {
  let retries = 3;
  let retryDelay = 2000;

  if (options && options.retries) {
    retries = options.retries;
  }

  if (options && options.retryDelay) {
    retryDelay = options.retryDelay;
  }

  return new Promise(function (resolve, reject) {
    var wrappedFetch = function (n) {
      fetch(url, options)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          if (n > 0) {
            setTimeout(function () {
              wrappedFetch(--n);
            }, retryDelay);
          } else {
            reject(error);
          }
        });
    };
    wrappedFetch(retries);
  });
}
