/**
 * Preleva la lista dei report.
 * @-param {object} data
 * @-param {string} data.type Stringa che rappresenta il reportsType, usato internamente per isolare nel reducer, le varie tipologie di report.
 * @-param {object} data.hashFilter Oggetto che contiene il filtro da applicare ai reports. { filter: { }}
 * @-param {any} router
 */
export function getDynamicList(endpoint, data) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_DYNAMIC_LIST_REQUEST',
      pageType: data.type,
    });

    const dateFilter = data.hashFilter ? getState().impact.dateFilter : { filter: {} };

    const d = await api.get(`categories/${endpoint}`, { ...data.params, ...dateFilter.filter });

    if (d.error) {
      dispatch({
        type: 'GET_DYNAMIC_LIST_FAILED',
        message: d.error,
        pageType: data.type,
      });
    } else {
      dispatch({
        type: 'GET_DYNAMIC_LIST_SUCCESS',
        data: d.data,
        page: data.params.page,
        extra: d._extra,
        pageType: data.type,
      });
    }
  };
}
