import React from "react";
import { Col } from "reactstrap";
import ReportList from "../../../containers/ReportsList";
import IOCEventsList from "../../../containers/IOCEventsList";

const string = ({ query }) => {
  return (
    <Col sm={{ size: 10, offset: 1 }}>
      <ReportList
        reportsType="SEARCH_REPORT"
        isSearch
        searchQuery={query}
        showPagination
      />
      <IOCEventsList
        isSearch
        searchQuery={query}
        eventsType={`SEARCH_${query.slice(0, 5)}`}
        showPagination
      />
    </Col>
  );
};

export default string;
