import React, { Component } from 'react';
import { connect } from 'react-redux';
import Settings from '../../components/Settings';
import { editSettings } from '../../actions/settings';

class SettingsContainer extends Component {
  render() {
    return (
      <Settings {...this.props} />
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    editSettings: (...args) => dispatch(editSettings(...args))
  }
}

export default connect(
  (state) => ({
    user: state.user.user["me"],
  }),
  mapDispatchToProps
)(SettingsContainer);