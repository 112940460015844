import React from "react";
import AdversariesPageContainer from "../../../containers/AdversariesPage";
// import AdversaryListContainer from "../../../containers/AdversaryList";

const adversaries = ({ query, location }) => {
  return (
    <div className="col">
      <AdversariesPageContainer
        isSearch
        location={location}
        searchQuery={query}
        showPagination
      />
    </div>
  );
};

export default adversaries;
