import React, { Component } from 'react';
import { prettyDate } from "../../tools/date";

export default class TicketReply extends Component {
  render() {
    const { comment_html, user, created_at } = this.props;

    return (
      <div className="row ticket-reply clearfix">
        <div className="col col-12 mb-2 col-sm-2 text-sm-center ticket-left">
          <img className="ticket-avatar img-circle img-responsive" src={user.photo} alt="" />
          <h5 className="ticket-owner">{user.username}</h5>
        </div>

        <div className="col col-12 col-sm-10 ticket-right">
          <p dangerouslySetInnerHTML={{ __html: comment_html }}></p>
          <div className="blog-meta clearfix">
            <small>{prettyDate(new Date(created_at))}</small>
          </div>
        </div>
      </div>
    );
  }
}
