const initialState = {
  isAuthenticated: !!localStorage.getItem('api_token'),
  loginErrorMessage: '',
  emailSent: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'AUTHENTICATION_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
      };
    case 'LOGOUT_SUCCESS':
    case 'AUTHENTICATION_FAILED':
      return {
        ...state,
        isAuthenticated: false,
        loginErrorMessage: action.message,
      };
    case 'RESETPASSWORDEMAIL_SUCCESS':
      return {
        ...state,
        isAuthenticated: false,
        emailSent: true,
      };
    case 'RESETPASSWORDEMAIL_FAILED':
      return {
        ...state,
        isAuthenticated: false,
        loginErrorMessage: action.message,
        emailSent: false,
      };

    case 'RESETPASSWORD_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
      };
    case 'RESETPASSWORD_FAILED':
      return {
        ...state,
        isAuthenticated: false,
        loginErrorMessage: action.message,
      };
    default:
      return state;
  }
}
