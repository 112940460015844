import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Localized } from "@fluent/react";

export default class RelatedReports extends PureComponent {
  static defaultProps = {
    reports: [],
  };

  render() {
    const { reports } = this.props;

    if (!reports.length) {
      return null;
    }

    return (
      <div className="card d-print-none">
        <div className="card-header">
          <h2>
            <Localized id="related_reports">related_reports</Localized>
          </h2>
        </div>

        <div className="card-body">
          <ul className="list-group">
            {reports.map((rep) => (
              <li className="list-group-item" key={rep.id}>
                <Link
                  to={`/report/${rep.id}`}
                  dangerouslySetInnerHTML={{ __html: rep.title.rendered }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
