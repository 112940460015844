import React, { Component } from 'react';
import AppShell from '../AppShell';
import PropTypes from 'prop-types';
import InlineSpinner from '../Spinner/InlineSpinner';
import { Localized } from '@fluent/react';

//const InlineError = error => <span className="inline-error">{error}</span>;

export default class NewTicket extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  file = undefined;

  state = {
    subject: '',
    description: '',
    type: 0,
    errors: {},
    isSubmitting: false,
  };

  onSubjectChange = e => this.setState({ subject: e.target.value });

  onDescriptionChange = e => this.setState({ description: e.target.value });

  onFileUpload = e => {
    const file = e.target.files[0];
    const form = new FormData();
    const inputFile = document.getElementById('ticket-attach');

    if (file) {
      form.append('file', e.target.files[0]);
      this.file = form;
      inputFile.setAttribute('data-value', file.name);
    }
  };

  onFormSubmit = e => {
    e.preventDefault();
    const { subject, description, type } = this.state;

    if (subject !== '' && description !== '' && type !== 0) {
      this.setState({ isSubmitting: true });

      this.props
        .addNewTicket(
          { subject, description, type },
          !this.file ? this.context.router : undefined
        )
        .then(e => {
          if (this.file) {
            this.props
              .addNewTicketReplyAttachment(e.id, this.file, this.context.router)
              .then(e => this.setState({ isSubmitting: false }));
          } else {
            this.setState({ isSubmitting: false });
          }
        });
    }
  };

  componentDidMount() {
    const {
      computedMatch: { params },
    } = this.props;

    if (params && params.title) {
      this.setState({
        subject: decodeURIComponent(params.title),
        type: params.ticketType || 5, // general request
      });
    }
  }

  onCategoryChange = e => {
    this.setState({ type: e.target.value });
  };

  render() {
    const { info } = this.props;
    const { subject, type, isSubmitting } = this.state;

    return (
      <AppShell noFilterBar {...this.props}>
        <div className="row justify-content-center">
          <div className="col col-lg-10 col-xl-8">
            <div
              className={`card border-light ${
                isSubmitting && 'card-submitting'
              }`}
            >
              <div className="card-header">
                <h2>
                  <i className="icon-envelope-letter pr-2" />
                  <Localized id="ticket_new">ticket_new</Localized>
                </h2>
              </div>

              <div className="card-body">
                {isSubmitting && (
                  <div className="card-submitting-loader">
                    <InlineSpinner />
                  </div>
                )}
                <form onSubmit={this.onFormSubmit} className="ticket-new">
                  <div className="form-group">
                    <label htmlFor="object">
                      <Localized id="ticket_subject">ticket_subject</Localized>
                    </label>

                    <Localized
                      id="ticket_subject_placeholder"
                      attrs={{ placeholder: true }}
                    >
                      <input
                        placeholder="ticket_subject_placeholder"
                        className="form-control"
                        id="object"
                        type="text"
                        aria-describedby="object"
                        value={subject}
                        onChange={this.onSubjectChange}
                      />
                    </Localized>
                  </div>

                  <div className="form-group">
                    <label htmlFor="category">
                      <Localized id="ticket_category">
                        ticket_category
                      </Localized>
                    </label>
                    <select
                      className="form-control"
                      id="category"
                      value={type}
                      onChange={this.onCategoryChange}
                    >
                      <Localized
                        id="ticket_select_category"
                        attrs={{ placeholder: true }}
                      >
                        <option value={0} placeholder="ticket_select_category">
                          ticket_select_category
                        </option>
                      </Localized>
                      {info.types &&
                        Object.keys(info.types).map(e => (
                          <option key={e} value={info.types[e]}>
                            {e}{' '}
                            {e === 'On demand'
                              ? ` - ticket left: ${info.left}`
                              : ''}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="file-upload" className="d-block">
                      <Localized id="ticket_file_upload">
                        ticket_file_upload
                      </Localized>
                    </label>
                    <span className="custom-file">
                      <input
                        className="custom-file-input"
                        id="file-upload"
                        type="file"
                        onChange={this.onFileUpload}
                      />
                      <Localized
                        id="ticket_file_upload_input"
                        attrs={{ 'data-value': true }}
                      >
                        <span
                          className="custom-file-control"
                          id="ticket-attach"
                          data-value="ticket_file_upload_input "
                        />
                      </Localized>
                    </span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="content">
                      <Localized id="ticket_content">ticket_content</Localized>
                    </label>
                    <Localized
                      id="ticket_content_placeholder"
                      attrs={{ placeholder: true }}
                    >
                      <textarea
                        className="form-control"
                        id="content"
                        rows="7"
                        onChange={this.onDescriptionChange}
                        placeholder="ticket_content_placeholder"
                      />
                    </Localized>
                  </div>

                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">
                      <Localized id="ticket_send">ticket_send</Localized>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AppShell>
    );
  }
}
