import React, { Component } from 'react';
import TicketReply from './TicketReply';

export default class TicketReplies extends Component {
  static defaultProps = {
    replies: []
  };

  render() {
    const { replies } = this.props;

    if (!replies.length) {
      return null;
    }

    return (
      <div className="card border-light mb-4">
        <div className="card-header">
          <h2>Risposte</h2>
        </div>
        <div className="card-body p-0">
          {replies && replies.map((com, id) => <TicketReply key={id} {...com} />)}
        </div>
      </div>
    );
  }
}