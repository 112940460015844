import React, { useEffect, useState } from 'react';

import { Localized, useLocalization } from '@fluent/react';

import { Alert, Button, Col, Row } from 'reactstrap';
// https://github.com/reactstrap/reactstrap

import Scrollspy from 'react-scrollspy';
// https://github.com/makotot/react-scrollspy

import Viewer from 'react-viewer';
// https://github.com/infeng/react-viewer

import Sticky from 'react-stickynode';
// https://github.com/yahoo/react-stickynode

import 'animate.css';
// https://animate.style/

import DocHtmlParser from './DocHtmlParser';
import DebugObjectsList from './DebugObjectsList';

import './contentDoc.scss';

export default function ContentDoc(props) {
  // usata per nascondere la lista degli oggetti nell'ambiente di produzione
  const env = process.env.NODE_ENV;

  // Alert message
  const [alertMessage, setAlertMessage] = useState('');
  const [alert, setAlert] = useState(false);

  // HTML che arriva alla pagina
  const content = props.content.rendered;

  // parsing HTML del contenuto della pagina
  const parsedHtml = DocHtmlParser({ html: content });

  const filteredHtml = parsedHtml.html;
  const sectionList = parsedHtml.sectionList;
  const h1List = parsedHtml.h1List;
  const h2List = parsedHtml.h2List;
  const h3List = parsedHtml.h3List;
  const h4List = parsedHtml.h4List;
  const h5List = parsedHtml.h5List;
  const h6List = parsedHtml.h6List;
  const pList = parsedHtml.pList;
  const aList = parsedHtml.aList;
  const imgList = parsedHtml.imgList;

  // Voci dell'indice generato
  const tocItems = parsedHtml.tocItems;

  // Avvio con Indice esteso o compatto
  const [tocIsCompact, setTocIsCompact] = useState(true);

  // Avvio con Indice esteso o compatto
  const [toggleToc, setToggleToc] = useState(false);

  // Viewer - visualizzatore immagine
  const [imgViewerIsVisible, setImgViewerIsVisible] = useState(false);
  const [imgToShow, setImgToShow] = useState(0); // prima immagine da mostrare

  let imgObject =
    imgList &&
    imgList.map((img, i) => ({
      alt: img.attribs.alt,
      number: i,
      src: img.attribs.src,
    }));

  let { l10n } = useLocalization();
  let doc_copy_link_title = l10n.getString('doc_copy_link_title');

  useEffect(() => {
    // Aggiungo il click alle immagini per aprire la galleria con l'immagine cliccata attiva
    let clickableImagesCollection = document.getElementsByClassName(
      'img-content'
    );
    let clickableImagesArray = Array.from(clickableImagesCollection);
    clickableImagesArray.map((image, i) =>
      image.addEventListener('click', () => {
        setImgViewerIsVisible(true);
        setImgToShow(i);
      })
    );

    // Aggiungo il "copia negli appunti" ai link dei titoli
    let clickableLinksCollection = document.getElementsByClassName('copyLink');
    let clickableLinksArray = Array.from(clickableLinksCollection);

    clickableLinksArray.map(
      a =>
        a.setAttribute('title', doc_copy_link_title) &
        a.addEventListener('click', e => {
          e.preventDefault();
          navigator.clipboard.writeText(a.href);

          setAlertMessage(
            <Localized id="doc_copy_link_msg">doc_copy_link_msg</Localized>
          );
          setAlert(true);
          setTimeout(function () {
            setAlert(false);
          }, 2000);
        })
    );
  }, [doc_copy_link_title]);

  useEffect(() => {
    const hash = window.location.hash;
    window.location.hash = '';
    let timer1 = setTimeout(function () {
      window.location.hash = hash;
    }, 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div id="doc-wrapper" className="container-fluid">
      <Row>
        <Col xl="4" lg="12">
          {tocItems && tocItems.length > 0 ? (
            <Sticky
              top={50}
              bottomBoundary="#doc-wrapper"
              enabled={true}
              innerZ={1}
            >
              <div
                id="indice"
                className={`animate__animated animate__fadeIn animate__delay-1s ${
                  tocIsCompact ? 'compact' : ''
                }`}
              >
                <Row className="space-between">
                  <div className="col-xl-6 col-lg-12">
                    <h2>
                      <button
                        id="tocToggle"
                        onClick={() =>
                          setToggleToc(!toggleToc) && setTocIsCompact(false)
                        }
                      >
                        <Localized id="doc_toc">doc_toc</Localized>
                        <i
                          className="fa fa-angle-left float-right"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </h2>
                  </div>
                  <div className="col-xl-6">
                    <Button
                      id="tocCompactToggle"
                      className="float-right d-none d-xl-block"
                      onClick={() => setTocIsCompact(!tocIsCompact)}
                    >
                      {tocIsCompact ? (
                        <Localized id="doc_toggle_compact">
                          doc_toggle_compact
                        </Localized>
                      ) : (
                        <Localized id="doc_toggle_expand">
                          doc_toggle_expand
                        </Localized>
                      )}
                    </Button>
                  </div>
                </Row>

                <Scrollspy
                  offset={-100}
                  className={`items 
                  ${toggleToc ? 'show-mobile' : 'hide-mobile'}`}
                  items={tocItems.map((item, i) => item.id)}
                  currentClassName="is-current"
                >
                  {tocItems.map((item, i) => (
                    <li
                      className={`item-${item.name} ${
                        item.name === 'h1'
                          ? 'capitolo text-uppercase'
                          : item.name === 'h2'
                          ? 'sezione '
                          : 'sottosezione'
                      }`}
                      key={i}
                    >
                      <a
                        href={`#${item.id}`}
                        onClick={() => {
                          setToggleToc(false);
                        }}
                      >
                        {item.data}
                      </a>
                    </li>
                  ))}
                </Scrollspy>
              </div>
            </Sticky>
          ) : (
            ''
          )}
        </Col>

        <Col xl="7">
          <div id="doc-content" className="documentation">
            <Alert id="copyLinkMsg" isOpen={alert} color="success">
              {alertMessage}
            </Alert>
            {/*  stampo il contenuto dell'HTML filtrato */}
            <div dangerouslySetInnerHTML={{ __html: filteredHtml }} />
          </div>
        </Col>
      </Row>

      {imgObject && imgObject.length > 0 ? (
        <div>
          <Viewer
            onMaskClick={() => {
              setImgViewerIsVisible(false);
            }}
            noToolbar={true}
            activeIndex={imgToShow}
            zoomSpeed={0.1}
            visible={imgViewerIsVisible}
            onClose={() => {
              setImgViewerIsVisible(false);
            }}
            images={imgObject}
          />{' '}
        </div>
      ) : (
        ''
      )}

      {env !== 'production' ? (
        <div>
          {imgObject && imgObject.length > 0 ? (
            <Button
              id="gallery-button"
              color="primary"
              onClick={() => {
                setImgViewerIsVisible(true);
                setImgToShow(0);
              }}
            >
              <i className="fa fa-picture-o" aria-hidden="true"></i> Immagini
            </Button>
          ) : (
            ''
          )}

          <DebugObjectsList
            objectsList={{
              originalHtml: content,
              filteredHtml,
              h1List,
              h2List,
              h3List,
              h4List,
              h5List,
              h6List,
              imgList,
              aList,
              pList,
              sectionList,
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
