import React, { Component } from 'react';
import { Localized } from '@fluent/react';

export class LicenseModule extends Component {
  static defaultProps = {
    label: '',
    type: '',
    currentLicense: '',
  };

  render() {
    const { id, name, services } = this.props;
    const isActive = !!services.find(s => id === s.label);
    return (
      <div className="license-module col-xl-6 col-lg-6 col-md-6 col-12">
        <div className={`license-module-content ${isActive ? 'active' : ''}`}>
          <div className="wrapper">
            <div className="badge">
              {!isActive ? (
                <Localized id="not_active">not_active</Localized>
              ) : (
                <Localized id="active">active</Localized>
              )}
            </div>
            <div className="module-name">{name}</div>
          </div>
        </div>
      </div>
    );
  }
}
