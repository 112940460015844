import React, { Component } from 'react';
import { connect } from 'react-redux'
import GLR from '../../components/HeaderBar/GLR';
import { getGlr } from '../../actions/glr';



class GLRContainer extends Component {
  componentDidMount() {
    if (!this.props.hasFetchedData) {
      this.props.getGlr()
    }
  }
  render() {
    return <GLR {...this.props} />
  }
}



const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getGlr: () => {
      dispatch(getGlr())
    }
  }
}

export default connect(
  (state) => ({
    glr: state.glr.glr,
    isLoading: state.glr.isLoading,
    hasFetchedData: state.glr.hasFetchedData,
    requestErrorMessage: state.glr.requestErrorMessage
  }),
  mapDispatchToProps
)(GLRContainer);