import React, { Component } from "react";
import AppShell from "../AppShell";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import TicketRow from "../TicketRow";
import Pagination from "../Pagination";
import { Localized } from "@fluent/react";

import "./TicketPage.scss";

export default class TicketPage extends Component {
  static defaultProps = {
    tickets: [],
  };
  render() {
    const { tickets } = this.props;

    return (
      <AppShell noFilterBar {...this.props}>
        <Row>
          <Col sm="12">
            <Card className="border-light">
              <CardHeader>
                <h2>
                  <i className="fa ts-icon-ticket" />{" "}
                  <Localized id="tickets">tickets</Localized>
                </h2>
              </CardHeader>
              <CardBody>
                <Link to="/ticket/new">
                  <button
                    type="button"
                    className="btn btn-primary mb-4 new-ticket-btn"
                  >
                    <Localized id="ticket_new">ticket_new</Localized>
                  </button>
                </Link>

                <ul className="list-group ticket-list mb-4">
                  {tickets.map((ticket) => (
                    <TicketRow key={ticket.id} {...ticket} />
                  ))}
                </ul>

                <Pagination />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </AppShell>
    );
  }
}
