export default {
  items: [
    {
      name: 'dashboard',
      icon: 'icon-speedometer',
      url: '/',
    },
    {
      title: true,
      name: 'insight',
      icon: 'fa fa-bar-chart',
    },
    {
      name: 'reports',
      url: '/reports?page=1',
      icon: 'fa ts-icon-report',
    },
    {
      name: 'special_reports',
      url: '/special_reports?page=1',
      icon: 'fa ts-icon-special-report',
      type: ['professional', 'advanced'],
    },
    {
      name: 'custom_reports',
      url: '/custom_reports?page=1',
      icon: 'fa ts-icon-custom-report',
      //type: "business"
    },
    {
      title: true,
      name: 'threats',
      icon: 'fa fa-bolt',
    },
    {
      name: 'adversaries',
      url: '/adversaries?page=1',
      icon: 'fa ts-icon-adversaries',
    },
    {
      name: 'ioc_events',
      url: '/IOCEvents?page=1',
      icon: 'fa ts-icon-ioc-events',
    },
    {
      divider: true,
    },
    {
      title: true,
      name: 'resources',
    },
    {
      name: 'favorites',
      url: '/favorite',
      icon: 'fa fa-star',
    },
    {
      name: 'tickets',
      url: '/tickets',
      icon: 'fa ts-icon-ticket',
    },

    {
      name: 'documentations',
      url: '/documentazione',
      icon: 'fa fa-book',
    },
    {
      name: 'changelog',
      url: '/changelog',
      icon: 'fa fa-list',
    },
    {
      name: 'faq',
      url: '/faq',
      icon: 'fa fa-question-circle',
    },
    {
      divider: true,
    },
    {
      title: true,
      name: 'user',
    },
    {
      name: 'profile',
      url: '/profile',
      icon: 'fa fa-user-o',
    },
    {
      name: 'settings',
      url: '/settings',
      icon: 'fa fa-sliders',
    },
    {
      name: 'logout',
      url: '/logout',
      icon: 'fa fa-sign-out',
    },
  ],
};
