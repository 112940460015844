import React, { Component } from "react";
import { Localized } from "@fluent/react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PropTypes from "prop-types";

const availableSearchTerms = [
  { type: "report", label: "report" },
  { type: "adversaries", label: "adversary" },
  { type: "events", label: "event" },
  /*{ type: "md5", label: "MD5" },
  { type: "sha1", label: "SHA1" },
  { type: "sha256", label: "SHA256" },
  { type: "filename", label: "Filename" },
  { type: "domain", label: "Domain" },
  { type: "mutex", label: "Mutex" },
  { type: "registry_key", label: "Registry key" },
  { type: "certificate", label: "Certificate" },
  { type: "ip", label: "IP" },
  { type: "email", label: "Email" },
  { type: "url", label: "URL" },*/
  //{ type: "string", label: "Report / Eventi" }
];

export default class SearchBar extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  static defaultProps = {
    searchType: "report",
    query: "",
  };

  state = {
    query: this.props.query,
    type: availableSearchTerms.find((el) => el.type === this.props.searchType),
  };

  componentDidUpdate(prevProps) {
    const { searchType, query } = this.props;
    if (prevProps.searchType !== searchType || prevProps.query !== query) {
      this.setState({
        type: availableSearchTerms.find(
          (el) => el.type === this.props.searchType
        ),
        query,
      });
    }
  }

  onTypeSearchChange = (item) => {
    this.setState({ type: item });
  };

  onSearchTextChange = (e) => {
    this.setState({ query: e.target.value });
  };

  onEnter = (e) => {
    if (e.key === "Enter") this.onSearch(e);
  };

  onSearch = (e) => {
    e.preventDefault();
    this.context.router.history.push(
      `/search?page=1&query=${encodeURIComponent(this.state.query)}&type=${
        this.state.type.type || "report"
      }`
    );
  };

  render() {
    const { query, type } = this.state;

    return (
      <div className="input-group search_bar">
        <UncontrolledDropdown>
          <DropdownToggle className="search_bar_toggle" caret>
            {type ? (
              <Localized id={type.label}>type.label</Localized>
            ) : (
              <Localized id="filter_by">filter_by</Localized>
            )}
          </DropdownToggle>
          <DropdownMenu>
            {availableSearchTerms.map((item) => (
              <DropdownItem
                key={item.type}
                onClick={() => {
                  this.onTypeSearchChange(item);
                }}
              >
                <Localized id={item.label}>{item.label}</Localized>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

        <Localized id="search_term" attrs={{ placeholder: true }}>
          <input
            className="form-control search_bar_input"
            name="search"
            type="text"
            value={query}
            onChange={this.onSearchTextChange}
            onKeyUp={(e) => this.onEnter(e)}
            placeholder="search_term"
          />
        </Localized>
        <span className="input-group-btn">
          <button
            className="btn btn-default search_bar_btn"
            type="button"
            onClick={(e) => this.onSearch(e)}
          >
            <span className="fa fa-search" />
          </button>
        </span>
      </div>
    );
  }
}
