import React, { Component } from "react";
import { Link } from "react-router-dom";
import TicketRow from "../TicketRow";
import Spinner from "../Spinner";
import { Localized } from "@fluent/react";

export default class TicketsList extends Component {
  static defaultProps = {
    tickets: [],
  };

  render() {
    const { tickets, link = "", isLoading, limit } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div className="card">
        <div className="card-header">
          <Link to={link}>
            <h2>
              <i className="fa fa-envelope-open" />{" "}
              <Localized id="tickets">tickets</Localized>
            </h2>
          </Link>
        </div>
        <div className="card-body">
          <ul className="list-group ticket-list">
            {limit
              ? tickets
                  .slice(0, limit)
                  .map((d, i) => <TicketRow key={i} {...d} />)
              : tickets.map((d, i) => <TicketRow key={i} {...d} />)}
          </ul>
        </div>
      </div>
    );
  }
}
