import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Paginations from '../Pagination';
import Spinner from '../Spinner';
import EmptyState from '../EmptyState';
import { Localized } from '@fluent/react';

const Tool = props => {
  return (
    <tr>
      <td className="text-center">
        <Link to={`/tool/${props.id}`}>{props.name}</Link>
      </td>
    </tr>
  );
};

export default class Tools extends Component {
  static defaultProps = {
    tool: [],
  };
  render() {
    const { tool, size, hasFetchedData } = this.props;
    const pagination = { pages: 0 };

    if (!hasFetchedData && !tool.length) {
      return <Spinner />;
    }

    if (tool && tool.length > 0) {
      pagination.pages = Math.ceil(tool[0].contatore / size);
      return (
        <Card className="mb-4">
          <CardHeader>
            <h2 className="text-capitalize">
              <i className="fa ts-icon-tools ts-blue2" aria-hidden="true"></i>{' '}
              <Localized id="tools">tools</Localized>
            </h2>
          </CardHeader>
          <CardBody>
            <Table striped>
              <tbody>
                {tool.map(e => (
                  <Tool key={e.id} {...e} />
                ))}
              </tbody>
            </Table>
            <Paginations pagination={pagination} {...this.props} />
          </CardBody>
        </Card>
      );
    }

    return (
      <EmptyState
        img="/img/reports.svg"
        label={<Localized id="no_tools_found">no_tools_found</Localized>}
      />
    );
  }
}
