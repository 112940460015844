import React, { Component } from 'react';
import { Localized } from '@fluent/react';
import AppShell from '../AppShell';
import License from './License';
import { SettingSwitch } from './SettingSwitch';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

export default class Settings extends Component {
  state = {
    email_report: false,
    email_custom_report: false,
    email_ticket: false,
  };

  componentDidMount() {
    this.fetchSettings();
  }

  fetchSettings = () => {
    const { user } = this.props;
    if (user) {
      this.setState({
        email_report: user.notifications.email_report,
        email_custom_report: user.notifications.email_custom_report,
        email_ticket: user.notifications.email_ticket,
      });
    }
  };

  onSettingsSave = () => {
    const {
      user: { id },
    } = this.props;
    if (!id) return;

    this.props.editSettings(id, this.state);
  };

  componentDidUpdate(nextProps) {
    if (this.props.user !== nextProps.user) {
      this.fetchSettings();
    }
  }

  onCheck = (type, c) => this.setState({ [type]: c });

  render() {
    const { email_report, email_custom_report, email_ticket } = this.state;
    const { user } = this.props;

    if (!user) return null;

    return (
      <AppShell noFilterBar {...this.props}>
        <div className="row justify-content-center">
          <div className="col col-12 col-lg-12 col-xl-12">
            <License organization={user.organization} />

            {/* <div className="card border-light">
              <div className="card-header">
                <h2>
                  <i className="fa fa-sliders ts-blue2" aria-hidden="true" />{' '}
                  <span className="ts-blue2">
                    <Localized id="settings">settings</Localized> /{' '}
                  </span>
                  <Localized id="language">language</Localized>
                </h2>
              </div>
              <div className="settings-card">
                <LanguageSelect label></LanguageSelect>
              </div>
            </div> */}

            <div className="card border-light">
              <div className="card-header">
                <h2>
                  <i className="fa fa-sliders ts-blue2" aria-hidden="true" />{' '}
                  <span className="ts-blue2">
                    <Localized id="settings">settings</Localized> /{' '}
                  </span>
                  <Localized id="notifications">notifications</Localized>
                </h2>
              </div>
              <div className="settings-card">
                <form>
                  <SettingSwitch
                    title={
                      <Localized id="notifications_email_reports">
                        notifications_email_reports
                      </Localized>
                    }
                    checked={email_report}
                    onToggle={c => this.onCheck('email_report', c)}
                  />

                  <SettingSwitch
                    title={
                      <Localized id="notifications_email_custom_reports">
                        notifications_email_custom_reports
                      </Localized>
                    }
                    checked={email_custom_report}
                    onToggle={c => this.onCheck('email_custom_report', c)}
                  />

                  <SettingSwitch
                    title={
                      <Localized id="notifications_email_tickets">
                        notifications_email_tickets
                      </Localized>
                    }
                    checked={email_ticket}
                    onToggle={c => this.onCheck('email_ticket', c)}
                  />

                  <div className="mt-4 text-center text-xl-left">
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      onClick={this.onSettingsSave}
                    >
                      <Localized id="save">save</Localized>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="col col-12 col-lg-5 col-xl-4">
            <Expire expireDate={expireDate} />
          </div> */}
        </div>
      </AppShell>
    );
  }
}
