/**
 *
 * Effettua una richiesta GET per prendere il GLR attualmente settato.
 *
 */
export function getGlr(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_GLR_REQUEST'
    });

    const d = await api.get('glr');

    if (d.error) {
      dispatch({
        type: 'GET_GLR_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_GLR_SUCCESS',
        data: d.data
      });
    }
  };
}
