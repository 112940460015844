/**
 * Effettua la chiamata GET per prendere i dati relativi agli exploits, partendo da un ACTOR ID.
 * Utilizzato nella visualizzazione del singolo adversary.
 * @param {object} data
 * @param {object} data.params.ID ACTOR ID
 * @param {object} data.query Object che contiene la paginazione -> url-encoded.
 *
 *
 */
export function getExploitFromActorName(data, router) {
  return async (dispatch, getState, api) => {
    dispatch({
      type: 'GET_ADVERSARY_EXPLOITS_REQUEST'
    });

    const d = await api.get(
      `threats/actors/${data.params.name}/exploits`,
      data.query
    );

    if (d.error) {
      dispatch({
        type: 'GET_ADVERSARY_EXPLOITS_FAILED',
        message: d.error
      });
    } else {
      dispatch({
        type: 'GET_ADVERSARY_EXPLOITS_SUCCESS',
        data: d.data,
        id: data.params.name
      });
    }
  };
}
